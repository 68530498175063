import React, { useEffect, useState } from 'react'
import { Title } from '../../components/title/Title'
import { STRINGS } from './strings'
import { get_all_orders, search_orders } from '../../utils/api/utilAPI'
import { TextInput } from '../../components/formelements/TextInput'
import OrdersTable from './Components/OrdersTable'
import { Paper } from '@mui/material'
import PageSelector from '../../components/pagination/PageSelector'
import RowsSelector from '../../components/pagination/RowSelector'
import { useDispatch, useSelector } from 'react-redux'
import { loadingActions } from '../../utils/redux/features/loadingSlice'
import { toast } from 'react-toastify'


export const OrderManagement = () => {

  const [tableData, setTableData] = useState([]);
  const loading = useSelector((state) => state?.loadingReducer?.loading);
  const dispatch = useDispatch();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [search, setSearch] = useState("");
  const orderStatus = 0;

  const handlePaginationLimitChange = (event) => {
    setLimit(event.target.value);
    setPage(1);

  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };
  const fetchOrderList = async () => {
    dispatch(loadingActions.loadingState(true));
    try {
      const response = await get_all_orders(page, limit, orderStatus);
      setTableData(response?.data?.data?.orderData);
      setTotalCount(response?.data?.data?.totalOrders);
    } catch (error) {
      toast.error(error.response?.data?.message, {
        toastId: "fetching order list failed",
      });
    } finally {
      dispatch(loadingActions.loadingState(false));
    }
  }

  const handleSearchOrders = async () => {

    dispatch(loadingActions.loadingState(true));
    try {
      setPage(1);
      const response = await search_orders(search);
      setTableData(response?.data?.data);
      setTotalCount(response?.data?.totalCount);
    } catch (error) {
      toast.error(error?.response?.data?.message, {
        toastId: "Error fetching order  "
      });
    } finally {
      dispatch(loadingActions.loadingState(false));
    }
  }


  useEffect(() => {
    if (search !== "") {
      handleSearchOrders();
    }
    else {
      fetchOrderList();
    }
  }, [page, limit, search]);



  return (
    <div className="flex flex-col h-full justify-start gap-6 ">
      <Paper
        className="paperClass"
        component={Paper}
      >
        <div className="flex flex-row items-center justify-between  ">
          <Title
            pagetitle={STRINGS.TITLE}
            fontsize="20px"
            fontWeight="600"
            color="#060709"
            border={false}
          />
          <TextInput
            type="text"
            placeholder="Search Order Id"
            style={{ background: "#E4E4E4" }}
            marginbot="0"
            onChange={(e) => setSearch(e.target.value)}

          />
        </div>
        <OrdersTable tableData={tableData} loadingData={loading} />
        <div className="flex justify-between mt-auto items-center">
          <RowsSelector limit={limit} handlePaginationLimitChange={handlePaginationLimitChange} />
          <PageSelector totalCount={totalCount} limit={limit} page={page} handlePageChange={handlePageChange} />
        </div>
      </Paper>
    </div>
  )
}

