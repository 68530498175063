import React, { useEffect, useState } from "react";
import { Title } from "../../components/title/Title";
import { Paper } from "@mui/material";
import CommunityTable from "./communityTable";
import { useDispatch } from "react-redux";
import { loadingActions } from "../../utils/redux/features/loadingSlice";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { channelsRef } from "./firebase";
export const Community = () => {
  const [tableData, setTableData] = useState([]);
  const dispatch = useDispatch();
  const fetchCommunityList = async () => {
    dispatch(loadingActions.loadingState(false));
  };
  const [channels, setChannels] = useState([]);

  useEffect(() => {
    fetchCommunityList();

    const channelsQuery = query(channelsRef);

    const unsubscribe = onSnapshot(channelsQuery, (queryChannels) => {
      const tempChannels = [];

      queryChannels.forEach((channel) => {
        tempChannels.push({ id: channel.id, ...channel.data() });
      });

      setChannels(tempChannels);
    });

    return () => unsubscribe();
  }, []);

  return (
    <div className="flex flex-col h-full justify-start gap-6 ">
      <Paper className="paperClass h-full" component={Paper}>
        <Title
          pagetitle={"Community Management"}
          fontsize="20px"
          fontWeight="600"
          color="#060709"
          border={false}
        />
        <CommunityTable tableData={channels} />
      </Paper>
    </div>
  );
};
