
import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import UserManagementTable from "./UserManagementTable";
import { Title } from "../../components/title/Title";
import { STRING } from "./strings";
import DynamicTabs from "../../components/tabs/DynamicTabs";
import RowsSelector from "../../components/pagination/RowSelector";
import PageSelector from "../../components/pagination/PageSelector";
import { get_all_users, search_user } from "../../utils/api/utilAPI";
import { useDispatch, useSelector } from "react-redux";
import { loadingActions } from "../../utils/redux/features/loadingSlice";
import { toast } from "react-toastify";

const tabLabels = [
  {
    label: "All User", value: "all"
  },
  {
    label: "Music Collab's", value: "collaborator"
  },
  {
    label: "General user", value: "users"
  }
];

const UserManagement = () => {
  const [selectedTab, setSelectedTab] = useState({});
  const [tableData, setTableData] = useState([]);
  const loading = useSelector((state) => state?.loadingReducer?.loading);
  const dispatch = useDispatch();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [search, setSearch] = useState("");
  const [action, setAction] = useState(false);
  const handleTabSelect = (activeTab) => {
    setSelectedTab(activeTab);
    setPage(1);
  };

  const handlePaginationLimitChange = (event) => {
    setPage(1);
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };
  const fetchUserList = async () => {
    dispatch(loadingActions.loadingState(true));
    try {
      const response = await get_all_users(page, limit, selectedTab?.value);
      setTableData(response?.data?.data);
      setTotalCount(response?.data?.totalCount);
    } catch (error) {
      toast.error(error?.response?.data?.message , {
        toastId : "Error fetching user  list"
      });    
    } finally {
      dispatch(loadingActions.loadingState(false));
    }
  };
  const handleSearchUser = async () => {
    dispatch(loadingActions.loadingState(true));
    try {
      setPage(1);
      const response = await search_user(search, selectedTab?.value);
      setTableData(response?.data?.data);
      setTotalCount(response?.data?.totalCount);
    } catch (error) {
      toast.error(error?.response?.data?.message , {
        toastId : "Error fetching user  "
      });
    } finally {
      dispatch(loadingActions.loadingState(false));
    }
  };
  useEffect(() => {
    if (!selectedTab?.value) return;
    if (search !== "") {
      handleSearchUser();
    }
    else {
      fetchUserList();
    }
  }, [page, limit, selectedTab?.value, search]);
  useEffect(() => {
    if (action) { fetchUserList(); }
    setAction(false);
  }, [action]);
  return (
    <div className="flex flex-col h-full  justify-start gap-6 ">
      <Paper
        className="paperClass"
        component={Paper}
      >
        <Title
          pagetitle={STRING.PAGE_TITLE}
          fontsize="20px"
          fontWeight="600"
          color="#060709"
          border={false}
        />
        <DynamicTabs tabLabels={tabLabels} onTabSelect={handleTabSelect} />
        <UserManagementTable tableData={tableData} loadingData={loading} filterType={selectedTab} searchQuery={(e) => setSearch(e)} sendAction={(e) => setAction(e)} />
        <div className="flex justify-between mt-auto items-center">
          <RowsSelector limit={limit} handlePaginationLimitChange={handlePaginationLimitChange} />
          <PageSelector totalCount={totalCount} limit={limit} page={page} handlePageChange={handlePageChange} />
        </div>
      </Paper>
    </div>
  );
}

export default UserManagement;