import { React, useEffect, useContext, useRef } from "react";
import { PlayerContext } from "../MusicManagement";
import Hls from "hls.js";

const MiniVideo = () => {
	const videoRef = useRef(null);

	useEffect(() => {
		if (videoRef.current) {
			if (Hls.isSupported()) {
				const hls = new Hls();
				hls.loadSource(`${currentTrack?.video?.src}720.m3u8`);
				hls.attachMedia(videoRef.current);
				hls.on(Hls.Events.MANIFEST_PARSED, () => {
					videoRef.current.play();
				});
			} else if (
				videoRef.current.canPlayType("application/vnd.apple.mpegurl")
			) {
				videoRef.current.src = `${currentTrack?.video?.src}720.m3u8`;
				videoRef.current.addEventListener("loadedmetadata", () => {
					videoRef.current.play();
				});
			}
		}
	}, []);

	const { play, setPlay, setOpen, currentTrack, setCurrentTrack, setYTState } =
		useContext(PlayerContext);

	return (
		<div className="w-[32rem] h-[20rem] absolute right-0 bottom-[4.75rem] flex flex-col items-center justify-between bg-[#E6355C] p-2">
			<video
				ref={videoRef}
				style={{
					width: "100%",
					display: "block",
					objectFit: "contain",
					backgroundColor: "black",
				}}
				autoPlay
				controls
				poster={currentTrack?.video?.thumbnail}
			/>
		</div>
	);
};

export default MiniVideo;
