import { useState, useEffect, useContext, useCallback } from "react";
import { motion } from "framer-motion";
import { CircularProgress, Skeleton } from "@mui/material";
import styles from "./Player.module.css";
// import { formatTime } from "../../../../utils/helperFunctions";
import {
	formatTimePlayer,
	extractYouTubeVideoId,
} from "../../utils/helperFuncs";
import MiniVideo from "./Video/MiniVideo";
import YouTube from "react-youtube";
import { PlayerContext } from "./MusicManagement";

function Player() {
	// function inputHandler() {
	// 	return (
	// 		loadMedia && (!currentTrack.isYoutube || ytState === 1 || ytState === 2)
	// 	);
	// }

	const {
		// open,
		// setOpen,
		// selectMedia,
		// setSelectMedia,
		loadMedia,
		// setLoadMedia,
		timeProgress,
		setTimeProgress,
		duration,
		// setDuration,
		play,
		setPlay,
		repeat,
		setRepeat,
		shuffle,
		setShuffle,
		showVideo,
		setShowVideo,
		currentTrack,
		audioRef,
		ytState,
		setYTState,
		progressBarRef,
		playAnimationRef,
		handleBack,
		handleForward,
		prev,
		next,
		shuffleFunc,
		ytref,
		playerCleanup,
		onLoadedMetadata,
	} = useContext(PlayerContext);

	const [forceRender, setForceRender] = useState(false);

	useEffect(() => {
		setForceRender((prev) => !prev);
	}, [currentTrack]);

	const handleProgressChange = () => {
		if (loadMedia) return;
		if (!currentTrack?.isYoutube)
			audioRef.current.currentTime = progressBarRef.current.value;
		else ytref.current?.seekTo(progressBarRef.current.value, true);
		const progressPercent =
			(progressBarRef.current.value / duration) * 100 +
			(progressBarRef.current.value !== duration && 0.25);
		progressBarRef.current.style.setProperty(
			"--range-progress",
			`${progressPercent}%`
		);
	};

	// const onLoadedMetadata = (durationVal) => {
	// 	const seconds = !currentTrack?.isYoutube
	// 		? audioRef.current.duration
	// 		: durationVal;
	// 	setDuration(seconds);
	// 	progressBarRef.current.max = seconds;
	// 	if (!currentTrack?.isYoutube) setPlay(true);
	// 	setLoadMedia(false);
	// };

	const repeatAni = useCallback(() => {
		const currentTime = !currentTrack?.isYoutube
			? audioRef.current?.currentTime || 0
			: ytref.current?.getCurrentTime() || 0;

		setTimeProgress(currentTime);
		const progressPercent =
			(currentTime / duration) * 100 + (currentTime !== duration && 0.25);
		if (progressBarRef.current) {
			progressBarRef.current.value = currentTime;
			progressBarRef.current.style.setProperty(
				"--range-progress",
				`${progressPercent}%`
			);
		}

		playAnimationRef.current = requestAnimationFrame(repeatAni);
	}, [duration, currentTrack]);

	useEffect(() => {
		if (currentTrack) {
			if (!currentTrack.isYoutube && audioRef.current) {
				if (play) {
					audioRef.current.play();
					playAnimationRef.current = requestAnimationFrame(repeatAni);
				} else {
					audioRef.current.pause();
					cancelAnimationFrame(playAnimationRef.current);
				}
			}
		}
	}, [play, currentTrack, repeatAni]);

	return (
		<motion.div
			initial={{ y: 60 }}
			animate={{ y: 0 }}
			transition={{ ease: "easeOut", duration: 0.75 }}
			className="min-h-[4.75rem] w-full absolute bottom-0 left-0 px-8 py-2 bg-[#E6355C] text-cyan-50 z-10 flex justify-between"
		>
			{!loadMedia && !currentTrack ? (
				<>Error in Music...</>
			) : (
				<>
					<div className="flex items-center gap-x-2 w-52 overflow-hidden">
						{currentTrack ? (
							<>
								<img
									src={
										currentTrack?.isYoutube
											? currentTrack?.youtube?.thumbnail
											: currentTrack?.audio?.thumbnail
									}
									alt={`${currentTrack?.title}`}
									style={{ borderRadius: "50%" }}
									className="w-9 h-9 object-cover border-solid border-[1px] border-[white]"
								/>
								<div className="flex flex-col ">
									<span
										className="text-base font-semibold"
										style={{ display: "block" }}
									>
										{currentTrack?.title}
									</span>{" "}
									{/* make this name scroll automatically on overflow*/}
									<span
										className="text-sm font-normal"
										style={{ display: "block" }}
									>
										by {currentTrack?.artist?.firstName}{" "}
										{currentTrack?.artist.lastName}
									</span>
								</div>
							</>
						) : (
							<>
								<Skeleton
									variant="circular"
									sx={{
										bgcolor: "#CF254B",
										width: "2.25rem",
										height: "2.25rem",
									}}
								/>
								<div className="flex flex-col gap-y-[0.5rem]">
									<Skeleton
										variant="rounded"
										sx={{
											bgcolor: "#CF254B",
											width: "6rem",
											height: "0.75rem",
										}}
									/>
									<Skeleton
										variant="rounded"
										sx={{
											bgcolor: "#CF254B",
											width: "3.5rem",
											height: "0.75rem",
										}}
									/>
								</div>
							</>
						)}
					</div>
					<div className="flex shrink-0 grow">
						{!currentTrack?.isYoutube && currentTrack?.audio?.src && (
							<audio
								loop={repeat}
								key={currentTrack?.audio?.src}
								autoplay
								src={currentTrack?.audio?.src}
								ref={audioRef}
								onLoadedMetadata={onLoadedMetadata}
								onEnded={() => {
									if (!repeat) {
										playerCleanup();
										if (shuffle) shuffleFunc();
										else handleForward();
									}
								}}
							/>
						)}

						<div className="w-full flex justify-between items-center gap-x-4">
							<span className="min-w-[45px]">
								{currentTrack && formatTimePlayer(timeProgress)}
							</span>
							<div
								style={{
									flexShrink: "0",
									flexGrow: "1",
									height: "1rem",
									position: "relative",
								}}
							>
								<div
									className={styles.emptyInput}
									style={{
										background:
											!loadMedia &&
											(currentTrack.isYoutube
												? ytState === 1 || ytState === 2 || ytState === 3
												: true)
												? "transparent"
												: "#ae1335",
									}}
								/>
								<input
									style={{
										background:
											loadMedia ||
											(currentTrack.isYoutube
												? ytState !== 1 && ytState !== 2 && ytState !== 3
												: false)
												? "transparent"
												: "#ae1335",
										display:
											loadMedia ||
											(currentTrack.isYoutube
												? ytState !== 1 && ytState !== 2 && ytState !== 3
												: false)
												? "none"
												: "block",
									}}
									type="range"
									defaultValue="0"
									onChange={handleProgressChange}
									ref={progressBarRef}
								/>
							</div>
							<span className="min-w-[45px]">
								{currentTrack && formatTimePlayer(duration)}
							</span>
						</div>
					</div>
					<div className="flex items-center gap-x-4 ml-4">
						<svg
							className={`${prev && "cursor-pointer"}`}
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
							onClick={() => {
								if (prev) {
									playerCleanup();
									setRepeat(false);
									if (shuffle) shuffleFunc();
									else handleBack();
								}
							}}
						>
							{prev ? (
								<>
									<path
										d="M20.24 7.21957V16.7896C20.24 18.7496 18.11 19.9796 16.41 18.9996L12.26 16.6096L8.10996 14.2096C6.40996 13.2296 6.40996 10.7796 8.10996 9.79957L12.26 7.39957L16.41 5.00957C18.11 4.02957 20.24 5.24957 20.24 7.21957Z"
										fill="white"
									/>
									<path
										d="M3.75977 18.9303C3.34977 18.9303 3.00977 18.5903 3.00977 18.1803V5.82031C3.00977 5.41031 3.34977 5.07031 3.75977 5.07031C4.16977 5.07031 4.50977 5.41031 4.50977 5.82031V18.1803C4.50977 18.5903 4.16977 18.9303 3.75977 18.9303Z"
										fill="white"
									/>
								</>
							) : (
								<>
									<path
										d="M20.24 7.21957V16.7896C20.24 18.7496 18.11 19.9796 16.41 18.9996L12.26 16.6096L8.10996 14.2096C6.40996 13.2296 6.40996 10.7796 8.10996 9.79957L12.26 7.39957L16.41 5.00957C18.11 4.02957 20.24 5.24957 20.24 7.21957Z"
										fill="#AE1335"
									/>
									<path
										d="M3.75977 18.9303C3.34977 18.9303 3.00977 18.5903 3.00977 18.1803V5.82031C3.00977 5.41031 3.34977 5.07031 3.75977 5.07031C4.16977 5.07031 4.50977 5.41031 4.50977 5.82031V18.1803C4.50977 18.5903 4.16977 18.9303 3.75977 18.9303Z"
										fill="#AE1335"
									/>
								</>
							)}
						</svg>

						{loadMedia ? (
							<CircularProgress
								sx={{ color: "white" }}
								style={{ width: "24px", height: "24px" }}
							/>
						) : (
							<svg
								style={{ cursor: "pointer" }}
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
								onClick={() => {
									if (!currentTrack?.isYoutube || ytref) {
										setPlay((curr) => !curr);
										if (ytref && ytref.current) {
											play
												? ytref.current.pauseVideo()
												: ytref.current.playVideo();
										}
									}
								}}
							>
								{play ? (
									<>
										<path
											d="M10.65 19.11V4.89C10.65 3.54 10.08 3 8.64 3H5.01C3.57 3 3 3.54 3 4.89V19.11C3 20.46 3.57 21 5.01 21H8.64C10.08 21 10.65 20.46 10.65 19.11Z"
											fill="white"
										/>
										<path
											d="M20.9996 19.11V4.89C20.9996 3.54 20.4296 3 18.9896 3H15.3596C13.9296 3 13.3496 3.54 13.3496 4.89V19.11C13.3496 20.46 13.9196 21 15.3596 21H18.9896C20.4296 21 20.9996 20.46 20.9996 19.11Z"
											fill="white"
										/>
									</>
								) : (
									<path
										d="M4 6.13352V17.8681C4 20.2714 6.70123 21.7796 8.85714 20.578L14.1201 17.6474L19.3831 14.7045C21.539 13.5029 21.539 10.4987 19.3831 9.29708L14.1201 6.35424L8.85714 3.42366C6.70123 2.222 4 3.71794 4 6.13352Z"
										fill="white"
									/>
								)}
							</svg>
						)}

						<svg
							className={`${next && "cursor-pointer"}`}
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
							onClick={() => {
								if (next) {
									setRepeat(false);
									playerCleanup();
									if (shuffle) shuffleFunc();
									else handleForward();
								}
							}}
						>
							{next ? (
								<>
									<path
										d="M3.75977 7.21957V16.7896C3.75977 18.7496 5.88977 19.9796 7.58977 18.9996L11.7398 16.6096L15.8898 14.2096C17.5898 13.2296 17.5898 10.7796 15.8898 9.79957L11.7398 7.39957L7.58977 5.00957C5.88977 4.02957 3.75977 5.24957 3.75977 7.21957Z"
										fill="white"
									/>
									<path
										d="M20.2402 18.9303C19.8302 18.9303 19.4902 18.5903 19.4902 18.1803V5.82031C19.4902 5.41031 19.8302 5.07031 20.2402 5.07031C20.6502 5.07031 20.9902 5.41031 20.9902 5.82031V18.1803C20.9902 18.5903 20.6602 18.9303 20.2402 18.9303Z"
										fill="white"
									/>
								</>
							) : (
								<>
									<path
										d="M3.75977 7.21957V16.7896C3.75977 18.7496 5.88977 19.9796 7.58977 18.9996L11.7398 16.6096L15.8898 14.2096C17.5898 13.2296 17.5898 10.7796 15.8898 9.79957L11.7398 7.39957L7.58977 5.00957C5.88977 4.02957 3.75977 5.24957 3.75977 7.21957Z"
										fill="#AE1335"
									/>
									<path
										d="M20.2402 18.9303C19.8302 18.9303 19.4902 18.5903 19.4902 18.1803V5.82031C19.4902 5.41031 19.8302 5.07031 20.2402 5.07031C20.6502 5.07031 20.9902 5.41031 20.9902 5.82031V18.1803C20.9902 18.5903 20.6602 18.9303 20.2402 18.9303Z"
										fill="#AE1335"
									/>
								</>
							)}
						</svg>

						<svg
							style={{ cursor: "pointer" }}
							width="28"
							height="28"
							viewBox="0 0 28 28"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
							onClick={() => {
								if (!repeat && shuffle) setShuffle(false);
								setRepeat((curr) => !curr);
							}}
						>
							{repeat ? (
								<>
									<rect width="28" height="28" rx="6" fill="white" />
									<path
										d="M5.91 19.1804C5.72 19.1804 5.53 19.1104 5.38 18.9604C4.01 17.5804 3.25 15.7604 3.25 13.8304C3.25 9.82042 6.5 6.56042 10.5 6.56042L16.57 6.58042L15.48 5.54042C15.18 5.25042 15.17 4.78042 15.46 4.48042C15.75 4.18042 16.22 4.17042 16.52 4.46042L18.96 6.80042C19.18 7.01042 19.25 7.34042 19.14 7.62042C19.03 7.90042 18.75 8.09042 18.44 8.09042L10.5 8.07042C7.33 8.07042 4.75 10.6604 4.75 13.8404C4.75 15.3704 5.35 16.8204 6.44 17.9104C6.73 18.2004 6.73 18.6804 6.44 18.9704C6.29 19.1104 6.1 19.1804 5.91 19.1804Z"
										fill="#E6355C"
									/>
									<path
										d="M11.9999 23.75C11.8099 23.75 11.6299 23.68 11.4799 23.54L9.0399 21.2C8.8199 20.99 8.7499 20.66 8.8599 20.38C8.9799 20.1 9.2599 19.95 9.5599 19.91L17.5099 19.93C20.6799 19.93 23.2599 17.34 23.2599 14.16C23.2599 12.63 22.6599 11.18 21.5699 10.09C21.2799 9.8 21.2799 9.32 21.5699 9.03C21.8599 8.74 22.3399 8.74 22.6299 9.03C23.9999 10.41 24.7599 12.23 24.7599 14.16C24.7599 18.17 21.5099 21.43 17.5099 21.43L11.4399 21.41L12.5299 22.45C12.8299 22.74 12.8399 23.21 12.5499 23.51C12.3899 23.67 12.1999 23.75 11.9999 23.75Z"
										fill="#E6355C"
									/>
									<path
										d="M14.2505 17.4201C13.8405 17.4201 13.5005 17.0801 13.5005 16.6701V13.2801L13.3105 13.4901C13.0305 13.8001 12.5605 13.8201 12.2505 13.5501C11.9405 13.2801 11.9205 12.8001 12.1905 12.4901L13.6905 10.8201C13.9005 10.5901 14.2305 10.5101 14.5205 10.6201C14.8105 10.7401 15.0005 11.0101 15.0005 11.3301V16.6801C15.0005 17.0901 14.6605 17.4201 14.2505 17.4201Z"
										fill="#E6355C"
									/>
								</>
							) : (
								<>
									<rect width="28" height="28" rx="6" fill="#AE1335" />
									<path
										d="M5.91 19.1804C5.72 19.1804 5.53 19.1104 5.38 18.9604C4.01 17.5804 3.25 15.7604 3.25 13.8304C3.25 9.82042 6.5 6.56042 10.5 6.56042L16.57 6.58042L15.48 5.54042C15.18 5.25042 15.17 4.78042 15.46 4.48042C15.75 4.18042 16.22 4.17042 16.52 4.46042L18.96 6.80042C19.18 7.01042 19.25 7.34042 19.14 7.62042C19.03 7.90042 18.75 8.09042 18.44 8.09042L10.5 8.07042C7.33 8.07042 4.75 10.6604 4.75 13.8404C4.75 15.3704 5.35 16.8204 6.44 17.9104C6.73 18.2004 6.73 18.6804 6.44 18.9704C6.29 19.1104 6.1 19.1804 5.91 19.1804Z"
										fill="#E6355C"
									/>
									<path
										d="M11.9999 23.75C11.8099 23.75 11.6299 23.68 11.4799 23.54L9.0399 21.2C8.8199 20.99 8.7499 20.66 8.8599 20.38C8.9799 20.1 9.2599 19.95 9.5599 19.91L17.5099 19.93C20.6799 19.93 23.2599 17.34 23.2599 14.16C23.2599 12.63 22.6599 11.18 21.5699 10.09C21.2799 9.8 21.2799 9.32 21.5699 9.03C21.8599 8.74 22.3399 8.74 22.6299 9.03C23.9999 10.41 24.7599 12.23 24.7599 14.16C24.7599 18.17 21.5099 21.43 17.5099 21.43L11.4399 21.41L12.5299 22.45C12.8299 22.74 12.8399 23.21 12.5499 23.51C12.3899 23.67 12.1999 23.75 11.9999 23.75Z"
										fill="#E6355C"
									/>
									<path
										d="M14.2505 17.4201C13.8405 17.4201 13.5005 17.0801 13.5005 16.6701V13.2801L13.3105 13.4901C13.0305 13.8001 12.5605 13.8201 12.2505 13.5501C11.9405 13.2801 11.9205 12.8001 12.1905 12.4901L13.6905 10.8201C13.9005 10.5901 14.2305 10.5101 14.5205 10.6201C14.8105 10.7401 15.0005 11.0101 15.0005 11.3301V16.6801C15.0005 17.0901 14.6605 17.4201 14.2505 17.4201Z"
										fill="#E6355C"
									/>
								</>
							)}
						</svg>

						<svg
							style={{ cursor: "pointer" }}
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
							onClick={() => {
								if (!shuffle && repeat) setRepeat(false);
								setShuffle((curr) => !curr);
							}}
						>
							{shuffle ? (
								<>
									<path
										d="M21.7497 17.9799C21.7497 17.9599 21.7397 17.9399 21.7397 17.9199C21.7297 17.8399 21.7197 17.7599 21.6897 17.6899C21.6497 17.5999 21.5997 17.5299 21.5397 17.4599C21.5397 17.4599 21.5397 17.4499 21.5297 17.4499C21.4597 17.3799 21.3797 17.3299 21.2897 17.2899C21.1997 17.2499 21.0997 17.2299 20.9997 17.2299L16.3297 17.2499C16.3297 17.2499 16.3297 17.2499 16.3197 17.2499C15.7197 17.2499 15.1397 16.9699 14.7797 16.4899L13.5597 14.9199C13.3097 14.5899 12.8397 14.5299 12.5097 14.7899C12.1797 15.0499 12.1197 15.5099 12.3797 15.8399L13.5997 17.4099C14.2497 18.2499 15.2697 18.7499 16.3297 18.7499H16.3397L19.1897 18.7399L18.4797 19.4499C18.1897 19.7399 18.1897 20.2199 18.4797 20.5099C18.6297 20.6599 18.8197 20.7299 19.0097 20.7299C19.1997 20.7299 19.3897 20.6599 19.5397 20.5099L21.5397 18.5099C21.6097 18.4399 21.6597 18.3599 21.6997 18.2699C21.7297 18.1699 21.7497 18.0699 21.7497 17.9799Z"
										fill="white"
									/>
									<path
										d="M8.42 6.68977C7.77 5.78977 6.73 5.25977 5.62 5.25977C5.61 5.25977 5.61 5.25977 5.6 5.25977L3 5.26977C2.59 5.26977 2.25 5.60977 2.25 6.01977C2.25 6.42977 2.59 6.76977 3 6.76977L5.61 6.75977H5.62C6.25 6.75977 6.84 7.05977 7.2 7.56977L8.28 9.06977C8.43 9.26976 8.66 9.37977 8.89 9.37977C9.04 9.37977 9.2 9.32977 9.33 9.23977C9.67 8.98977 9.74 8.51976 9.5 8.18976L8.42 6.68977Z"
										fill="white"
									/>
									<path
										d="M21.74 6.07973C21.74 6.05973 21.75 6.03973 21.75 6.02973C21.75 5.92973 21.73 5.82973 21.69 5.73973C21.65 5.64973 21.6 5.56973 21.53 5.49973L19.53 3.49973C19.24 3.20973 18.76 3.20973 18.47 3.49973C18.18 3.78973 18.18 4.26973 18.47 4.55973L19.18 5.26973L16.45 5.25973C16.44 5.25973 16.44 5.25973 16.43 5.25973C15.28 5.25973 14.2 5.82973 13.56 6.79973L7.17 16.3797C6.81 16.9197 6.2 17.2497 5.55 17.2497H5.54L3 17.2297C2.59 17.2297 2.25 17.5597 2.25 17.9797C2.25 18.3897 2.58 18.7297 3 18.7297L5.55 18.7397C5.56 18.7397 5.56 18.7397 5.57 18.7397C6.73 18.7397 7.8 18.1697 8.44 17.1997L14.83 7.61973C15.19 7.07973 15.8 6.74973 16.45 6.74973H16.46L21 6.76973C21.1 6.76973 21.19 6.74973 21.29 6.70973C21.38 6.66973 21.46 6.61973 21.53 6.54973C21.53 6.54973 21.53 6.53973 21.54 6.53973C21.6 6.46973 21.66 6.39973 21.69 6.30973C21.72 6.23973 21.73 6.15973 21.74 6.07973Z"
										fill="white"
									/>
								</>
							) : (
								<>
									<path
										d="M21.7497 17.9799C21.7497 17.9599 21.7397 17.9399 21.7397 17.9199C21.7297 17.8399 21.7197 17.7599 21.6897 17.6899C21.6497 17.5999 21.5997 17.5299 21.5397 17.4599C21.5397 17.4599 21.5397 17.4499 21.5297 17.4499C21.4597 17.3799 21.3797 17.3299 21.2897 17.2899C21.1997 17.2499 21.0997 17.2299 20.9997 17.2299L16.3297 17.2499C16.3297 17.2499 16.3297 17.2499 16.3197 17.2499C15.7197 17.2499 15.1397 16.9699 14.7797 16.4899L13.5597 14.9199C13.3097 14.5899 12.8397 14.5299 12.5097 14.7899C12.1797 15.0499 12.1197 15.5099 12.3797 15.8399L13.5997 17.4099C14.2497 18.2499 15.2697 18.7499 16.3297 18.7499H16.3397L19.1897 18.7399L18.4797 19.4499C18.1897 19.7399 18.1897 20.2199 18.4797 20.5099C18.6297 20.6599 18.8197 20.7299 19.0097 20.7299C19.1997 20.7299 19.3897 20.6599 19.5397 20.5099L21.5397 18.5099C21.6097 18.4399 21.6597 18.3599 21.6997 18.2699C21.7297 18.1699 21.7497 18.0699 21.7497 17.9799Z"
										fill="#AE1335"
									/>
									<path
										d="M8.42 6.68977C7.77 5.78977 6.73 5.25977 5.62 5.25977C5.61 5.25977 5.61 5.25977 5.6 5.25977L3 5.26977C2.59 5.26977 2.25 5.60977 2.25 6.01977C2.25 6.42977 2.59 6.76977 3 6.76977L5.61 6.75977H5.62C6.25 6.75977 6.84 7.05977 7.2 7.56977L8.28 9.06977C8.43 9.26976 8.66 9.37977 8.89 9.37977C9.04 9.37977 9.2 9.32977 9.33 9.23977C9.67 8.98977 9.74 8.51976 9.5 8.18976L8.42 6.68977Z"
										fill="#AE1335"
									/>
									<path
										d="M21.74 6.07973C21.74 6.05973 21.75 6.03973 21.75 6.02973C21.75 5.92973 21.73 5.82973 21.69 5.73973C21.65 5.64973 21.6 5.56973 21.53 5.49973L19.53 3.49973C19.24 3.20973 18.76 3.20973 18.47 3.49973C18.18 3.78973 18.18 4.26973 18.47 4.55973L19.18 5.26973L16.45 5.25973C16.44 5.25973 16.44 5.25973 16.43 5.25973C15.28 5.25973 14.2 5.82973 13.56 6.79973L7.17 16.3797C6.81 16.9197 6.2 17.2497 5.55 17.2497H5.54L3 17.2297C2.59 17.2297 2.25 17.5597 2.25 17.9797C2.25 18.3897 2.58 18.7297 3 18.7297L5.55 18.7397C5.56 18.7397 5.56 18.7397 5.57 18.7397C6.73 18.7397 7.8 18.1697 8.44 17.1997L14.83 7.61973C15.19 7.07973 15.8 6.74973 16.45 6.74973H16.46L21 6.76973C21.1 6.76973 21.19 6.74973 21.29 6.70973C21.38 6.66973 21.46 6.61973 21.53 6.54973C21.53 6.54973 21.53 6.53973 21.54 6.53973C21.6 6.46973 21.66 6.39973 21.69 6.30973C21.72 6.23973 21.73 6.15973 21.74 6.07973Z"
										fill="#AE1335"
									/>
								</>
							)}
						</svg>

						<svg
							style={{
								cursor: `${
									currentTrack &&
									(currentTrack?.isYoutube || "video" in currentTrack)
										? "pointer"
										: "default"
								}`,
							}}
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
							onClick={() => {
								if (currentTrack?.isYoutube || "video" in currentTrack) {
									setShowVideo((curr) => !curr);
								}
							}}
						>
							{currentTrack &&
							((!currentTrack?.isYoutube && "video" in currentTrack) ||
								currentTrack?.isYoutube) ? (
								<>
									{showVideo ? (
										<>
											<path
												opacity="0.4"
												d="M17.7405 7.57007C17.7505 7.64007 17.7505 7.72007 17.7405 7.79007C17.7405 7.72007 17.7305 7.65007 17.7305 7.58007L17.7405 7.57007Z"
												fill="white"
											/>
											<path
												d="M17.2799 6.56L3.82988 20.01C2.42988 19.12 1.87988 17.53 1.87988 16V8C1.87988 4.58 3.20988 3.25 6.62988 3.25H12.6299C15.5199 3.25 16.9099 4.2 17.2799 6.56Z"
												fill="white"
											/>
											<path
												d="M21.4 2.22988C21.1 1.92988 20.61 1.92988 20.31 2.22988L1.85 20.6899C1.55 20.9899 1.55 21.4799 1.85 21.7799C2 21.9199 2.2 21.9999 2.39 21.9999C2.59 21.9999 2.78 21.9199 2.93 21.7699L21.4 3.30988C21.7 3.00988 21.7 2.52988 21.4 2.22988Z"
												fill="white"
											/>
											<path
												d="M22.3802 8.37998V15.62C22.3802 17.05 21.6802 17.62 21.2802 17.83C21.0902 17.93 20.7902 18.04 20.4202 18.04C19.9902 18.04 19.4602 17.9 18.8402 17.46L17.3602 16.42C17.2902 18.63 16.5902 19.89 15.0002 20.42C14.3602 20.65 13.5702 20.75 12.6202 20.75H6.62016C6.38016 20.75 6.15016 20.74 5.91016 20.71L15.0002 11.63L20.6502 5.97998C20.9102 5.99998 21.1202 6.07998 21.2802 6.16998C21.6802 6.37998 22.3802 6.94998 22.3802 8.37998Z"
												fill="white"
											/>
										</>
									) : (
										<path
											d="M21.15 6.17C20.74 5.95 19.88 5.72 18.71 6.54L17.24 7.58C17.13 4.47 15.78 3.25 12.5 3.25H6.5C3.08 3.25 1.75 4.58 1.75 8V16C1.75 18.3 3 20.75 6.5 20.75H12.5C15.78 20.75 17.13 19.53 17.24 16.42L18.71 17.46C19.33 17.9 19.87 18.04 20.3 18.04C20.67 18.04 20.96 17.93 21.15 17.83C21.56 17.62 22.25 17.05 22.25 15.62V8.38C22.25 6.95 21.56 6.38 21.15 6.17ZM11 11.38C9.97 11.38 9.12 10.54 9.12 9.5C9.12 8.46 9.97 7.62 11 7.62C12.03 7.62 12.88 8.46 12.88 9.5C12.88 10.54 12.03 11.38 11 11.38Z"
											fill="white"
										/>
									)}
								</>
							) : (
								<path
									d="M21.15 6.17C20.74 5.95 19.88 5.72 18.71 6.54L17.24 7.58C17.13 4.47 15.78 3.25 12.5 3.25H6.5C3.08 3.25 1.75 4.58 1.75 8V16C1.75 18.3 3 20.75 6.5 20.75H12.5C15.78 20.75 17.13 19.53 17.24 16.42L18.71 17.46C19.33 17.9 19.87 18.04 20.3 18.04C20.67 18.04 20.96 17.93 21.15 17.83C21.56 17.62 22.25 17.05 22.25 15.62V8.38C22.25 6.95 21.56 6.38 21.15 6.17ZM11 11.38C9.97 11.38 9.12 10.54 9.12 9.5C9.12 8.46 9.97 7.62 11 7.62C12.03 7.62 12.88 8.46 12.88 9.5C12.88 10.54 12.03 11.38 11 11.38Z"
									fill="#AE1335"
								/>
							)}
						</svg>
					</div>
					{showVideo && !currentTrack?.isYoutube && <MiniVideo />}
					{currentTrack?.isYoutube && (
						<YouTube
							key={forceRender}
							opts={{ width: "512", height: "320" }}
							style={{
								position: "absolute",
								[showVideo ? "bottom" : "top"]: "100%",
								right: "0",
							}}
							onReady={(e) => {
								onLoadedMetadata(e.target.getDuration());
								ytref.current = e.target;
								ytref.current?.playVideo();
							}}
							onStateChange={(e) => {
								setYTState(e.data);
								if (e.data === 1) {
									playAnimationRef.current = requestAnimationFrame(repeatAni);
								} else {
									cancelAnimationFrame(playAnimationRef.current);
								}
							}}
							videoId={extractYouTubeVideoId(currentTrack?.youtube?.url)}
							onPlay={() => {
								setPlay(true);
							}}
							onPause={() => {
								setPlay(false);
							}}
							onEnd={() => {
								if (repeat) {
									ytref.current?.seekTo(0);
									ytref.current?.playVideo();
								} else {
									playerCleanup();
									if (shuffle) shuffleFunc();
									else handleForward();
									ytref = null; // destroy?!
								}
							}}
						/>
					)}
				</>
			)}
		</motion.div>
	);
}

export default Player;
