import instance from "./axiosConfig";
export async function wrappedFetch(url, method, data, headers) {
	let config = {
		url,
		method,
		headers: {},
		data,
	};
	if (headers) config.headers = headers;
	try {
		console.log("CONFIG", config);

		let x = await instance(config);
		return x;
	} catch (err) {
		return Promise.reject(err);
	}
}
export async function wrappedGet(url, method, params) {
	let config = {
		url,
		method,
		headers: {},
		params,
	};

	try {
		return await instance(config);
	} catch (err) {
		return Promise.reject(err);
	}
}

export async function wrappedPost(url, method, data) {
	let config = {
		url,
		method,
		headers: {},
		data
	};

	try {
		return await instance(config);
	} catch (err) {
		return Promise.reject(err);
	}
}