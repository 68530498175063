import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { loginSuccess } from "../../utils/redux/features/authSlice";
import styles from "./Login.module.css";
import { STRINGS } from "./strings";
import { TextInput } from "../../components/formelements/TextInput";
import Button from "../../components/formelements/Button";
import {
	emailValidation,
	loginPasswordValidation,
} from "../../utils/validations/inputValidations";
import { Link, useNavigate } from "react-router-dom";
import { login } from "../../utils/api/authAPI";
import { FORGOT_PASSWORD_PATH } from "../../utils/constants/routes";

const LoginScreen = () => {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [validations, setValidations] = useState({
		email: "",
		password: "",
	});

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const image = "/assets/images/login.jpg";
	const handleSubmit = (e) => {
		e.preventDefault();

		const validatedEmail = emailValidation(email);
		const validatedPassword = loginPasswordValidation(password);
		if (validatedEmail.isValid && validatedPassword.isValid) {
			setValidations({
				email: "",
				password: "",
			});

			login({ email, password })
				.then((res) => {
					console.log("RESPONSE: ", res);
					localStorage.setItem(
						"AL_ADMIN",
						JSON.stringify({
							email: res.data?.data?.email,
							token: res.data?.data?.token,
							firstName: res.data?.data?.firstName,
							lastName: res.data?.data?.lastName,
						})
					);
					dispatch(loginSuccess(res?.data?.data));
					navigate("/merchandise");
				})
				.catch((err) => {
					console.error("ERROR PRINT: ", err);
					// toast.error(err.response?.data?.message, {
					// 	theme: "dark",
					// });
					console.log(
						"SET VALIDATIONS PASSWORD: ERROR",
						err?.response?.data?.message
					);
					setValidations({
						password: err?.response?.data?.message,
					});
				});
		} else {
			setValidations({
				email: validatedEmail.message,
				password: validatedPassword.message,
			});
		}
	};
	return (
		<>
			<div className={styles.test}>
				<div className={`container ${styles.bg}`}>
					<div className="row align-items-center justify-content-between">
						<div className="col-md-5 ps-5">
							<h1 className={styles.title}>{STRINGS.loginTitle}</h1>
							<p>{STRINGS.loginText}</p>

							<TextInput
								type="text"
								value={email}
								label={STRINGS.email}
								placeholder={STRINGS.emailplaceholder}
								onChange={(e) => setEmail(e.target.value)}
								error={validations.email}
							/>
							<TextInput
								type="password"
								value={password}
								label={STRINGS.password}
								placeholder={STRINGS.passwordplaceholder}
								onChange={(e) => setPassword(e.target.value)}
								error={validations.password}
							/>
							<div className="text-end mb-3">
								<Link to={FORGOT_PASSWORD_PATH} className={styles.link}>
									Forgot Password
								</Link>
							</div>
							<Button
								onClick={handleSubmit}
								className={`btn w-100 ${styles.btnbg}`}
								type="button"
								disabled={false}
								variant="gradient"
							>
								{STRINGS.buttontext}
							</Button>
						</div>
						<div className="col-md-6 pe-0">
							<img src={process.env.PUBLIC_URL + image} alt="" />
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default LoginScreen;
