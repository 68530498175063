import React from "react";
import { Modal, Fade } from "@mui/material";
import Button from "../../components/formelements/Button";
import styles from "./form.module.css";
import { ICON_LABELS } from "../icons";
import IconComponent from "../icons/IconComponent";

const BlockPopup = ({ modal, isBlocked  , handleDone, handleClose }) => {
  return (
    <Modal
      open={modal}
      onClose={handleClose}
      closeAfterTransition
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Fade in={modal}>
        <div className={`bg-white ${styles.popup}`}>
        {isBlocked ? (
             <IconComponent
             iconLabel={ICON_LABELS.UNBLOCK}
             color="#E6355C"
             fontSize="72px"
           />
          ) : (
            <IconComponent
            iconLabel={ICON_LABELS.BLOCK}
            color="#E6355C"
            fontSize="72px"
          />
          )}
          <div style={{ textAlign: "center" }}>
            <span style={{ fontSize: "1.125rem", fontWeight: "600" }}>
              {isBlocked ? "Are you sure you want to unblock ? " : "Are you sure you want to block ? "}
            </span>
            <br />
            <span>
              { isBlocked ? "Are you sure you want to unblock this user? " : "Are you sure you want to block this user?"} <br />
            </span>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button
              className={`btn py-2 px-4`}
              type="button"
              disabled={false}
              onClick={handleDone}
            >
             {isBlocked ? "Yes, Unblock!" : "Yes, Block"}
            </Button>
            <Button
              className={`btn py-2 px-4`}
              type="button"
              disabled={false}
              variant
              onClick={handleClose}
            >
              No, Cancel
            </Button>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default BlockPopup;
