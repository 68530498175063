import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { formatDate, renderTableCell, renderTableHeading, renderTextContent } from "../../../utils/helperFuncs";
import ViewButton from "../../../components/formelements/ViewButton";
import { ICON_LABELS } from "../../../components/icons";
import IconComponent from "../../../components/icons/IconComponent";
import { useNavigate } from 'react-router-dom'
import OrderStatus from "./OrderStatus";

const tableHeading = [
    "S.No.",
    "Order ID",
    "Date",
    "Customer Name",
    "Amount",
    "Order Status",
    "Actions",
];

export default function OrdersTable({ tableData, loadingData }) {
    const navigate = useNavigate();
    const handleView = (row) => {
        navigate(`/order_management/order-details/${row._id}`, { state: { data: row } });
    }


    const renderActionButtons = (row) => (
        <ViewButton
            type="button"
            variant={true}
            icon={ICON_LABELS.VISIBILITY_ON}
            onClick={() => handleView(row)}
        >
            View
        </ViewButton>

    )

    return (
        <Paper
            className="randomClass1 flex-grow flex flex-col justify-start pt-3 gap-[24px] shadow-none !rounded-none border-t-[1px]"
            component={Paper}
        >
            {loadingData ? (
                ""
            ) : (
                <TableContainer className="scrollContainer w-auto overflow-auto">

                    {tableData?.length !== 0 ? (
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow className="h-11 p-3">
                                    {tableHeading?.map((heading) => renderTableHeading(heading))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tableData?.map((row, index) => (
                                    <TableRow key={row?._id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                        {renderTableCell(<p>{index + 1}</p>)}
                                        {renderTableCell(row?.orderId || renderTextContent("N/A"))}
                                        {renderTableCell(formatDate(row?.createdAt) || renderTextContent("N/A"))}
                                        {renderTableCell(row?.user ? `${row?.user?.firstName} ${row?.user?.lastName}` : renderTextContent("N/A"))}
                                        {renderTableCell(row?.orderAmount ? `$ ${row.orderAmount?.totalAmount}` : renderTextContent("N/A"))}
                                        {renderTableCell(<OrderStatus orderId={row?._id} orderStatus={row?.orderStatus} /> || renderTextContent("N/A"))}
                                        {renderTableCell(renderActionButtons(row))}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    ) : (
                        <div className="flex flex-col gap-10 justify-center items-center mt-[150px] ">
                            <IconComponent
                                iconLabel={ICON_LABELS.NO_DATA_FOUND}
                                color="#EFEFEF"
                                fontSize="80px"
                            /><p className="text-[18px] font-[600] text-[#888888] opacity-[0.4]">No data found</p> </div>)}
                </TableContainer>)
            }

        </Paper>
    );
}
