import React from 'react';
import styles from "./form.module.css"
import IconComponent from '../icons/IconComponent';

const ViewButton = ({ onClick, children, icon, type, disabled,variant, ...props }) => {

  

  
  return (

   

    <button
      onClick={onClick}
      className={`d-flex gap-1  align-items-center my-3 ${!variant ? styles.btnbg : styles.viewBtn}`}
      type={type}
      disabled={disabled}
      {...props}
      
     
    >
       {icon ? <IconComponent iconLabel={icon} /> : null} 
       {children}
    </button>
  );
};

export default ViewButton;
