import React, { useEffect, useRef, useState } from 'react';
import Button from '../../components/formelements/Button';
import { STRINGS } from './strings';
import { Title } from '../../components/title/Title';

const RectangleSelection = ({ imageURL, width, height, rectangle, onRectangleUpdate }) => {
    
    const [rect, setRect] = useState(rectangle?.length!==0 ? { x: parseInt(rectangle[4]?.x), y: parseInt(rectangle[4]?.y), width: parseInt(rectangle[4]?.width), height: parseInt(rectangle[4]?.height) } : { x: 50, y: 50, width: 100, height: 100 });
    const [isSelected, setIsSelected] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const [isResizing, setIsResizing] = useState(false);
    const [resizingCorner, setResizingCorner] = useState(null);
    const [aspectRatio, setAspectRatio] = useState(null);
    const canvasRef = useRef(null);
    const offscreenCanvasRef = useRef(null);
    const handleSize = 8;

    useEffect(() => {
        if (imageURL) {
            const offscreenCanvas = offscreenCanvasRef.current;
            const offscreenCtx = offscreenCanvas.getContext('2d');
            const img = new Image();
            img.src = imageURL;
            img.onload = () => {
                offscreenCtx.clearRect(0, 0, offscreenCanvas.width, offscreenCanvas.height);
                offscreenCtx.drawImage(img, 0, 0, offscreenCanvas.width, offscreenCanvas.height);
                drawRect();
            };
        }
    }, [imageURL]);

    useEffect(() => {
        if (imageURL) {
            drawRect();
        }
    }, [rect]);

    const drawRect = () => {
        setIsSelected(false);

        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        const offscreenCanvas = offscreenCanvasRef.current;
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(offscreenCanvas, 0, 0, canvas.width, canvas.height);
        ctx.strokeStyle = '#E6355C';
        ctx.lineWidth = 2;
        ctx.strokeRect(rect.x, rect.y, rect.width, rect.height);
        drawHandle(ctx, rect.x, rect.y);
        drawHandle(ctx, rect.x + rect.width, rect.y);
        drawHandle(ctx, rect.x, rect.y + rect.height);
        drawHandle(ctx, rect.x + rect.width, rect.y + rect.height);
    };

    const drawHandle = (ctx, x, y) => {
        ctx.fillStyle = '#E6355C';
        ctx.fillRect(x - handleSize / 2, y - handleSize / 2, handleSize, handleSize);
    };

    const isInHandle = (mouseX, mouseY, x, y) => {
        return (
            mouseX >= x - handleSize / 2 &&
            mouseX <= x + handleSize / 2 &&
            mouseY >= y - handleSize / 2 &&
            mouseY <= y + handleSize / 2
        );
    };

    const handleMouseDown = (event) => {
        const canvas = canvasRef.current;
        const boundingRect = canvas.getBoundingClientRect();
        const scaleX = canvas.width / boundingRect.width;
        const scaleY = canvas.height / boundingRect.height;
        const mouseX = (event.clientX - boundingRect.left) * scaleX;
        const mouseY = (event.clientY - boundingRect.top) * scaleY;

        let corner = null;
        if (isInHandle(mouseX, mouseY, rect.x, rect.y)) corner = 'topLeft';
        if (isInHandle(mouseX, mouseY, rect.x + rect.width, rect.y)) corner = 'topRight';
        if (isInHandle(mouseX, mouseY, rect.x, rect.y + rect.height)) corner = 'bottomLeft';
        if (isInHandle(mouseX, mouseY, rect.x + rect.width, rect.y + rect.height)) corner = 'bottomRight';

        if (corner) {
            setIsResizing(true);
            setResizingCorner(corner);
            setAspectRatio(rect.width / rect.height);
        } else {
            setIsDragging(true);
        }

        setRect({ ...rect, startX: mouseX, startY: mouseY });
    };

    const handleMouseMove = (event) => {
        const canvas = canvasRef.current;
        const boundingRect = canvas.getBoundingClientRect();
        const scaleX = canvas.width / boundingRect.width;
        const scaleY = canvas.height / boundingRect.height;
        const mouseX = (event.clientX - boundingRect.left) * scaleX;
        const mouseY = (event.clientY - boundingRect.top) * scaleY;

        if (isDragging) {
            const offsetX = mouseX - rect.startX;
            const offsetY = mouseY - rect.startY;
            setRect((prevRect) => {
                const newX = Math.max(0, Math.min(prevRect.x + offsetX, width - prevRect.width));
                const newY = Math.max(0, Math.min(prevRect.y + offsetY, height - prevRect.height));
                return {
                    ...prevRect,
                    x: newX,
                    y: newY,
                    startX: mouseX,
                    startY: mouseY,
                };
            });
        } else if (isResizing) {
            let newRect = { ...rect };

            switch (resizingCorner) {
                case 'topLeft':
                    newRect.width = newRect.x + newRect.width - mouseX;
                    newRect.height = newRect.y + newRect.height - mouseY;
                    newRect.x = mouseX;
                    newRect.y = mouseY;
                    break;
                case 'topRight':
                    newRect.width = mouseX - newRect.x;
                    newRect.height = newRect.y + newRect.height - mouseY;
                    newRect.y = mouseY;
                    break;
                case 'bottomLeft':
                    newRect.width = newRect.x + newRect.width - mouseX;
                    newRect.height = mouseY - newRect.y;
                    newRect.x = mouseX;
                    break;
                case 'bottomRight':
                    newRect.width = mouseX - newRect.x;
                    newRect.height = mouseY - newRect.y;
                    break;
                default:
                    break;
            }

            if (event.shiftKey && aspectRatio) {
                if (newRect.width / newRect.height > aspectRatio) {
                    newRect.height = newRect.width / aspectRatio;
                } else {
                    newRect.width = newRect.height * aspectRatio;
                }
            }

            // Constrain rectangle to be within the image
            newRect.x = Math.max(0, newRect.x);
            newRect.y = Math.max(0, newRect.y);
            newRect.width = Math.max(0, Math.min(newRect.width, width - newRect.x));
            newRect.height = Math.max(0, Math.min(newRect.height, height - newRect.y));

            setRect(newRect);
        }
    };

    const handleMouseUp = () => {
        setIsDragging(false);
        setIsResizing(false);
        setResizingCorner(null);
        normalizeRect();
    };

    const normalizeRect = () => {
        let newRect = { ...rect };
        if (newRect.width < 0) {
            newRect.x += newRect.width;
            newRect.width *= -1;
        }
        if (newRect.height < 0) {
            newRect.y += newRect.height;
            newRect.height *= -1;
        }
        setRect(newRect);
    };

    const getCornerCoordinates = () => {
        setIsSelected(true);
        return [
            { x: rect.x, y: rect.y }, // top-left
            { x: rect.x + rect.width, y: rect.y }, // top-right
            { x: rect.x, y: rect.y + rect.height }, // bottom-left
            { x: rect.x + rect.width, y: rect.y + rect.height }, // bottom-right
            { x: rect.x, y: rect.y, width: rect.width, height: rect.height, startX: rect.startX, startY: rect.startY } // position : x , y / width / height
        ];
    };

    const handleClipButtonClick = () => {
        const corners = getCornerCoordinates();
        if (onRectangleUpdate) {
            onRectangleUpdate(corners);
        }
    };

    const handleResetRectangle = () => {
        setRect({ x: 50, y: 50, width: 100, height: 100 });
        onRectangleUpdate([]);
        setIsSelected(false);
    };

    return (
        <div className='flex w-full justify-start gap-[40px]'>
            {imageURL && (
                <div style={{ width: `${width}px`, height: `${height}px`, position: 'relative' }}>
                    <canvas
                        ref={offscreenCanvasRef}
                        width={width}
                        height={height}
                        style={{ display: 'none' }}
                    />
                    <canvas
                        ref={canvasRef}
                        width={width}
                        height={height}
                        onMouseDown={handleMouseDown}
                        onMouseMove={handleMouseMove}
                        onMouseUp={handleMouseUp}
                        style={{ border: '1px solid #E6355C', width: '100%', height: '100%', borderRadius: '10px' }}
                    />
                </div>
            )}
            <div className='flex flex-col gap-4'>
                <div className='h-fit flex gap-4 '>
                    <Button type="button" onClick={handleClipButtonClick}>Set Selected Area</Button>
                    <Button type="button" variant onClick={handleResetRectangle}>Reset</Button>
                </div>
                <Title
                    pagetitle={isSelected ? STRINGS.AREA_SELECTED : STRINGS.CLICK_SELECTION_BUTTON}
                    fontsize="16px"
                    fontWeight="500"
                    color={isSelected ? "green" : "red"}
                />
            </div>
        </div>
    );
};

export default RectangleSelection;
