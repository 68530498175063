import React, { useEffect, useState } from 'react';
import { STRINGS } from "./strings";
import { Title } from "../../components/title/Title";
import { Link, useLocation, useParams } from "react-router-dom";
import IconComponent from "../../components/icons/IconComponent";
import { ORDER_MANAGEMENT_PATH } from "../../utils/constants/routes";
import { ICON_LABELS } from "../../components/icons";
import styles from "./ordermanagement.module.css";
import { ParaGraph } from "../../components/title/ParaGraph";
import { get_order_details } from "../../utils/api/utilAPI";
import Paper from "@mui/material/Paper";
import { useDispatch, useSelector } from 'react-redux';
import { loadingActions } from '../../utils/redux/features/loadingSlice';
import OrderStatusBadge from './Components/OrderStatusBadge';

export const ViewOrder = () => {
  const location = useLocation();
  const { id } = useParams();
  // const { data: initialData } = location.state || {};

  const [data, setData] = useState({});

  const loading = useSelector((state) => state?.loadingReducer?.loading);
  const dispatch = useDispatch();


  const handleOrderDetails = async (id) => {
    dispatch(loadingActions.loadingState(true));
    try {
      const res = await get_order_details(id);
      setData(res?.data?.data);
    } catch (error) {
      console.error("Error fetching order details:", error);
    } finally {
      dispatch(loadingActions.loadingState(false));
    }
  };


  useEffect(() => {
    handleOrderDetails(id);
  }, []);


  return (
    <div className="flex flex-col h-full justify-start gap-6 scrollPage">
      {!loading &&
        <Paper
          className="paperClass"
          component={Paper}>
          <div>
            <div className="flex gap-20 border-bottom mb-3">
              <Link to={ORDER_MANAGEMENT_PATH} className={styles.link}>
                <IconComponent iconLabel={ICON_LABELS.KEYBOARD_BACKSPACE} />
              </Link>
              <Title
                pagetitle={STRINGS.VIEW_ORDER}
                fontsize="20px"
                fontWeight="600"
                color="#060709"
                border={false}
                paddingBottom="1rem"
              />
            </div>

            <div className="my-4">
              <div className="flex flex-row gap-2">
                <ParaGraph
                  text="Order ID:"
                  fontsize="16px"
                  fontWeight="600"
                  color="#060709"
                />
                <ParaGraph
                  text={`${data?.orderId}`}
                  fontsize="16px"
                  fontWeight="500"
                  color="#858585"
                />
              </div>

              <div className="flex flex-row gap-2">
                <ParaGraph
                  text="Total Orders:"
                  fontsize="16px"
                  fontWeight="600"
                  color="#060709"
                />
                <ParaGraph
                  text={`${data?.orderItemCount}`}
                  fontsize="16px"
                  fontWeight="500"
                  color="#858585"
                />
              </div>
            </div>

            <OrderStatusBadge status={data?.orderStatus} />

            {data?.orderItems?.map((item, index) => (
              <div
                key={index}
                className="flex flex-row justify-between items-center border rounded px-4 py-2 m-2"
              >
                {item.productDetails.productImages[0] && (
                  <div className="flex flex-row items-center gap-2 w-[40%]">
                    <div className={`my-3 ${styles.imgWidth}`}>
                      <img
                        src={item.productDetails.productImages[0]}
                        alt="Product Image"
                        className="rounded"
                      />
                    </div>
                    <div>
                      <ParaGraph
                        text={`${item?.productDetails?.productName}`}
                        fontsize="16px"
                        fontWeight="500"
                        color="#858585"
                        textTransform="capitalize"
                      />
                      <ParaGraph
                        text={`Category: ${item?.productDetails?.category?.categoryName || "N/A"}`} 
                        fontsize="14px"
                        fontWeight="300"
                        color="#858585"
                        textTransform="capitalize"
                      />
                    </div>
                  </div>
                )}
                <ParaGraph
                  text={`$${item?.productDetails?.basePrice} X ${item?.qty}`}
                  fontsize="16px"
                  fontWeight="500"
                  color="#858585"
                />
                <ParaGraph
                  text={`$${(item.productDetails.basePrice * item.qty).toFixed(2)}`}
                  fontsize="16px"
                  fontWeight="500"
                  color="#858585"
                />
              </div>
            ))}

            <div className="m-2 text-right">
              <ParaGraph
                text={`Subtotal: $${data?.orderAmount?.subTotal}`}
                fontsize="16px"
                fontWeight="400"
                color="#858585"
              />
              <ParaGraph
                text={`Shipping: $${data?.orderAmount?.shippingCharges}`}
                fontsize="16px"
                fontWeight="400"
                color="#858585"
              />
              {/* <ParaGraph
                text={`Taxes: $${data?.orderAmount?.tax}`}
                fontsize="16px"
                fontWeight="400"
                color="#858585"
              /> */}
              {data?.orderAmount?.discount > 0 && (
                <ParaGraph
                  text={`Discount: $${data?.orderAmount?.discount}`}
                  fontsize="18px"
                  fontWeight="400"
                  color="#858585"
                />
              )}
              <ParaGraph
                text={`Total: $${data.orderAmount?.totalAmount || 'N/A'}`}
                fontsize="16px"
                fontWeight="500"
                color="#060709"
              />
            </div>

          </div>
        </Paper>
      }
    </div>

  );
};
