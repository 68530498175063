import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { renderTableCell, renderTableHeading, renderTextContent } from "../../utils/helperFuncs";
import OrderStatusBadge from "../ordermanagement/Components/OrderStatusBadge";

const tableHeading = [
    "S.No.",
    "User Name",
    "Product ID",
    "Product Category",
    "Amount",
    "Order Status",
];

export default function DashboardTable({ tableData }) {
    return (
        <Paper
            className="randomClass1 flex-grow flex flex-col justify-start pt-3 gap-[24px] shadow-none !rounded-none border-t-[1px]"
            component={Paper}
        >
            <TableContainer className="scrollContainer w-auto overflow-auto">
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow className="h-11">
                            {tableHeading?.map((heading) => renderTableHeading(heading))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tableData?.map((row, index) => (
                            <TableRow key={row?._id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                {renderTableCell(<p>{index + 1}</p>)}
                                {renderTableCell(row?.user ? `${row?.user?.firstName} ${row?.user?.lastName}` : renderTextContent("N/A"))}
                                {renderTableCell(row?.orderId || renderTextContent("N/A"))}
                                {renderTableCell(row?.orderPayment?.paymentAmount ? `$ ${row.orderPayment.paymentAmount}` : renderTextContent("N/A"))}
                                {renderTableCell(row?.orderPayment?.paymentAmount ? `$ ${row.orderPayment.paymentAmount}` : renderTextContent("N/A"))}
                                {renderTableCell(<OrderStatusBadge status={row?.orderStatus}/> || renderTextContent("N/A"))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
}
