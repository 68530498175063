import { ICON_LABELS } from "../components/icons";
import {
	COLLABOARTORS_PATH,
	COMMUNITY_PATH,
	DASHBOARD_PATH,
	EVENT_PATH,
	FINANCE_MANAGEMENT_PATH,
	MERCHANDISE_PATH,
	MUSIC_MANAGEMENT_PATH,
	MY_PROFILE_PATH,
	ORDER_MANAGEMENT_PATH,
	PROMO_CODE_PATH,
	USER_MANAGEMENT_PATH,
} from "./constants/routes";

export const DRAWER_CONTENT = [
	{
		text: "Dashboard",
		path: DASHBOARD_PATH,
		icon: ICON_LABELS.GRID_VIEW,
	},
	{
		text: "User Management",
		path: USER_MANAGEMENT_PATH,
		icon: ICON_LABELS.PERSON,
	},
	{
		text: "Collaborators",
		path: COLLABOARTORS_PATH,
		icon: ICON_LABELS.HANDSHAKE,
	},
	{
		text: "Community Management",
		path: COMMUNITY_PATH,
		icon: ICON_LABELS.GROUPS,
	},
	{
		text: "Music Management",
		path: MUSIC_MANAGEMENT_PATH,
		icon: ICON_LABELS.MUSIC_NOTE,
	},
	{
		text: "Order Management ",
		path: ORDER_MANAGEMENT_PATH,
		icon: ICON_LABELS.ORDER,
	},
	{
		text: "Merchandise",
		path: MERCHANDISE_PATH,
		icon: ICON_LABELS.SHOP,
	},
	{
		text: "Event",
		path: EVENT_PATH,
		icon: ICON_LABELS.EVENT_NOTE,
	},
	// {
	// 	text: "Finance Management",
	// 	path: FINANCE_MANAGEMENT_PATH,
	// 	icon: ICON_LABELS.BAR_CHART,
	// },
	{
		text: "Promo Code",
		path: PROMO_CODE_PATH,
		icon: ICON_LABELS.PROMO_CODE,
	},
	{
		text: "My Profile",
		path: MY_PROFILE_PATH,
		icon: ICON_LABELS.MY_PROFILE,
	},

];
