import { wrappedFetch, wrappedGet } from "./urlFuncs";
import {
  FORGOT_URL,
  LOGIN_URL,
  RESET_URL,
  // LOGOUT_URL,
  //   FORGET_PASSWORD,
  // VERIFY_OTP,
  // RESET_PASSWORD_URL,
  // ENABLE_NOTIFICATION,
} from "./urlConstants";

export const login = (data) => {
  return wrappedFetch(LOGIN_URL, "POST", data);
};

export const forgotPassword = (data) => {
  return wrappedFetch(FORGOT_URL, "POST", data);
};

export const resetPassword = (data) => {
  return wrappedFetch(RESET_URL, "POST", data);
};
