import { React,  useState } from "react";
import { Title } from "../../components/title/Title";
import IconComponent from "../../components/icons/IconComponent";
import { ICON_LABELS } from "../../components/icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {  PROMO_CODE_PATH } from "../../utils/constants/routes";
import { TextInput } from "../../components/formelements/TextInput";
import Button from "../../components/formelements/Button";

import {  Paper } from "@mui/material";
import {  add_promocode, edit_promocode } from "../../utils/api/utilAPI";
import { toast } from "react-toastify";
import {  AddPromoCodeValidations } from "../../utils/validations/joiValidations";


export const AddPromoCode = () => {
    const location = useLocation();
	const navigate = useNavigate();
    const [formData , setFormData] = useState({
        promoName : location ? location?.state?.promoCodeName : "" ,
        promoCode : location ? location?.state?.promoCode : "",
        minPrice :location ? location?.state?.minimumPrice : "",
        discount : location ? location?.state?.discount : "",
    })
    const [validation , setValidation] = useState({
        promoName : "" ,
        promoCode : "",
        minPrice : "",
        discount : "",
    })
    const handleDataChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };
	const handleAddProduct = async() => {
		const data = {
			promoCodeName : formData?.promoName ,
			promoCode : formData?.promoCode ,
			minimumPrice : formData?.minPrice , 
			discount : formData?.discount ,
		}
		try {
			await add_promocode(data) ;
            navigate(PROMO_CODE_PATH);
			toast.success("Promo Code Added Successfully" , {toastId : "successfully added promocode"})
		} catch (error) {
			toast.error("Error Adding Promo Code"  , {toastId : "Error adding promocode"})
		}
	} 
	const handleEditProduct = async() => {
		const data = {
			promoCodeName : formData?.promoName ,
			promoCode : formData?.promoCode ,
			minimumPrice : formData?.minPrice , 
			discount : formData?.discount ,
			active : location?.state?.isActive,
			id : location?.state?._id
		}
		try {
			await edit_promocode(data) ;
            navigate(PROMO_CODE_PATH);
			toast.success("Promo Code Edited Successfully" , {toastId : "successfully edited promocode"})
		} catch (error) {
			toast.error("Error Edited Promo Code"  , {toastId : "Error editing promocode"})
		}
	} 
    const handleSubmit = (e) => {
		e.preventDefault();
		const { error } = AddPromoCodeValidations.validate({
		  promoName: formData.promoName,
		  promoCode: formData.promoCode,
		  minPrice: formData.minPrice,
		  discount: formData.discount,
		});
		if (error) {
		  const validationErrors = error.details.reduce((acc, curr) => {
			acc[curr.path[0]] = curr.message;
			return acc;
		  }, {});
		  setValidation(validationErrors);
		} else {
		  setValidation({});
		  location?.state ? handleEditProduct() : handleAddProduct();
		}
		
    }
	return (
		<div className="flex flex-col !h-full  justify-start gap-6  scrollPage">
			<Paper
				className="paperClass "
				component={Paper}
			>

				<div className="flex gap-20 border-bottom pb-3">
					<Link to={PROMO_CODE_PATH} >
						<IconComponent iconLabel={ICON_LABELS.KEYBOARD_BACKSPACE} />
					</Link>
					<Title
						pagetitle={location?.state ? "Edit Promo Code" : "Add Promo Code"}
						fontsize="20px"
						fontWeight="600"
						color="#060709"
						border={false}
					/>
				</div>

				<TextInput
					type="text"
                    name="promoName"
					label={"Promo Code Name"}
					placeholder={"Enter Promo Code Name"}
					error={validation?.promoName}
					value={formData?.promoName}
                    onChange={handleDataChange}
				/>
				<TextInput
                    type="text"
                    name="promoCode"
					label={"Promo Code"}
					placeholder={"Enter Promo Code"}
					value={formData?.promoCode}
					error={validation?.promoCode}
                    onChange={handleDataChange}
				/>
                <TextInput
                    type="number"
                    name="minPrice"
					label={"Minimum price on which that code is applicable"}
					placeholder={"Enter Price"}
					value={formData?.minPrice}
					error={validation?.minPrice}
                    onChange={handleDataChange}
				/>
				 <TextInput
                    type="text"
                    name="discount"
					label={"Discount Offered"}
					placeholder={"Enter Discount"}
					value={formData?.discount}
					error={validation?.discount}
                    onChange={handleDataChange}
				/>
			    
				
				<div className="flex justify-content-end gap-20 my-3">
					<Button variant="secondary" onClick={() => navigate(PROMO_CODE_PATH)}>
						Cancel
					</Button>
					<Button type="submit" onClick={handleSubmit}>						
                        {location?.state ? "Edit" :"Submit"}
					</Button>
				</div>
			</Paper >
		</div >
	);
};
