import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { ICON_LABELS } from "../../components/icons";
import {  renderTableCell, renderTableHeading, renderTextContent } from "../../utils/helperFuncs";
import IconComponent from "../../components/icons/IconComponent";
import { toast } from "react-toastify";
import DeletePopup from "../../components/formelements/DeletePopup";
import { useNavigate } from "react-router-dom";
import { ADD_PROMO_CODE_PATH } from "../../utils/constants/routes";
import { delete_promocode, update_promocode } from "../../utils/api/utilAPI";
import { alpha, styled, Switch } from "@mui/material";
const StatusSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: "#E6355C",
      '&:hover': {
        backgroundColor: alpha("#E6355C", theme.palette.action.hoverOpacity),
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: "#E6355C",
    },
  }));
const tableHeading = [
    "S.No.",
    "Promocode Name",
    "Code",
    "Minimum Price on which code is applicable",
    "Discount Offered",
    "Status",
    "Actions",
];

export default function PromoCodeTable({ tableData, loadingData , sendAction }) {
    const navigate = useNavigate();
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [promoId , setPromoId] = useState("");
    const handleDeleteModal = (id) => {
        setPromoId(id)
        setDeleteModalOpen(true);
      }
      const handleEditPromocode = (row) => {
        navigate(ADD_PROMO_CODE_PATH ,{ state:  row });
      }
    const handleDelete = async () => {
        try {
            await delete_promocode({id : promoId})
          setDeleteModalOpen(false);
          toast.success("Promo Code deleted successfully", {
            toastId: "Promo Code Deleted"
          });
          sendAction(true);
        } catch (error) {
          toast.error(error.response?.data?.message, {
           toastId: "Error deleting promo code"
          });
          setDeleteModalOpen(false);
        }}

    const handleClose = () => {
            setDeleteModalOpen(false);
          }
    const handleStatusChange = async(id ,status) => {
        try {
            await update_promocode({id : id , isActive : !status})
            toast.success("Status Updated Successfully" , {toastId: "status updated successfully"})
            sendAction(true);
        } catch (error) {
            toast.error("Failed Updating Status" , {toastId: "status updated failed"})

        }
    }
    const renderActionButtons = (row) => (
        <div className="flex justify-start p-auto gap-2">
           

            <button
                type="button"
                className={`w-[44px] h-[44px] bg-[#E4FFDD] rounded-[5.02px] px-[8px] py-[10px]`}
                onClick={() => handleEditPromocode(row)}
            >
                <IconComponent
                    iconLabel={ICON_LABELS.EDIT}
                    color="#167D4C"
                    fontSize="24px"
                />
            </button>
            <button
                type="button"
                className="w-[44px] h-[44px] bg-[#FFDDDD] rounded-[5.02px] px-[8px] py-[10px]"
                onClick={() => handleDeleteModal(row?._id)}

            >
                <IconComponent
                    iconLabel={ ICON_LABELS.DELETE}
                    color="#E63535"
                    fontSize="24px"
                   
                />
            </button>

        </div>

    )

    return (
        <Paper
            className="randomClass1 flex-grow flex flex-col justify-start pt-3 gap-[24px] shadow-none !rounded-none border-t-[1px]"
            component={Paper}
        >
            {loadingData ? (
                ""
            ) : (
                <TableContainer className="scrollContainer w-auto overflow-auto">
                    {tableData?.length !== 0 ? (
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow className="h-11 p-3">
                                    {tableHeading?.map((heading) => renderTableHeading(heading))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tableData?.map((row, index) => (
                                    <TableRow key={row?._id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                        {renderTableCell(<p>{index + 1}</p>)}
                                        {renderTableCell(row?.promoCodeName || renderTextContent("N/A"))}
                                        {renderTableCell(row?.promoCode || renderTextContent("N/A"))}
                                        {renderTableCell(row?.minimumPrice ? `$${row.minimumPrice}` : renderTextContent("N/A"))}
                                        {renderTableCell(row?.discount ||  renderTextContent("N/A"))}
                                        {renderTableCell(<StatusSwitch checked={row?.isActive}  onChange={() => handleStatusChange(row?._id , row?.isActive)} />)}
                                        {renderTableCell(renderActionButtons(row))}


                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    ) : (
                        <div className="flex flex-col gap-10 justify-center items-center mt-[150px] ">
                            <IconComponent
                                iconLabel={ICON_LABELS.NO_DATA_FOUND}
                                color="#EFEFEF"
                                fontSize="80px"
                            /><p className="text-[18px] font-[600] text-[#888888] opacity-[0.4]">No data found</p> </div>)}
                </TableContainer>)

            }
      <DeletePopup modal={deleteModalOpen} handleDone={handleDelete} handleClose={handleClose} />

        </Paper>
    )
}
