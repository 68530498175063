//Auth Constants
export const LOGIN_URL = "admin/login";
export const FORGOT_URL = "admin/forgotPassword";
export const RESET_URL = "admin/resetPassword";

// MERCHANDISE
export const GET_CAT = "admin/category";
export const GET_PROD = "admin/product";
export const GET_PROD_CAT = "admin/products";
export const GET_ART = "admin/artType";
export const SEARCH_PROD = "admin/searchProduct";

// ORDER MANAGEMENT 
export const GET_ORDERS = "admin/order/getAllOrder";
export const SEARCH_ORDERS = "admin/order/searchOrder"
export const ORDER_DETAILS = "/admin/order/getOrderDetails"
export const ORDER_STATUS = '/admin/order/changeOrderStatus'

// COLLABORATORS 
export const GET_COLLAB_REQUESTS = "admin/getCollaborationRequests"
export const POST_COLLAB = "admin/toggleCollaborationRequestStatus"
export const ADD_COLLABORATOR = "admin/addCollaborator"

// USER MANAGEMENT
export const GET_ALL_USER = "admin/user/getAllUser";
export const SEARCH_USER = "admin/user/searchUser";
export const BLOCK_UNBLOCK_USER = "admin/user/blockedUser";
export const DELETE_USER = "admin/user/userDeletion";

//MUSIC MANAGEMENT
export const GET_ALL_MUSIC = "admin/music/getAllMusic";
export const GET_MUSIC_REQUEST = "admin/music/getAllMusicRequest";
export const ACCEPT_MUSIC = "admin/music/editMusicRequest";
export const DELETE_MUSIC = "admin/music/deleteMusicById";

//PROMO CODE 
export const GET_ALL_PROMOCODE = "admin/getAllPromoCode";
export const GET_PROMOCODE_DETAIL = "admin/getPromoCode";
export const DELETE_ALL_PROMOCODE = "admin/deletePromoCode";
export const UPDATE_PROMOCODE_STATUS = "admin/updatePromoCodeStatus";
export const EDIT_PROMOCODE = "admin/editPromoCode";
export const ADD_PROMOCODE = "admin/addPromoCode";
export const SEARCH_PROMOCODE = "admin/searchPromocode";

//EVENT MANAGEMENT
export const GET_ALL_ACCEPTED_EVENT = "admin/event/getAllAcceptedEvent";
export const ADD_EVENT = "admin/event/addEVent";
export const GET_REQUESTED_EVENT = "admin/event/getAllRequestedEvent";
export const RESPOND_TO_EVENT = "admin/event/toggleEventRequest";
export const GET_EVENT_DETAILS = "admin/event/getEventById";

// ORDER MANAGEMENT 
export const GET_ALL_DASHBOARD_DATA = "admin/dashboard/getDashboardData"

// ADMIN PROFILE UPDATE
export const GET_ADMIN_PROFILE = 'admin/getProfile'
export const EDIT_ADMIN_PROFILE = 'admin/editProfile'
export const CHANGE_ADMIN_PASSWORD = 'admin/changePassword'