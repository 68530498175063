import styles from '../ordermanagement.module.css'

const OrderStatusBadge = ({ status }) => {
    switch (status) {
        case 1:
            return <div className={`${styles.success} ${styles.lables}`}>Paid</div>;
        case 2:
            return <div className={`${styles.transit} ${styles.lables}`}>Transit</div>;
        case 3:
            return <div className={`${styles.success} ${styles.lables}`}>Delivered</div>;
        case 4:
            return <div className={`${styles.placed} ${styles.lables}`}>Refunded</div>;
        case 5:
            return <div className={`${styles.cancelled} ${styles.lables}`}>Cancelled</div>;
        default:
            return <div>{status}</div>;
    }
};

export default OrderStatusBadge;