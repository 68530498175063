import React from 'react';
import styles from "./form.module.css"

const Button = ({ onClick, children, className, type, disabled, variant, ...props }) => {
  return (
    <button
      onClick={onClick}
      className={`${!variant ? styles.btnbg : styles.secondry} ${className}`}
      type={type}
      disabled={disabled}
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;
