import React from 'react';
import { Pagination, Stack } from '@mui/material';

const PageSelector = ({ totalCount, limit, page, handlePageChange }) => {
  return (
    <Stack spacing={2}>
      <Pagination
        count={Math.ceil(totalCount / limit)}
        defaultPage={1}
        siblingCount={3}
        shape="rounded"
        page={page}
        onChange={handlePageChange}
        sx={{
          '& .Mui-selected': {
            background: "#E6355C !important", // Selected page background color
            color: '#ffffff', // Selected page text color
            fontFamily: "Poppins" ,
            '&:hover': {
                background:"#E6355C !important" ,  // Selected page hover background color
              color: '#ffffff', // Selected page hover text color
              fontFamily: "Poppins" ,

            },
          },
          '& .MuiPaginationItem-root:hover': {
            background: "#E6355C !important",  // Hover background color
            color: '#ffffff',
            fontFamily: "Poppins" ,

          },
        }}
      />
    </Stack>
  );
};

export default PageSelector;