import { Tab, Tabs } from '@mui/material';
import React, { useState, useEffect } from 'react';
import styles from "./tabs.module.css";
import PropTypes from "prop-types";

function DynamicTabs({ tabLabels, onTabSelect }) {
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    if (onTabSelect) {
      onTabSelect(tabLabels[activeTab]);
    }
  }, [activeTab]);

  const handleTabChange = (event, newTab) => {
    setActiveTab(newTab);
  };

  return (
    <div>
      <Tabs
        key={activeTab}
        value={activeTab}
        onChange={handleTabChange}
        className={styles.tabsbg}
        TabIndicatorProps={{ style: { display: 'none' } }}
      >
        {tabLabels?.map((tab, index) => (
          <Tab
            key={tab?.vlaue}
            label={tab.label}
            className={activeTab === index ? styles.activetabs : styles.tabs}
          />
        ))}
      </Tabs>

    </div>
  );
}
DynamicTabs.propTypes = {
  tabLabels: PropTypes.array,
  onTabSelect: PropTypes.func,
};

export default DynamicTabs;