export const MESSAGE_INVALID_NAME_LENGTH = "This is a required field.";
 
export const MESSAGE_INVALID_PASSWORD =
  "Password length should be greater than or equal to 8 characters.";
export const MESSAGE_INVALID_EMAIL_PATTERN = "Enter a valid Email.";
export const MESSAGE_INVALID_EMAIL_LENGTH = "Email is a required field.";
export const MESSAGE_INVALID_PASSWORD_LENGTH = "Password is required.";
export const MESSAGE_CONFIRMED_INVALID_PASSWORD_LENGTH =
  "Confirm Password is required.";
export const NEW_PASSWORD_LENGTH =
  "Please provide your new password to proceed.";
export const MESSAGE_INVALID_CONF_PASSWORD_LENGTH =
  "Confirm Password should be greater than or equal to 8.";
export const MESSAGE_INVALID_NEW_PASSWORD_LENGTH =
  "New Password should be greater than or equal to 8.";
 
export const MESSAGE_PASSWORD_CONFIRMPASSWORD_MISMATCH =
  "Password and confirm password should be same.";
export const CONFIRM_PASSWORD_LENGTH =
  "Please provide your confirm password to proceed.";
export const MESSAGE_PASSWORD_NEW_PASSWORD_MISMATCH =
  "Old Password and New Password should not be same.";
 
 

 

 