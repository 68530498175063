import React, { useState } from "react";
import styles from "./Login.module.css";
import { STRINGS } from "./strings";
import { TextInput } from "../../components/formelements/TextInput";
import Button from "../../components/formelements/Button";
import { emailValidation } from "../../utils/validations/inputValidations";
import { forgotPassword } from "../../utils/api/authAPI";
import { useNavigate } from "react-router-dom";
import { LOGIN_PATH } from "../../utils/constants/routes";

const ForgotPassword = () => {
	const navigate = useNavigate();
	const [email, setEmail] = useState("");

	const [validations, setValidations] = useState({
		email: "",
	});

	const handleSubmit = (e) => {
		e.preventDefault();

		const validatedEmail = emailValidation(email);

		if (validatedEmail.isValid) {
			setValidations({
				email: "",
			});

			forgotPassword({ email: email.toLowerCase() })
				.then((res) => {
					setValidations({
						email: "Please check your registered mail to reset your password.",
						isError: false,
					});
					setEmail("")
					setTimeout(() => {
						navigate(LOGIN_PATH);
					}, 5000);
				})
				.catch((err) => {
					setValidations({
						email: err?.response?.data?.message,
					});
				});
		} else {
			setValidations({
				email: validatedEmail.message,
			});
		}
	};

	return (

		<div className={`col-md-5 mx-auto ${styles.forgotcard}`}>
			<div className={`${styles.bg} p-4`}>
				<div>
					<h1 className={styles.title}>{STRINGS.forgotpassword}</h1>
					<p>{STRINGS.forgotpasstext}</p>
					<TextInput
						type="text"
						value={email}
						label={STRINGS.email}
						placeholder={STRINGS.emailplaceholder}
						onChange={(e) => setEmail(e.target.value)}
						error={validations.email}
						isError={validations.isError}
					/>
					<Button
						onClick={handleSubmit}
						className={`btn w-100 ${styles.btnbg}`}
						type="button"
						disabled={false}
					>
						{STRINGS.reset}
					</Button>
				</div>
			</div>
		</div>

	);
};

export default ForgotPassword;
