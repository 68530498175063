export const STRING = {
PAGE_TITLE:"User Management",
NAME:"User Name",
NAME_PLACEHOLDER:"Enter User Name",
EMAIL:"User Email",
EMAIL_PLACEHOLDER:"Enter User Email",
USER_TYPE:"User Type",
USER_TYPE_LABEL:"Select User Type",
BTNTEXTSUBMIT:"Save",
BTNTEXTCANCEL:"Cancel",
}