import React, { useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { change_order_status } from '../../../utils/api/utilAPI';
import { toast } from 'react-toastify';

const OrderStatus = ({ orderId, orderStatus }) => {
    const [selectOrderStatus, setSelectOrderStatus] = useState(orderStatus);
    const [tempOrderStatus, setTempOrderStatus] = useState(orderStatus);

    const handleChange = (event) => {
        const status = event.target.value;
        setTempOrderStatus(status);
        handleOrderStatus(orderId, status);
    };

    const handleOrderStatus = async (id, status) => {
        try {
            const response = await change_order_status({ id: id, orderStatus: status });
            toast.success(response?.data?.message, {
                toastId: "Response Order Status"
            });
            setSelectOrderStatus(status); 
        } catch (error) {
            toast.error(error?.response?.data?.message || 'Error updating order status', {
                toastId: "Error Order Status"
            });
            setTempOrderStatus(selectOrderStatus);
        }
    };

    return (
        <Select
            labelId="order-status-select-label"
            id="order-status-select"
            value={tempOrderStatus}
            onChange={handleChange}
            sx={{ width: '150px' }}
        >
            <MenuItem value={1}>Paid</MenuItem>
            <MenuItem value={2}>Transit</MenuItem>
            <MenuItem value={3}>Delivered</MenuItem>
            <MenuItem value={4}>Refunded</MenuItem>
            <MenuItem value={5}>Cancelled</MenuItem>
        </Select>
    );
};

export default OrderStatus;

