// import { BorderBottom } from "@mui/icons-material";
import React from "react";

export const Title = ({
	pagetitle,
	fontsize,
	fontWeight,
	color,
	border,
	...props
}) => {
	const fontStyles = {
		fontSize: fontsize ? fontsize : "24px",
		fontWeight: fontWeight ? fontWeight : "400",
		color: color ? color : "#f00",
		borderBottom: border ? "1px solid #E4E4E4" : "",
		...props,
	};

	return (

		<h3 style={fontStyles}>
			{pagetitle}
		</h3>

	);
};
