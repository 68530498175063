import React from "react";
import { Title } from "../../components/title/Title";
import { TextInput } from "../../components/formelements/TextInput";
import SelectBox from "../../components/formelements/SelectBox";
import { VIDEO_THUMB } from "../../utils/constants/assets";
import ViewButton from "../../components/formelements/ViewButton";
import { ICON_LABELS } from "../../components/icons";

export const FinanceManagement = () => {
  const DROPDOWN_EMAIL_VIEWS = ["All", "Everyone", "Any"];

  const headCells = [
    {
      key: "sno",

      label: "S.No.",
    },
    {
      key: "product",

      label: "Product",
    },
    {
      key: "collection",

      label: "Collection Type",
    },
    {
      key: "size",

      label: "Size",
    },
    {
      key: "price",
      label: "Price",
    },

    {
      key: "purchase",
      label: "Date of Purchase"
    },

    {
      key: "source",
      label: "Medium of purchase"
    },
    {
      key: "action",

      label: "Actions",
    },
  ];



  const musicTitleCol = (
    <>
      <img src={VIDEO_THUMB} alt="" />
      <span className='ps-3'>Kathryn Murphy</span>
    </>
  )
  const ActionButton = (
    <div className='d-flex align-items-center'>

      <SelectBox
        items={DROPDOWN_EMAIL_VIEWS}
        placeholder={"Action"}
        value="test"
        wkeyth="120px"
        margin="8px"
        background="rgb(228, 228, 228)"
      />

    </div>
  )
  const musicreview = [
    {
      sno: "1",
      product: musicTitleCol,
      collection: "Play time here",
      size: "Large",
      price: "$26",
      purchase: "8/16/13",
      playnumber: "65",
      source: "Medium of purchase here",
      action: ActionButton
    },
    {
      sno: "1",
      product: musicTitleCol,
      collection: "Play time here",
      size: "Medium",
      price: "$26",
      purchase: "8/16/13",
      playnumber: "65",
      source: "Medium of purchase here",
      action: ActionButton
    },

  ];

  return (
    <>
      <div className="row my-4 align-items-center border-bottom pt-3">
        <div className="col-md-6">
          <Title
            pagetitle={"Finance Management"}
            fontsize="18px"
            fontWeight="600"
            color="#222124"
          />
        </div>

        <div className="col-md-6">
          <div className="d-flex justify-content-end align-items-center">
            <TextInput
              type="text"
              placeholder="Search"
              style={{ background: "#E4E4E4" }}
              marginbot="0"
            />
            <SelectBox
              items={DROPDOWN_EMAIL_VIEWS}
              placeholder={"Filter"}
              value="Music Collab’s"
              wkeyth="120px"
              margin="8px"
              background="rgb(228, 228, 228)"
            />
          </div>
        </div>
      </div>

    </>

  );
};
