import React from "react";
import Paper from "@mui/material/Paper";
import { Table, TableBody, TableContainer, TableHead, TableRow } from "@mui/material";
import PropTypes from "prop-types";
import { renderTableCell, renderTextContent, renderTableHeading, renderNameWithProfile } from "../../utils/helperFuncs";
import { ICON_LABELS } from "../../components/icons";
import IconComponent from "../../components/icons/IconComponent";
import { post_collab } from "../../utils/api/utilAPI";
import { toast } from "react-toastify";

const tableHeading = [
    "S. No.",
    "Name",
    "Email Address",
    "Phone Number",
    "Actions"
];


const CollaboratorTable = ({ tableData, loadingData, sendAction }) => {

    const handleCollabRequest = async (id, status) => {
        try {
            const response = await post_collab(id, { accepted: status });
            toast.success(response?.data?.message, {
                toastId: " responding to collab request"
            })
            sendAction(false);
        } catch (error) {
            toast.error(error?.response?.data?.message, {
                toastId: "Error responding to collab request"
            })
        }
    }
    const renderActionButtons = (row) => (
        <div className="flex justify-start p-auto gap-2 ">

            <button
                type="button"
                className={`w-[44px] h-[44px] ${row?.isBlocked ? "bg-[#FFEEE1]" : "bg-[#E4FFDD]"} rounded-[5.02px] px-[8px] py-[10px]`}
                onClick={() => handleCollabRequest(row?._id, true)}
            >

                <IconComponent
                    iconLabel={ICON_LABELS.CHECK_CIRCLE}
                    color="#167D4C"
                    fontSize="24px"
                />

            </button>


            <button
                type="button"
                className="w-[44px] h-[44px] bg-[#FFDDDD] rounded-[5.02px] px-[8px] py-[10px]"
                onClick={() => handleCollabRequest(row?._id, false)}
            >
                <IconComponent
                    iconLabel={ICON_LABELS.CANCEL}
                    color="#E63535"
                    fontSize="24px"
                />
            </button>

        </div>
    );

    return (
        <Paper
            className="randomClass1 flex-grow flex flex-col justify-start pt-3 gap-[24px] shadow-none !rounded-none border-t-[1px]"
            component={Paper}
        >

            {loadingData ? (
                ""
            ) : (
                <TableContainer className="scrollContainer w-auto overflow-auto">
                    {tableData.length !== 0 ? (
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow className="h-11 p-3">
                                    {tableHeading?.map((heading) => renderTableHeading(heading))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tableData?.map((row, index) => (
                                    <TableRow key={row?._id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                        {renderTableCell(<p>{index + 1}</p>)}
                                        {renderTableCell(renderNameWithProfile(`${row?.userDetails?.firstName} ${row?.userDetails?.lastName}`, row?.userDetails?.profilePicture) || renderTextContent("Deleted User"))}
                                        {renderTableCell(row?.userDetails?.email || renderTextContent("N/A"))}
                                        {renderTableCell(row?.userDetails?.phoneNumber ? `${row?.userDetails?.countryCode}${row?.userDetails?.phoneNumber}` : renderTextContent("N/A"))}
                                        {renderTableCell(renderActionButtons(row))}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    ) : (
                        <div className="flex flex-col gap-10 justify-center items-center mt-[150px] ">
                            <IconComponent
                                iconLabel={ICON_LABELS.NO_DATA_FOUND}
                                color="#EFEFEF"
                                fontSize="80px"
                            /><p className="text-[18px] font-[600] text-[#888888] opacity-[0.4]">No data found</p> </div>)}
                </TableContainer>)
            }

        </Paper>
    );
}

CollaboratorTable.propTypes = {
    tableData: PropTypes.array,
    loadingData: PropTypes.bool,
    sendAction: PropTypes.func,
};

export default CollaboratorTable;