import React from 'react';
import { Select, MenuItem } from '@mui/material';
import PropTypes from "prop-types";

const limitOptions = [ 10, 25, 50, 75, 100];

const RowsSelector = ({ limit, handlePaginationLimitChange }) => {
  return (
    <Select
      labelId="pagination-limit-select-label"
      value={limit}
      onChange={handlePaginationLimitChange}
      className="w-[66px] h-11 bg-[#E6355C]"
      style={{
        color: "white",
        fontSize: "14px",
        fontWeight: "500",
        width: "fit-content",
      }}
    >
      {limitOptions.map((option) => (
        <MenuItem
          key={option}
          
          value={option}
        >
          {option}
        </MenuItem>
      ))}
    </Select>
  );
};

RowsSelector.propTypes = {
    limit: PropTypes.number,
    handlePaginationLimitChange: PropTypes.func,
  };
  

export default RowsSelector;
