import { createSlice, nanoid } from "@reduxjs/toolkit";
// recognize state and action as parameters of the reducers
const userObject = JSON.parse(localStorage.getItem("AL_ADMIN"));

const authSlice = createSlice({
	name: "auth",
	initialState: {
		user: userObject,
		loading: false,
		error: null,
		timeout: false,
	},
	reducers: {
		loginRequest: (state) => {
			state.loading = true;
			state.error = null;
		},
		loginSuccess: (state, action) => {
			let { email, token, firstName, lastName, profileImage } = action.payload;
			state.user = {
				email: email,
				token,
				firstName,
				lastName,
				profileImage: profileImage ? profileImage : null,
			};
			state.loading = false;
			state.error = null;
		},

		updateProfileData: (state, action) => {
			state.user = action.payload;
		},
		sessionTimeout: (state, action) => {
			state.user = null;
			state.timeout = action.payload;
		},
		logoutSuccess: (state) => {
			state.user = null;
			state.loading = false;
			state.error = null;
		},
	},
});

export const {
	loginRequest,
	loginSuccess,
	updateProfileData,
	sessionTimeout,
	logoutSuccess,
} = authSlice.actions;

export default authSlice.reducer; // THIS IS THE DEFAULT EXPORT!
