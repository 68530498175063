import React from "react";
import List from "@mui/material/List";
import { DRAWER_CONTENT } from "../../utils";
import { LOGO } from "../../utils/constants/assets";
import "./sidenav.css";
import SideNavItem from "./sidenavitem";
const Sidenav = () => {

    return (

        <div className="contain h-[100vh]">
            <div className="py-3  gap-12">
                <div className="px-3 pb-2.5">
                <img src={LOGO} alt="Logo" className=""/>

                </div>
                <div className="navContent flex flex-col gap-3">
                    <div className="heightOverflow flex flex-col gap-5 px-3">
                        <List className="text-white flex flex-col gap-3 w-[269px] h-auto " >
                        {DRAWER_CONTENT.map((item, index) => (
						 <SideNavItem to={item.path} label={item?.text} img={item?.icon} key={item?.text}/>
					))}
                          

                        </List>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default Sidenav;