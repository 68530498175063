import React, { useState } from "react";
import { TextInput } from "../../components/formelements/TextInput";
import Button from "../../components/formelements/Button";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import Joi from "joi";
import {  add_cat,  edit_cat } from "../../utils/api/utilAPI";

const AddCategoryModal = ({ data }) => {
  const [title, setTitle] = useState(data?.categoryName || "");
  const [errors, setErrors] = useState({});

  const schema = Joi.object({
    title: Joi.string().min(3).required().messages({
      "string.empty": "Category Name is required",
      "string.min": "Category Name must be at least 3 characters long",
    }),
  });

  const validate = () => {
    const { error } = schema.validate({ title }, { abortEarly: false });
    if (!error) return null;

    const validationErrors = {};
    error.details.forEach((detail) => {
      validationErrors[detail.path[0]] = detail.message;
    });
    return validationErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validate();
    if (validationErrors) {
      setErrors(validationErrors);
      return;
    }

    const formData = new FormData();

    formData.append("categoryName", title);
    formData.append("media", JSON.stringify([]));
    formData.append("images", JSON.stringify([]));
    try {
      if (data) {
        await edit_cat(data?._id, formData);
        toast.success("Category updated successfully" , {toastId : "category updated"});
      } else {
        formData.delete("images");
        await add_cat(formData);
        toast.success("Category added successfully" , {toastId : "category added"});
      }
      window.location.reload();
    } catch (error) {
      toast.error("Failed to submit category" , {toastId : "category failed"});
    }
  };

  return (
    <div className="p-4">
      <TextInput
        label="Category Name"
        type="text"
        value={title}
        onChange={(e) => {
          setTitle(e.target.value);
          setErrors({ ...errors, title: "" });
        }}
        error={errors.title || ""}
      />
      <div style={{ display: "flex", gap: "16px" }}>
        <Button className="btn py-2 px-4" type="submit" onClick={handleSubmit}>
          {data ? "Save" : "Add"}
        </Button>
      </div>
    </div>
  );
};

AddCategoryModal.propTypes = {
  data: PropTypes.object,
};

export default AddCategoryModal;
