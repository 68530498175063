import React from 'react'

export const ParaGraph = ({text, fontsize,fontWeight,color,margin, textTransform}) => {

    const fontStyles = {
        fontSize: fontsize ? fontsize : "24px",
        fontWeight: fontWeight ? fontWeight : "400",
        color: color ? color : "#f00",
        margin: margin ? margin : null,
        textTransform: textTransform ? textTransform : null
      };
  return (
    <>
    <p style={fontStyles}> 
    {text}
    </p>
    </>
  )
}
