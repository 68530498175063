export const STRINGS = {
	btnText: "Add Product",
	GENRAL: "General",
	PNAME: "Product Name",
	DESCRIPTION: "Description",
	PRODUCT_DESCRIPTION: "Add Product Description...",
	MEDIA: "Media",
	BASE_PRICE: "Base Price",
	PRODUCT_PRICE: "Product Price",
	TAX_CLASS: "Tax Class",
	SELECT_OPTION: "Select an Option",
	VAT_AMOUNT: "VAT Amount (%)",
	ENTER_VAT_AMOUNT: "Enter VAT Amount (%)",
	CUSTOM_DESIGN: "Custom Design",
	CATEGORY: "Category",
	SELECT_CATEGORY: "Select Category",
	ART: "Art Type",
	SELECT_ART: "Select Art Type",
	ART_IMG: "Art Image",
	SIZE_GUIDE: "Size Guide",
	FINAL_PRODUCT: "Final Product",
	UPLOADFINAL_PRODUCT: "Upload final Product",
	CSV_FILE: "Upload 1 Image",
	IMG_LIMIT: "Upload up to 10 Images",
	SIZE_CHART: "Upload size chart",
	UPLAOD_IMG: "Click to Upload",
	BTNTEXTSUBMIT: "Save",
	BTNTEXTCANCEL: "Cancel",
	SELECT_PRODUCT_TYPE: "Select Product Type",
	PRODUCT_TYPE: "Select Product Type",
	HIGHLIGHT_AREA: "Highlight Area In Image For Custom Design",
	AREA_SELECTED: "You Have Selected Area For Custom Design",
	CLICK_SELECTION_BUTTON: "Please Click On Set Selected Area Button To Confirm Your Selection"
};
