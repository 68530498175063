// Router.js

import React from "react";
import {
	RouterProvider,
	createBrowserRouter,
	Navigate,
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { userRoutes } from "./UserRoutes";
import DefaultLayout from "../components/layout/DefaultLayout";
// import { authRoutes } from "./AuthRoutes";
import LoginScreen from "../screens/Auth/LoginScreen";
import ForgotPassword from "../screens/Auth/ForgotPassword";
import ResetPassword from "../screens/Auth/ResetPassword";
import { Modal } from "@mui/material";
import { store } from "../utils/redux/store";
import { sessionTimeout } from "../utils/redux/features/authSlice";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const authRouter = createBrowserRouter([
	{
		path: "/login",
		element: <LoginScreen />,
	},
	{
		path: "/forgot_password",
		element: <ForgotPassword />,
	},
	{
		path: "/reset_password",
		element: <ResetPassword />,
	},
	{
		path: "*",
		element: <Navigate replace to="/login" />,
	},
	{
		path: "",
		element: <Navigate replace to="/login" />,
	},
]);

const mainRouter = createBrowserRouter([
	{
		path: "/",
		element: <DefaultLayout />,
		children: [
			...userRoutes.map((item, index) => ({
				path: item.path,
				element: <item.component />,
			})),
			{
				path: "*",
				element: <Navigate replace to="/dashboard" />,
			},
			{
				path: "",
				element: <Navigate replace to="/dashboard" />,
			},
		],
	},
]);
console.log("MAIN ROUTER: ", mainRouter);

// const Router = () => {
// 	return (
// 		<Routes>
// 			{authRoutes.map(({ path, component: Component }, index) => (
// 				<Route path={path} element={<Component />} key={index} exact />
// 			))}
// 			{userRoutes.map(({ path, component: Component }, id) => (
// 				<Route
// 					key={id}
// 					path={path}
// 					element={
// 						// Render DashboardLayout with the Component as its child
// 						<DashboardLayout>
// 							<Component />
// 						</DashboardLayout>
// 					}
// 				/>
// 			))}
// 		</Routes>
// 	);
// };

const Router = () => {
	const selector = useSelector((state) => state.authReducer);

	return (
		<>
			{selector?.user ? (
				<RouterProvider router={mainRouter} />
			) : (
				<RouterProvider router={authRouter} />
			)}
			{selector?.timeout && (
				<Modal
					aria-labelledby="transition-modal-title"
					aria-describedby="transition-modal-description"
					open={selector?.timeout}
					onClose={() => {
						store.dispatch(sessionTimeout(false));
					}}
					slotProps={{
						backdrop: {
							timeout: 500,
						},
					}}
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<div className="z-[3000] w-[400px] flex flex-col">
						<div className="flex py-[8px] px-[16px] justify-between bg-[#E6355C] items-center gap-x-8">
							<div className="flex flex-col">
								<h3 className="font-bold text-lg text-white">
									Session Timeout
								</h3>
							</div>
							<svg
								className="cursor-pointer"
								width="28"
								height="29"
								viewBox="0 0 28 29"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
								onClick={() => {
									store.dispatch(sessionTimeout(false));
								}}
							>
								<path
									d="M14 2.83301C7.57171 2.83301 2.33337 8.07134 2.33337 14.4997C2.33337 20.928 7.57171 26.1663 14 26.1663C20.4284 26.1663 25.6667 20.928 25.6667 14.4997C25.6667 8.07134 20.4284 2.83301 14 2.83301ZM17.92 17.183C18.2584 17.5213 18.2584 18.0813 17.92 18.4197C17.745 18.5947 17.5234 18.6763 17.3017 18.6763C17.08 18.6763 16.8584 18.5947 16.6834 18.4197L14 15.7363L11.3167 18.4197C11.1417 18.5947 10.92 18.6763 10.6984 18.6763C10.4767 18.6763 10.255 18.5947 10.08 18.4197C9.74171 18.0813 9.74171 17.5213 10.08 17.183L12.7634 14.4997L10.08 11.8163C9.74171 11.478 9.74171 10.918 10.08 10.5797C10.4184 10.2413 10.9784 10.2413 11.3167 10.5797L14 13.263L16.6834 10.5797C17.0217 10.2413 17.5817 10.2413 17.92 10.5797C18.2584 10.918 18.2584 11.478 17.92 11.8163L15.2367 14.4997L17.92 17.183Z"
									fill="white"
								/>
							</svg>
						</div>
						<div className="bg-white p-4 flex flex-col gap-y-2">
							<>
								<ErrorOutlineIcon
									sx={{
										color: "#e93359",
										alignSelf: "center",
										fontSize: "12rem",
									}}
								/>
							</>
						</div>
					</div>
				</Modal>
			)}
		</>
	);
};

export default Router;
