import axios from "axios";
import { sessionTimeout } from "../../utils/redux/features/authSlice";
import { store } from "../../utils/redux/store";

import _ from "lodash";
const fetchClient = () => {
	let {
		REACT_APP_BASE_URL,
		REACT_APP_STAGING_URL,
		REACT_APP_PREPROD_URL,
		REACT_APP_PROD_URL,
	} = process.env;

	const currURL =
		process.env.REACT_APP_TYPE === "local"
			? REACT_APP_BASE_URL
			: process.env.REACT_APP_TYPE === "staging"
			? REACT_APP_STAGING_URL
			: process.env.REACT_APP_TYPE === "preprod"
			? REACT_APP_PREPROD_URL
			: REACT_APP_PROD_URL;

	let instance = axios.create({ baseURL: currURL + "/api/" });

	instance.interceptors.request.use((config) => {
		const admin_obj = JSON.parse(localStorage.getItem("AL_ADMIN"));
		if (admin_obj?.token) {
			config.headers.authorization = admin_obj?.token;
		}
		return config;
	});

	instance.interceptors.response.use(
		(response) => {
			return response;
		},
		(error) => {
			if (error.response.status === 401) {
				localStorage.setItem("userData", null);
				store.dispatch(sessionTimeout(true));
			}
			return Promise.reject(error);
		}
	);

	return instance;
};
export default fetchClient();
