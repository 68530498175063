import React from "react";
import styles from "./form.module.css";
export const TextBox = ({
	label,
	value,
	onChange,
	error,
	marginbot,
	...props
}) => {
	return (
		<>
			<div className={marginbot ? marginbot : "mb-3"}>
				{label ? (
					<label className={`d-block mb-2 ${styles.label}`}>{label}</label>
				) : (
					""
				)}

				<textarea
					value={value}
					onChange={onChange}
					className={`form-control py-2 ${styles.textarea}`}
					{...props}
				></textarea>
				{error && <span className="text-danger">{error}</span>}
			</div>
		</>
	);
};
