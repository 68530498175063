import { Avatar, TableCell } from "@mui/material";

export const readFile = (file) => {
	return new Promise((resolve) => {
		const reader = new FileReader();
		reader.addEventListener("load", () => resolve(reader.result), false);
		reader.readAsDataURL(file);
	});
};

// To get the Nested Properties values ---
export const getNestedProperty = (obj, key) => {
	return key.split(".").reduce((o, k) => (o || {})[k], obj);
};

// To format Time ---
export const formatDate = (timestamp) => {
	const dateObject = new Date(timestamp);
	return dateObject.toLocaleDateString("en-GB", {
		day: "2-digit",
		month: "2-digit",
		year: "numeric",
	});
};

//to format time
export const formatTime = (timestamp) => {
	const dateObject = new Date(timestamp);
	return dateObject.toLocaleTimeString('en-GB', {
		hour: '2-digit',
		minute: '2-digit',
		hour12: true,
	});
};
// to format date and time
// to format date and time
export const formatDateTime = (timestamp) => {
	const dateObject = new Date(timestamp);

	const formattedDate = formatDate(timestamp);

	const formattedTime = dateObject.toLocaleTimeString("en-GB", {
		hour: "2-digit",
		minute: "2-digit",
		hour12: true,
	});

	return `${formattedDate} ${formattedTime}`;
};

export const formatTimePlayer = (time) => {
	if (time && !isNaN(time)) {
		const minutes = Math.floor(time / 60);
		const formatMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
		const seconds = Math.floor(time % 60);
		const formatSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
		return `${formatMinutes}:${formatSeconds}`;
	}
	return "00:00";
};

// table heading
export const renderTableHeading = (content, style = {}) => (
	<TableCell
		key={content}
		style={{
			fontFamily: "Poppins",
			fontSize: "14px",
			fontWeight: 500,
			color: "#060709",
			...style,
		}}
	>
		{content}
	</TableCell>
);
// render table cell content
export const renderTableCell = (content, style = {}) => (
	<TableCell
		style={{
			fontFamily: "Poppins",
			width: "400px",
			maxWidth: "400px",
			whiteSpace: "nowrap",
			overflow: "hidden",
			textOverflow: "ellipsis",

			fontSize: "16px",
			fontWeight: 400,
			color: "#858585",
			...style,
		}}
	>
		{content}
	</TableCell>
);

// render static data
export const renderTextContent = (content, style = {}) => (
	<p
		style={{
			fontFamily: "Poppins",
			width: "fit-content",
			fontStyle: "italic",
			fontSize: "14px",
			fontWeight: 400,
			color: "#858585",
			...style,
		}}
	>
		{content}
	</p>
);

// render initials
export const stringAvatar = (name) => {
	const nameParts = name.split(' ');
        const initials = nameParts.map(part => part.charAt(0).toUpperCase()).join('');
	return {
		children:initials,
	};
};
// Render action buttons
export const renderNameWithProfile = (name, image, product) => {
	let avatar;

	if (product && image) {
		avatar = (
			<Avatar
				src={image}
				sx={{ width: 46, height: 46 }}
				variant="rounded"
			/>
		);
	} else if (!product) {
		avatar = (
			<Avatar
				{...stringAvatar(name)}
				src={image}
				sx={{ width: 46, height: 46 }}
				variant="rounded"
			/>
		);
	}

	return (
		<div className="flex gap-2 items-center">
			{avatar}
			{name}
		</div>
	);
};

export const extractYouTubeVideoId = (url) => {
	// Regular expression to match YouTube video ID in various URL formats, including mobile
	const regex =
		/^(?:https?:\/\/)?(?:www\.)?(?:m\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;

	// Match the URL against the regular expression
	const match = url.match(regex);

	// Extract the video ID from the matched groups
	if (match && match[1]) {
		return match[1];
	} else {
		// Return null if no match is found
		return null;
	}
};

