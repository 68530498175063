import React from "react";
import styles from "./form.module.css";
import IconComponent from "../icons/IconComponent";
import { ICON_LABELS } from "../icons";
export const InputFile = ({
	multiple,
	label,
	type,
	value,
	onChange,
	error,
	marginbot,
	placeholder,
	subtext,
	...props
}) => {
	return (
		<div className={marginbot ? marginbot : "mb-3 position-relative"}>
			{label ? (
				<label className={`d-block mb-2 ${styles.label}`}>{label}</label>
			) : (
				""
			)}

			<input
				{...(multiple && { multiple: true })}
				type={type}
				onChange={onChange}
				{...props}
				className={`form-control py-2 ${styles.file}`}
			/>
			{error && <span className="text-danger d-block">{error}</span>}
			<div className={`${styles.customfile} gap-10`}>
				<IconComponent
					iconLabel={ICON_LABELS.UPLOAD_FILE}
					color="#E6355C"
					fontSize="48px"
				/>
				<p>
					<strong>{placeholder}</strong>
					<small className="d-block">{subtext}</small>
				</p>
			</div>
		</div>
	);
};
