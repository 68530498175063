
import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import { Table, TableBody, TableContainer, TableHead, TableRow } from "@mui/material";
import PropTypes from "prop-types";
import { Title } from "../../components/title/Title";
import { TextInput } from "../../components/formelements/TextInput";
import { renderTableCell, renderTextContent, renderTableHeading, formatDate } from "../../utils/helperFuncs";
import { ICON_LABELS } from "../../components/icons";
import IconComponent from "../../components/icons/IconComponent";
import DeletePopup from "../../components/formelements/DeletePopup";
import BlockPopup from "../../components/formelements/BlockPopup";
import { block_unblock_user, delete_user } from "../../utils/api/utilAPI";
import { toast } from "react-toastify";

const tableHeading = [
  "S. No.",
  "Name",
  "Email Address",
  "Registration Date",
  "User Type",
  "Action"
];

export default function UserManagementTable({ tableData, loadingData, filterType, sendAction, searchQuery }) {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [blockModalOpen, setBlockModalOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [userId, setUserId] = useState("");
  const [blocked, setBlocked] = useState(false);
  const handleDeleteModal = (id) => {
    setUserId(id)
    setDeleteModalOpen(true);
  }
  const handleDelete = async () => {
    try {
      await delete_user({ userId });
      setDeleteModalOpen(false);
      toast.success("User deleted successfully", {
        toastId: "User Deleted"
      });
      sendAction(true);
    } catch (error) {
      toast.error(error.response?.data?.message, {
       toastId: "Error deleting user"
      });
      setDeleteModalOpen(false);
    }

  }
  const handleBlock = async () => {
    try {
      const response = await block_unblock_user({ userId, isBlocked: !blocked });
      setBlockModalOpen(false);
      toast.success(`User ${response?.data?.blocked === "true" ? "blocked" : "unblocked"} successfully`, {
       toastId: "User Blocked"
      });
      sendAction(true);
    } catch (error) {
      setBlockModalOpen(false);
      toast.error(error.response?.data?.message, {
        toastId: "Error blocking user"
      });
    }
  }
  const handleClose = () => {
    setDeleteModalOpen(false);
    setBlockModalOpen(false);
  }
  const handleBlockModal = (id, isblock) => {
    setUserId(id);
    setBlocked(isblock)
    setBlockModalOpen(true);
  }
  const handleSearch = (e) => {
    setSearch(e?.target?.value);
    searchQuery(e?.target?.value);
  }
  const renderActionButtons = (row) => (
    <div className="flex justify-start p-auto gap-2">

      <button
        type="button"
        className={`w-[44px] h-[44px] ${row?.isBlocked ? "bg-[#FFEEE1]" : "bg-[#E4FFDD]"} rounded-[5.02px] px-[8px] py-[10px]`}
        onClick={() => handleBlockModal(row?._id, row?.isBlocked)}
      >
        {row?.isBlocked ? (
          <IconComponent
            iconLabel={ICON_LABELS.BLOCK}
            color="#FF6B00"
            fontSize="24px"
          />
        ) : (
          <IconComponent
            iconLabel={ICON_LABELS.UNBLOCK}
            color="#167D4C"
            fontSize="24px"
          />
        )}
      </button>


      <button
        type="button"
        className="w-[44px] h-[44px] bg-[#FFDDDD] rounded-[5.02px] px-[8px] py-[10px]"
        onClick={() => handleDeleteModal(row?._id)}
      >
        <IconComponent
          iconLabel={ICON_LABELS.DELETE}
          color="#E63535"
          fontSize="24px"
        />
      </button>

    </div>
  );
  return (
    <Paper
      className="randomClass1 flex-grow flex flex-col justify-start pt-3 gap-[24px] shadow-none !rounded-none border-t-[1px]"
      component={Paper}
    >
      <div className="flex justify-between items-center flex-wrap me-1" >
        <Title
          pagetitle={filterType?.label}
          fontsize="20px"
          fontWeight="600"
          color="#060709"
          border={false}
        />
        <TextInput
          type="text"
          placeholder="Search"
          style={{ background: "#E4E4E4"}}
          marginbot="0"
          value={search}
          onChange={handleSearch}
        />
      </div>
      {loadingData ? (
        ""
      ) : (
        <TableContainer className="scrollContainer w-auto overflow-auto">
          {tableData?.length !== 0 ? (
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow className="h-11 p-3">
                  {tableHeading.map((heading) => renderTableHeading(heading))}
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData?.map((row, index) => (
                  <TableRow key={row?._id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    {renderTableCell(<p>{index + 1}</p>)}
                    {renderTableCell((row?.firstName || row?.lastName) ? `${row.firstName} ${row.lastName}` : renderTextContent("Deleted User"))}                    
                    {renderTableCell(row?.email || renderTextContent("N/A"))}
                    {renderTableCell(formatDate(row?.createdAt) || renderTextContent("N/A"))}
                    {renderTableCell((row?.isCollaborator ? "Collaborator" : "User") || renderTextContent("N/A"))}
                    {renderTableCell(renderActionButtons(row))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) :  (
            <div className="flex flex-col gap-10 justify-center items-center mt-[100px] ">
                <IconComponent
                    iconLabel={ICON_LABELS.NO_DATA_FOUND}
                    color="#EFEFEF"
                    fontSize="80px"
                /><p className="text-[18px] font-[600] text-[#888888] opacity-[0.4]">No data found</p> </div>)
                }
        </TableContainer>)
      }
      <DeletePopup modal={deleteModalOpen} handleDone={handleDelete} handleClose={handleClose} />
      <BlockPopup modal={blockModalOpen} handleDone={handleBlock} handleClose={handleClose} isBlocked={blocked} />

    </Paper>
  );
}

UserManagementTable.propTypes = {
  filterType: PropTypes.object,
  tableData: PropTypes.array,
  loadingData: PropTypes.bool,
  sendAction: PropTypes.func,
  searchQuery: PropTypes.func,
};
