import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
export const ICON_LABELS = {
  GOOGLE: "google",
  MICROSOFT: "microsoft",
  CONTENT_COPY_1: "content_copy",
  TODAY: "today",
  HOUR_GLASS_BOTTOM: "hourglass_bottom",
  SCHEDULE: "schedule",
  PUBLIC: "public",
  TIMER: "timer",
  CONTENT_COPY: "content_copy",
  // DONE: "done",
  ADD: "add",
  FOLDER: "folder",
  MOVE: "drive_file_move",
  DELETEICON: "delete_outline",
  VIEW_ICON: "sort",
  ADD_WIDGET: "dashboard_customize",
  ACTION_ARROW: "keyboard_arrow_down",
  PERSON: "person",
  EVENT_NOTE: "event_note",
  SKIP_NEXT: "skip_next",
  ARROW_BACK: "arrow_back",
  VIEW_WEEK: "view_week",
  CHECK: "check",
  CLOUD_DOWNLOAD: "cloud_download",
  MAIL_LOCK: "mail_lock",
  LINK: "link",
  PUSH_PIN: "push_pin",
  DELETE: "delete",
  PAUSE: "pause",
  PLAY_ARROW: "play_arrow",
  DELETE_OUTLINE: "delete_outline",
  INSERT_DRIVE_FILE: "insert_drive_file",
  ALL: "article",
  CALL: "call",
  EMAIL: "email",
  INSERT_LINK: "insert_link",
  RADIO_BUTTON_UNCHECKED: "radio_button_unchecked",
  NAVIGATE_NEXT: "navigate_next",
  HELP_OUTLINE: "help_outline",
  NOTIFICATIONS_NONE: "notifications_none",
  SETTINGS: "settings",
  CURRENCY_EXCHANGE: "currency_exchange",
  LOGOUT: "logout",
  LOGOUTS: "logout",
  ACCESS_TIME: "access_time",
  VISIBILITY_OFF: "visibility_off",
  VISIBILITY_ON: "visibility_on",
  BOOKMARK: "bookmark",
  SAVE: "save",
  CONTACT_PAGE: "contact_page",
  FILTER_ALT: "filter_alt",
  CLOSE_BUTTON: "close_button",
  DOWNLOAD: "download",
  ADD_CIRCLE_OUTLINE: "add_circle_outline",
  DRIVE_FILE_MOVE: "drive_file_move",
  SORT: "sort",
  DASHBOARD_CUSTOMIZE: "dashboard_customize",
  KEYBOARD_ARROW_DOWN: "keyboard_arrow_down",
  KEYBOARD_ARROW_UP: "keyboard_arrow_up",
  KEYBOARD_ARROW_RIGHT: "keyboard_arrow_right",
  KEYBOARD_BACKSPACE: "keyboard_backspace",
  EDIT: "edit",
  FILE_UPLOAD: "file_upload",
  FACT_CHECK: "fact_check",
  TASK: "task",
  GRID_VIEW: "grid_view",
  DESCRIPTION: "description",
  DOUBLE_ARROW: "double_arrow",
  LIST_ALT: "list_alt",
  APARTMENT: "apartment",
  REFRESH: "refresh",
  MANAGE_SEARCH: "manage_search",
  CAMPAIGN: "campaign",
  TRENDING_UP: "trending_up",
  UPLOAD_FILE: "upload_file",
  VIDEO_CHAT_ROUDED: "video_chat",
  EXTENSION: "extension",
  TUNE: "tune",
  MORE_VERT: "more_vert",
  REMOVE_CIRCLE: "remove_circle",
  FIBER_MANUAL_RECORD: "fiber_manual_record",
  CREATE_NEW_FOLDER: "create_new_folder",
  CLEAR_ALL: "clear_all",
  BUG_REPORT: "bug_report",
  CLOSE: "close",
  BUILD_CIRCLE: "build_circle",
  VIEW_COLUMN: "view_column",
  PLAYLIST_ADD: "playlist_add",
  POST_ADD: "post_add",
  DOWNLOAD_FOR_ONLINE: "download_for_offline",
  RESTART_ALT: "restart_alt",
  ARROW_UP: "arrow_upward",
  ARROW_DOWN: "arrow_downward",
  CANCEL: "cancel_icon",
  DONE: "done",
  STICKY_NOTE: "sticky_note_2",
  REPLY: "reply",
  LINKEDIN: "linked_in",
  LOCK_PERSON: "lock_person",
  FACEBOOK: "facebook",
  TWITTER: "twitter",
  INFO: "info_outlined",
  DISABLEDBYDEFAULT: "disabled_by_default",
  GROUPS: "groups",
  WORK: "work",
  KEY: "key",
  MONETIZATION_ON: "monetization_on",
  LOCATION_ON: "location_on",
  LANGUAGE: "language",
  DNS: "dns",
  DRAG_INDICATOR: "drag_indicator",
  ARROW_BACK_IOS: "arrow_back_ios",
  ARROW_FORWARD: "arrow_forward",
  ERROR: "report_gmailerrorred",
  SHIELD: "shield",
  FILE_COPY: "file_copy",
  VIEW_TIMELINE: "view_timeline",
  DATE_RANGE: "date_range",
  SHARE: "share",
  CACHED: "cached",
  VIDEO_CALL: "video_call",
  LINKOFF: "link_off",
  EXPAND_MORE: "expand_more",
  EXPAND_LESS: "expand_less",
  FLAG_ICON: "flag",
  SHOP: "shopping_bag",
  HANDSHAKE: "handshake",
  PLAY_BTN: "play_circle",
  PACKET: "deployed_code_update",
  GROUP: "group",
  MUSIC_NOTE: "music_note",
  BAR_CHART: "bar_chart",
  CHECK_CIRCLE: "check_circle",
  BLOCK: "no_accounts",
  UNBLOCK: "account_circle_outline",
  NO_DATA_FOUND: "backup_table",
  PROMO_CODE: "confirmation_number",
  ORDER: "widgets",
  MY_PROFILE: "window",
};



export {
  InstagramIcon,
  FacebookRoundedIcon
}