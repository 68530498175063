
import React from "react";
import Paper from "@mui/material/Paper";
import { Table, TableBody, TableContainer, TableHead, TableRow } from "@mui/material";
import PropTypes from "prop-types";
import { Title } from "../../components/title/Title";
import { renderTableCell, renderTableHeading, renderTextContent, formatDate, formatTime } from "../../utils/helperFuncs";
import { ICON_LABELS } from "../../components/icons";
import IconComponent from "../../components/icons/IconComponent";
import ViewButton from "../../components/formelements/ViewButton";
import { respond_to_event } from "../../utils/api/utilAPI";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { VIEW_EVENT_PATH } from "../../utils/constants/routes";

const tableHeading = [
    "S. No.",
    "Event Title",
    "Event Date",
    "Event Time",
    "Location",
    "Description",
    "Action"
];

export default function EventManagementTable({ tableData, loadingData, filterType, sendAction }) {

    const navigate = useNavigate();
    const handleEventRequest = async (id, status) => {
        try {
            const response = await respond_to_event({ eventId: id, isAccepted: status });
            toast.success(response?.data?.message, {
                toastId: " responding to event request"
            })
            sendAction(true);
        } catch (error) {
            toast.error(error?.response?.data?.message, {
                toastId: "Error responding to event request"
            })
        }
    }
    const renderActionButtons = (row) => (

        <div className="flex justify-start items-center p-auto gap-2">
            <ViewButton
                variant={true}
                type="button"
                icon={ICON_LABELS.VISIBILITY_ON}
                onClick={() => navigate(VIEW_EVENT_PATH, { state: row })}
            >
                View
            </ViewButton>

            {filterType?.value === "eventRequest" && <button
                type="button"
                className={`w-[44px] h-[44px] bg-[#E4FFDD] rounded-[5.02px] px-[8px] py-[10px]`}
                onClick={() => handleEventRequest(row?._id, true)}
            >
                <IconComponent
                    iconLabel={ICON_LABELS.CHECK_CIRCLE}
                    color="#167D4C"
                    fontSize="24px"
                />
            </button>}
            {filterType?.value === "eventRequest" && <button
                type="button"
                className="w-[44px] h-[44px] bg-[#FFDDDD] rounded-[5.02px] px-[8px] py-[10px]"
                onClick={() => handleEventRequest(row?._id, false)}
            >
                <IconComponent
                    iconLabel={ICON_LABELS.CANCEL}
                    color="#E63535"
                    fontSize="24px"
                />
            </button>}

        </div>
    );




    return (
        <Paper
            className="randomClass1 flex-grow flex flex-col justify-start pt-3 gap-[24px] shadow-none !rounded-none border-t-[1px]"
            component={Paper}
        >
            <div className="flex justify-between items-center flex-wrap">
                <Title
                    pagetitle={filterType?.label}
                    fontsize="20px"
                    fontWeight="600"
                    color="#060709"
                    border={false}
                />
            </div>
            {loadingData ? (
                ""
            ) : (
                <TableContainer className="scrollContainer w-auto overflow-auto">
                    {tableData.length !== 0 ? (
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow className="h-11 p-3 whitespace-nowrap">
                                    {tableHeading.map((heading) => renderTableHeading(heading))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tableData?.map((row, index) => (
                                    <TableRow key={row?._id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                        {renderTableCell(<p>{index + 1}</p>)}
                                        {renderTableCell(row?.eventName)}
                                        {renderTableCell(formatDate(row?.eventStartDate) || renderTextContent("N/A"))}
                                        {renderTableCell(row?.eventStartDate ? `${formatTime(row?.eventStartDate)} - ${formatTime(row?.eventEndDate)}` : renderTextContent("N/A"))}
                                        {renderTableCell(row?.eventAddress || renderTextContent("Deleted User"))}
                                        {renderTableCell(row?.eventDetails || renderTextContent("N/A"))}
                                        {renderTableCell(renderActionButtons(row))}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    ) : (
                        <div className="flex flex-col gap-10 justify-center items-center mt-[150px] ">
                            <IconComponent
                                iconLabel={ICON_LABELS.NO_DATA_FOUND}
                                color="#EFEFEF"
                                fontSize="80px"
                            /><p className="text-[18px] font-[600] text-[#888888] opacity-[0.4]">No data found</p> </div>)
                    }
                </TableContainer>)
            }

        </Paper>
    );
}

EventManagementTable.propTypes = {
    filterType: PropTypes.object,
    tableData: PropTypes.array,
    loadingData: PropTypes.bool,
    sendAction: PropTypes.func,
};
