export const STRINGS = {
    PAGE_TITLE: "Event Management",
    REJECT: "Delete",
    APPROVE: "Edit",
    VIEW_TITLE: "View Event",
    EVENT_NAME: "Event Name",
    EVENT_ADDRESS: "Event Address",
    EVENT_DATE: "Event Date & Time",
    EDIT_EVENT: "Edit Event",
    ADD_EVENT: "Add Event",
    EVENT_COMPLETE_ADDRESS: "Event Complete Address",
    MEDIA: "Media",
    UPLAOD_IMG: "Drop file here or click to Upload",
    IMG_LIMIT: "Upload an Image for the Event",
    BTNTEXTCANCEL: "Cancel",
    BTNTEXTSUBMIT: "Add",
    ENTER_ARTIST_NAME: "Enter Artist Name",
    ARTIST_NAME: "Artist Name",
    EVENT_DETAILS: "Event Details",
    ENTER_EVENT_DETAILS: "Enter Event Details",

}