import React from "react";
import { TRASH } from "../../utils/constants/assets";
import { Modal, Fade } from "@mui/material";
import Button from "../../components/formelements/Button";
import styles from "./form.module.css";
import PropTypes from "prop-types";


const DeletePopup = ({ modal, handleDone, handleClose }) => {
  return (
    <Modal
      open={modal}
      onClose={handleClose}
      closeAfterTransition
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Fade in={modal}>
        <div className={`bg-white ${styles.popup}`}>
          <img alt="" src={TRASH} style={{ width: "3.4rem" }} />
          <div style={{ textAlign: "center" }}>
            <span style={{ fontSize: "1.125rem", fontWeight: "600" }}>
              Are you sure you want to delete?
            </span>
            <br />
            <span>
              Are you sure you want to delete this? <br />
              This action cannot be undone.
            </span>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button
              className={`btn py-2 px-4`}
              type="button"
              disabled={false}
              onClick={handleDone}
            >
              Yes, Delete!
            </Button>
            <Button
              className={`btn py-2 px-4`}
              type="button"
              disabled={false}
              variant
              onClick={handleClose}
            >
              No, Cancel
            </Button>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

DeletePopup.propTypes = {
  modal: PropTypes.bool,
  handleDone: PropTypes.func,
  handleClose: PropTypes.func,
};

export default DeletePopup;
