import React from 'react'
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import PropTypes from "prop-types";

const CustomPhoneInput = ({ value, onChange, disabled, error, isError }) => {
    return (
        <div>
            <PhoneInput
                inputClass="!w-full !h-[42px]"
                country={'us'}
                onlyCountries={['us']}
                countryCodeEditable={false}
                disabled={disabled}
                value={JSON.stringify(value)}
                onChange={onChange}
            />
            {error && (
                <span className={isError !== false && "text-danger"}>{error}</span>
            )}
        </div>
    )
}
CustomPhoneInput.propTypes = {
    value: PropTypes.number,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    error: PropTypes.string,
    isError: PropTypes.bool,
};

export default CustomPhoneInput 