import React, { useEffect, useState } from "react";
import { Modal, Fade, TextField, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import Button from "../../../components/formelements/Button";
import PropTypes from "prop-types";
import { change_current_password } from "../../../utils/api/utilAPI";
import { toast } from "react-toastify";
import { ParaGraph } from "../../../components/title/ParaGraph";
import styles from "./adminprofile.module.css";
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Joi from 'joi';
import IconComponent from "../../../components/icons/IconComponent";
import { ICON_LABELS } from "../../../components/icons";

const ResetPasswordPopup = ({ modal, handleClose }) => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [passwordVisibility, setPasswordVisibility] = useState({
    oldPassword: false,
    newPassword: false,
    confirmNewPassword: false
  });
  const [errors, setErrors] = useState({});

  const handlePasswordVisibilityToggle = (fieldName) => {
    setPasswordVisibility(prevState => ({
      ...prevState,
      [fieldName]: !prevState[fieldName]
    }));
  };

  const validate = () => {
    const schema = Joi.object({
      oldPassword: Joi.string().min(6).required().label('Current Password'),
      newPassword: Joi.string().min(6).required().label('New Password'),
      confirmNewPassword: Joi.string().valid(Joi.ref('newPassword')).required().label('Confirm New Password')
        .messages({ 'any.only': '{{#label}} does not match' })
    });

    const { error } = schema.validate({
      oldPassword,
      newPassword,
      confirmNewPassword
    }, { abortEarly: false });

    if (!error) return null;

    const errors = {};
    error.details.forEach(detail => {
      errors[detail.path[0]] = detail.message;
    });
    return errors;
  };

  const handleSave = async () => {
    const errors = validate();
    setErrors(errors || {});
    if (errors) return;

    const data = {
      oldPassword,
      newPassword,
    };

    try {
      await change_current_password(data);
      toast.success("Password changed successfully");
      handleClose();
    } catch (error) {
      toast.error(error.response?.data?.message.message || "Password change failed");
    }
  };

  useEffect(() => {
    setOldPassword("")
    setNewPassword("")
    setConfirmNewPassword("")
    setErrors("")
    setPasswordVisibility({
      oldPassword: false,
      newPassword: false,
      confirmNewPassword: false
    })
  }, [modal])

  return (
    <Modal
      open={modal}
      onClose={handleClose}
      closeAfterTransition
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Fade in={modal}>
        <div className="bg-white w-[550px] shadow-lg">
          <div className="bg-[#E6355C] px-4 py-2 text-white flex justify-between items-center">
            <div>
              <ParaGraph
                text="Change Password"
                fontsize="18px"
                fontWeight="600"
                color="#FFFFFF"
              />
              <ParaGraph
                text="Here you can change your password"
                fontsize="14px"
                fontWeight="400"
                color="#FFFFFF"
              />
            </div>
            <IconComponent
              iconLabel={ICON_LABELS.CANCEL}
              color="#ffffff"
              fontSize="24px"
              onClick={handleClose}
              className="cursor-pointer"
            />
          </div>
          <div className="flex flex-col gap-4 px-4 py-3">
            <div>
              <label className="mb-2 text-black font-medium">Current Password</label>
              <TextField
                type={passwordVisibility.oldPassword ? 'text' : 'password'}
                variant="outlined"
                fullWidth
                placeholder="Enter current password"
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
                error={!!errors.oldPassword}
                helperText={errors.oldPassword}
                className={styles.customTextField}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => handlePasswordVisibilityToggle("oldPassword")}
                        edge="end"
                      >
                        {passwordVisibility.oldPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div>
              <label className="block mb-2 font-medium">New Password</label>
              <TextField
                type={passwordVisibility.newPassword ? 'text' : 'password'}
                variant="outlined"
                fullWidth
                placeholder="Enter new password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                error={!!errors.newPassword}
                helperText={errors.newPassword}
                className={styles.customTextField}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => handlePasswordVisibilityToggle("newPassword")}
                        edge="end"
                      >
                        {passwordVisibility.newPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div>
              <label className="mb-2 text-black font-medium">Confirm New Password</label>
              <TextField
                type={passwordVisibility.confirmNewPassword ? 'text' : 'password'}
                variant="outlined"
                fullWidth
                placeholder="Confirm new password"
                value={confirmNewPassword}
                onChange={(e) => setConfirmNewPassword(e.target.value)}
                error={!!errors.confirmNewPassword}
                helperText={errors.confirmNewPassword}
                className={styles.customTextField}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => handlePasswordVisibilityToggle("confirmNewPassword")}
                        edge="end"
                      >
                        {passwordVisibility.confirmNewPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>
          <div className="flex justify-between p-4">
            <Button
              className="py-2 px-4"
              type="button"
              disabled={false}
              onClick={handleSave}
            >
              Save
            </Button>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

ResetPasswordPopup.propTypes = {
  modal: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default ResetPasswordPopup;
