import { Paper } from "@mui/material";
import React from "react";
import { Title } from "../../components/title/Title";
import ProductTable from "./ProductTable";
import ArtTypeTable from "../merchandise/ArtType";
import CategoryTable from "../merchandise/CategoryTable";

export const Merchandise = () => {
  return (
    <div className="scrollPage flex flex-col !h-full  justify-start gap-6 ">
      <Paper
        className="paperClass "
        component={Paper}
      >
        <Title
          pagetitle="Merchandise"
          fontsize="20px"
          fontWeight="600"
          color="#060709"
        />
        <ProductTable />
        <hr className="m-3 p-0 h-1" />
        <ArtTypeTable />
        <hr className="m-3 p-0 h-1" />
        <CategoryTable />
      </Paper>

    </div>);
};
