import {
  CONFIRM_PASSWORD_LENGTH,
  MESSAGE_CONFIRMED_INVALID_PASSWORD_LENGTH,
  MESSAGE_INVALID_EMAIL_LENGTH,
  MESSAGE_INVALID_EMAIL_PATTERN,
  MESSAGE_INVALID_NAME_LENGTH,
  MESSAGE_INVALID_NEW_PASSWORD_LENGTH,
  MESSAGE_INVALID_PASSWORD,
  MESSAGE_INVALID_PASSWORD_LENGTH,
  MESSAGE_PASSWORD_CONFIRMPASSWORD_MISMATCH,
  MESSAGE_PASSWORD_NEW_PASSWORD_MISMATCH,
  NEW_PASSWORD_LENGTH,
} from "../constants/messages";

export const emailValidation = (email = "") => {
  if (!email.length) {
    return { isValid: false, message: MESSAGE_INVALID_EMAIL_LENGTH };
  }
  let filter =
    /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  return filter.test(email)
    ? { isValid: true, message: "" }
    : { isValid: false, message: MESSAGE_INVALID_EMAIL_PATTERN };
};



export const passwordValidation = (password) => {
  if (!password.length) {
    return { isValid: false, message: MESSAGE_INVALID_PASSWORD_LENGTH };
  }

  if (password?.length >= 8) {
    return { isValid: true, message: "" };
  } else {
    return { isValid: false, message: MESSAGE_INVALID_PASSWORD };
  }
};

//Login SCreen password Validation

export const loginPasswordValidation = (password) => {
  if (!password.length) {
    return { isValid: false, message: MESSAGE_INVALID_PASSWORD_LENGTH };
  } else {
    return { isValid: true, message: "" };
  }
};

export const confPasswordValidation = (pass, confPass) => {
  if (!confPass.length) {
    return { isValid: false, message: CONFIRM_PASSWORD_LENGTH };
  }
  if (pass === confPass) {
    return {
      isValid: true,
      message: "",
    };
  } else {
    return {
      isValid: false,
      message: MESSAGE_PASSWORD_CONFIRMPASSWORD_MISMATCH,
    };
  }
};
export const confirmPasswordvalidation = (pass, confPass) => {
  if (!confPass.length) {
    return {
      isValid: false,
      message: MESSAGE_CONFIRMED_INVALID_PASSWORD_LENGTH,
    };
  }

  if (confPass === pass) {
    if (confPass?.length >= 8) {
      return { isValid: true, message: "" };
    } else {
      return { isValid: false, message: "" };
    }
  } else {
    return {
      isValid: false,
      message: MESSAGE_PASSWORD_CONFIRMPASSWORD_MISMATCH,
    };
  }
};

export const newPasswordvalidation = (pass, confPass) => {
  if (!confPass.length) {
    return { isValid: false, message: NEW_PASSWORD_LENGTH };
  }
  if (confPass !== pass) {
    if (confPass?.length >= 8) {
      return { isValid: true, message: "" };
    } else {
      return { isValid: false, message: MESSAGE_INVALID_NEW_PASSWORD_LENGTH };
    }
  } else {
    return {
      isValid: false,
      message: MESSAGE_PASSWORD_NEW_PASSWORD_MISMATCH,
    };
  }
};

export const nameValidation = (name) => {
  return name?.length
    ? { isValid: true, message: "" }
    : { isValid: false, message: MESSAGE_INVALID_NAME_LENGTH };
};

