import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import PropTypes from "prop-types";
import {
  renderTableCell,
  renderTextContent,
  renderTableHeading,
} from "../../utils/helperFuncs";
import { ICON_LABELS } from "../../components/icons";
import IconComponent from "../../components/icons/IconComponent";
import DeletePopup from "../../components/formelements/DeletePopup";
import { deleteDoc, doc } from "firebase/firestore";
import { db } from "./firebase";
import { toast } from "react-toastify";

const tableHeading = [
  "S. No.",
  "Channel Name",
  "Type",
  "Phone Number",
  "Actions",
];

const CommunityTable = ({ tableData, loadingData }) => {
  const [communityDeleteModal, setCommunityDeleteModal] = useState(false);
  const [communityId, setCommunityId] = useState(null);

  const handleDeleteModal = (channel) => {
    setCommunityDeleteModal(true);
    setCommunityId(channel);
  };

  const handleDeleteCommunity = async () => {
    try {
      await deleteDoc(doc(db, "channels", communityId));
    } catch (error) {
      toast.error(error?.message);
    } finally {
      setCommunityDeleteModal(false);
      setCommunityId(null);
    }
  };

  const renderActionButtons = (row) => {
    return (
      <button
        type="button"
        className="w-[44px] h-[44px] bg-[#FFDDDD] rounded-[5.02px] px-[8px] py-[10px]"
        onClick={() => handleDeleteModal(row?.id)}
      >
        <IconComponent
          iconLabel={ICON_LABELS.DELETE}
          color="#E63535"
          fontSize="24px"
        />
      </button>
    );
  };

  return (
    <Paper
      className="randomClass1 flex-grow flex flex-col justify-start pt-3 gap-[24px] shadow-none !rounded-none border-t-[1px]"
      component={Paper}
    >
      {loadingData ? (
        ""
      ) : (
        <TableContainer className="scrollContainer w-auto overflow-auto">
          {tableData.length !== 0 ? (
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow className="h-11 p-3">
                  {tableHeading?.map((heading) => renderTableHeading(heading))}
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData?.map((row, index) => (
                  <TableRow
                    key={row?._id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    {renderTableCell(<p>{index + 1}</p>)}
                    {renderTableCell(renderTextContent(row?.title))}
                    {renderTableCell(
                      renderTextContent(row?.isPublic ? "Public" : "Private")
                    )}
                    {renderTableCell(
                      row?.userDetails?.phoneNumber || renderTextContent("N/A")
                    )}
                    {renderTableCell(renderActionButtons(row))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <div className="flex flex-col gap-10 justify-center items-center mt-[150px] ">
              <IconComponent
                iconLabel={ICON_LABELS.NO_DATA_FOUND}
                color="#EFEFEF"
                fontSize="80px"
              />
              <p className="text-[18px] font-[600] text-[#888888] opacity-[0.4]">
                No data found
              </p>{" "}
            </div>
          )}
        </TableContainer>
      )}
      <DeletePopup
        modal={communityDeleteModal}
        handleClose={() => {
          setCommunityDeleteModal(false);
          setCommunityId(null);
        }}
        handleDone={handleDeleteCommunity}
      />
    </Paper>
  );
};

CommunityTable.propTypes = {
  tableData: PropTypes.array,
  loadingData: PropTypes.bool,
};

export default CommunityTable;
