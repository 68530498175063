import { React, useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import styles from "./form.module.css";

export default function SelectBox({
	label,
	list,
	placeholder,
	value,
	setValue,
	searchAPI,
	background,
	error,
	...props
}) {
	const handleChange = (event) => {
		// if value is not '', and an inherited searchAPI is provided, use it!
		setValue(event.target.value);
		if (searchAPI) searchAPI(event.target.value);
	};

	console.log("CATEGORY LIST: ", list, "current category", value);

	return (
		<div>
			{label ? (
				<label className={`d-block mb-2 ${styles.label}`}>{label}</label>
			) : (
				""
			)}
			<FormControl sx={{ ...props }}>
				<Select
					value={value}
					onChange={handleChange}
					displayEmpty
					sx={{
						"& .MuiSelect-select": {
							paddingRight: 4,
							paddingLeft: 2,
							paddingTop: 1.25,
							paddingBottom: 1,
							background: background,
							border: "none",
							width: "100%",
							color: "#858585",
							fontSize: "14px",
							fontFamily: "'Poppins', sans-serif",
						},
					}}
				>
					<MenuItem value="">{placeholder}</MenuItem>
					{list?.map((item) => {
						return <MenuItem value={item.id}>{item.name}</MenuItem>;
					})}
				</Select>
			</FormControl>
			{error && <span className="text-danger">{error}</span>}
		</div>
	);
}
