import React, { useState } from "react";
import styles from "./Login.module.css";
import { STRINGS } from "./strings";
import { TextInput } from "../../components/formelements/TextInput";
import Button from "../../components/formelements/Button";
import {
	confirmPasswordvalidation,
	emailValidation,
	passwordValidation,
} from "../../utils/validations/inputValidations";
import { resetPassword } from "../../utils/api/authAPI";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

const ResetPassword = () => {
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();

	const [password, setPassword] = useState("");
	const [confirmpassword, setConfirmpassword] = useState("");

	const [validations, setValidations] = useState({
		password: "",
		confirmpassword: "",
	});

	const handleSubmit = (e) => {
		e.preventDefault();

		const validatedPassword = passwordValidation(password);
		const validatedConfirmPassword = passwordValidation(confirmpassword);

		if (password !== confirmpassword) {
			// toast.error("Please ensure both passwords match!", { theme: "dark" });
			setValidations({
				...validations,
				confirmpassword: "New and confirm password should be same",
			});
			return;
		}

		if (validatedPassword.isValid && validatedConfirmPassword.isValid) {
			// setValidations({
			// 	password,
			// 	confirmpassword,
			// });

			const data = {
				newPassword: password,
				confirmPassword: confirmpassword,
				otp: searchParams.get("otp"),
				_id: searchParams.get("id"),
			};

			resetPassword(data)
				.then((res) => {
					console.log(res);
					navigate("/");
				})
				.catch((err) => {
					console.log(err);
					toast.error(err.response.data.message || "Something went wrong, please try again.")
					setPassword("");
					setConfirmpassword("");

				});
		} else {
			setValidations({
				password: validatedPassword.message,
				confirmpassword: validatedConfirmPassword.message,
			});
		}
	};

	return (
		<>
			<div className={`col-md-5 mx-auto ${styles.forgotcard}`}>
				<div className={`${styles.bg} p-4`}>
					<div>
						<h1 className={styles.title}>{STRINGS.forgotpassword}</h1>
						<p>{STRINGS.forgotpasstext}</p>
						<TextInput
							type="password"
							value={password}
							label={STRINGS.newpassword}
							placeholder={STRINGS.passwordplaceholder}
							onChange={(e) => setPassword(e.target.value)}
							error={validations.password}
						/>
						<TextInput
							type="password"
							value={confirmpassword}
							label={STRINGS.confirmpassword}
							placeholder={STRINGS.passwordplaceholder}
							onChange={(e) => setConfirmpassword(e.target.value)}
							error={validations.confirmpassword}
						/>
						<Button
							onClick={handleSubmit}
							className={`btn w-100 ${styles.btnbg}`}
							type="button"
							disabled={false}
						>
							{STRINGS.reset}
						</Button>
					</div>
				</div>
			</div>
		</>
	);
};

export default ResetPassword;
