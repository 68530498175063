import React, { useEffect, useState } from "react";
import { STRINGS } from "./strings";
import styles from "./dashboard.module.css";
import { Title } from "../../components/title/Title";
import { useDispatch, useSelector } from 'react-redux';
import { loadingActions } from '../../utils/redux/features/loadingSlice';
import { toast } from "react-toastify";
import { get_dashboard_data } from "../../utils/api/utilAPI";
import DashboardTable from "./DashboardTable";
import { Paper } from "@mui/material";

export const Dashboard = () => {

	const [cardsData, setCardsData] = useState({});
	const [tableData, setTableData] = useState([]);


	const loading = useSelector((state) => state?.loadingReducer?.loading);
	const dispatch = useDispatch();


	const fetchDashboardData = async () => {
		dispatch(loadingActions.loadingState(true));
		try {
			const response = await get_dashboard_data();
			setCardsData(response?.data?.resultValue);
			setTableData(response?.data?.resultValue?.orderList);

		} catch (error) {
			toast.error(error.response?.data?.message, {
				toastId: "fetching dasboard data failed",
			});
		} finally {
			dispatch(loadingActions.loadingState(false));
		}
	};

	useEffect(() => {
		fetchDashboardData();
	}, []);

	const cardData = [
		{ title: "Total Registered Users", value: cardsData?.totalUser | "N/A" },
		{ title: "Total Collaborations", value: cardsData?.totalCollab | "N/A" },
		{ title: "Total Music", value: cardsData?.totalMusic | "N/A" },
		{ title: "Total Events", value: cardsData?.totalEvents | "N/A" },
		{ title: "Total Products Purchased", value: cardsData?.totalProductPurchased | "N/A" },
		{ title: "Total Revenue", value: cardsData?.totalRevenue | "N/A" }
	];

	const renderCards = () => {
		return cardData.map((card, index) => (
			<div key={index}>
				<div className={`card flex-row align-items-center px-4 py-5 border-0 ${styles.cards}`}>
					<div className="col-md-6">
						<h3 className={styles.cardtitle}>{card.title}</h3>
					</div>
					<div className="col-md-6 text-end">
						<span className={styles.stats}>{card.value}</span>
					</div>
				</div>
			</div>
		));
	};

	const Success = <div className={`${styles.success}`}>Success</div>;
	const Failed = <div className={`${styles.fail}`}>Failed</div>;

	return (
		<div className="flex flex-col h-full  justify-start gap-6  scrollPage">
			{loading ? (
				""
			) : (
				<div >
					<div className={styles.dashboardCard}>
						{renderCards()}
					</div>

					<div className={`bg-white p-4 pb-0`}>
						<Title
							pagetitle={STRINGS.DASHBOARD}
							fontsize="20px"
							fontWeight="600"
							color="#060709"
							paddingBottom="1rem"
						/>
						<DashboardTable tableData={tableData} />
					</div>
				</div>
			)}


		</div>
	);
};
