import React, { useEffect, useState } from 'react'
import { Title } from '../../components/title/Title'
import { Paper } from '@mui/material';
import RowsSelector from '../../components/pagination/RowSelector';
import PageSelector from '../../components/pagination/PageSelector';
import { loadingActions } from '../../utils/redux/features/loadingSlice';
import { useDispatch, useSelector } from 'react-redux';
import DynamicTabs from '../../components/tabs/DynamicTabs';
import { toast } from 'react-toastify';
import EventManagementTable from './eventManagementTable';
import { get_accepted_event_list, get_requested_event_list } from '../../utils/api/utilAPI';
import Button from '../../components/formelements/Button';
import { useNavigate } from 'react-router-dom';
import { ADD_EVENT_PATH } from '../../utils/constants/routes';
const tabLabels = [
  {
    label: "All Events", value: "all"
  },
  {
    label: "Event Requests", value: "eventRequest"
  },
];
export const Events = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState([]);
  const [selectedTab, setSelectedTab] = useState(tabLabels[0]);
  const loading = useSelector((state) => state?.state?.loadingReducer?.loading);
  const [action, setAction] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const handleTabSelect = (activeTab) => {
    setSelectedTab(activeTab);
  };
  const handlePaginationLimitChange = (event) => {
    setPage(1);
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };
  const fetchEventRequest = async () => {
    dispatch(loadingActions.loadingState(true));
    try {
      const response = await get_requested_event_list(page, limit);
      setTableData(response?.data?.data);
      setTotalCount(response?.data?.totalCount);
    } catch (error) {
      toast.error(error?.response?.data?.message, {
        toastId: "Error fetching event request list"
      });
    } finally {
      dispatch(loadingActions.loadingState(false));
    }
  };
  const fetchEventList = async () => {
    dispatch(loadingActions.loadingState(true));
    try {
      const response = await get_accepted_event_list(page, limit);
      setTableData(response?.data?.data);
      setTotalCount(response?.data?.totalCount);
    } catch (error) {
      toast.error(error?.response?.data?.message, {
        toastId: "Error fetching event  list"
      });
    } finally {
      dispatch(loadingActions.loadingState(false));
    }
  };

  useEffect(() => {
    setTableData([]);
    if (selectedTab?.value === "all") {
      fetchEventList();
    }
    if (selectedTab?.value === "eventRequest") {
      fetchEventRequest();
    }
  }, [page, limit, selectedTab]);
  useEffect(() => {
    if (selectedTab?.value === "all" && action) {
      fetchEventList();
    }
    if (selectedTab?.value === "eventRequest" && action) {
      fetchEventRequest();
    }
    setAction(false)
  }, [action]);
  return (
    <div className="flex flex-col h-full  justify-start gap-6 ">
      <Paper
        className="paperClass"
        component={Paper}
      >
        <div className='flex justify-between items-center'>
          <Title
            pagetitle={"Event Management"}
            fontsize="20px"
            fontWeight="600"
            color="#060709"
            border={false}
          />
          <Button className="btn" type={"button"} onClick={() => navigate(ADD_EVENT_PATH)}>
            Add Event
          </Button>
        </div>

        <DynamicTabs tabLabels={tabLabels} onTabSelect={handleTabSelect} />
        <EventManagementTable tableData={tableData} loadingData={loading} filterType={selectedTab} sendAction={(e) => setAction(e)} />
        <div className="flex justify-between mt-auto items-center">
          <RowsSelector limit={limit} handlePaginationLimitChange={handlePaginationLimitChange} />
          <PageSelector totalCount={totalCount} limit={limit} page={page} handlePageChange={handlePageChange} />
        </div>
      </Paper>
    </div>
  )
}
