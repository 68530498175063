import React from "react";
import { Modal, Fade } from "@mui/material";
import { ICON_LABELS } from "../icons";
import IconComponent from "../icons/IconComponent";
import PropTypes from "prop-types";

const GenericModal = ({ modal, content, handleClose, title, height, width, isCross, description }) => {
    return (
        <Modal
            open={modal}
            onClose={handleClose}
            closeAfterTransition
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Fade in={modal}>
                <div
                    className={`font-semibold flex flex-col gap-3 absolute bg-white shadow-lg top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2`}
                    style={{ width: width, height: height }}
                >
                    {isCross && (
                        <div className={`flex flex-row justify-between ${title && "items-center"} bg-[#E6355C] px-4 py-2`}>
                            <div className="flex flex-col ">
                                <p className="font-bold text-lg leading-7 text-left text-white" style={{ fontFamily: "inherit" }}>{title}</p>
                                <p className="font-normal text-sm leading-5 text-left text-white">{description}</p>
                            </div>
                            {!title && content}
                            <button type="button" onClick={handleClose} className="cursor-pointer">
                                <IconComponent
                                    iconLabel={ICON_LABELS.CANCEL}
                                    color="white"
                                    fontSize="24px"
                                />
                            </button>
                        </div>
                    )}
                    {title && content}
                    {!isCross && content}
                </div>
            </Fade>
        </Modal>
    );
};

GenericModal.propTypes = {
    modal: PropTypes.bool,
    content: PropTypes.node,
    handleClose: PropTypes.func,
    title: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
    isCross: PropTypes.bool,
    description: PropTypes.string,
};

export default GenericModal;
