import React from 'react';
import Lottie from "lottie-react";
import spinningLoader from "../../utils/constants/loaderFile.json"
const SpinningLoader = () => {
  return (
    <div className='m-0 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-[99999] w-[200px] h-[200px]'>
        <Lottie animationData={spinningLoader} loop={true} />
    </div>
  )
}

export default SpinningLoader