import React from "react";
import styles from "./Header.module.css";
import {  useNavigate } from "react-router-dom";
import Button from "../formelements/Button";
import { useDispatch } from "react-redux";
import { logoutSuccess } from "../../utils/redux/features/authSlice";

export const Header = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const handleLogout = () => {
		dispatch(logoutSuccess());
		localStorage.setItem("AL_ADMIN", null);
		navigate("/");
		// window.location.reload();
	};

	return (
        <div className="w-full z-10 right-0 h-20 px-6 items-center bg-[#060709] border-none	 justify-end items-start gap-3 inline-flex">
       <Button
					//onClick={handleSubmit}
					className={`btn ${styles.btnbg}`}
					type="button"
					disabled={false}
					onClick={handleLogout}
				>
					Logout
				</Button>
      </div>
		
		
	);
};





