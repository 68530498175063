import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { ICON_LABELS } from "../../components/icons";
import {  renderTableCell, renderTableHeading, renderTextContent } from "../../utils/helperFuncs";
import IconComponent from "../../components/icons/IconComponent";
import { toast } from "react-toastify";
import DeletePopup from "../../components/formelements/DeletePopup";
import { Title } from "../../components/title/Title";
import Button from "../../components/formelements/Button";
import { useDispatch } from "react-redux";
import { delete_art_type,   get_art} from "../../utils/api/utilAPI";
import { loadingActions } from "../../utils/redux/features/loadingSlice";
import RowsSelector from "../../components/pagination/RowSelector";
import PageSelector from "../../components/pagination/PageSelector";
import  ArtTypeModal  from "./ArtTypeModal";
import GenericModal from "../../components/formelements/Modal";
const tableHeading = [
    "S.No.",
    "Image",
    "Art Type",
    "Actions",
];

  
export default function ArtTypeTable({  loadingData  }) {
    const dispatch = useDispatch();
    const [tableData ,setTableData] = useState([]);
    const [limit , setLimit] = useState(10);
    const [page , setPage] = useState(1);
    const [totalCount , setTotalCount] = useState(0);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [artId , setArtId] = useState("");
    const [artTypeModalOpen , setArtTypeModalOpen] = useState(false);
    const [artTypeData , setArtTypeData] = useState(null);
    const handlePaginationLimitChange = (event) => {
        setLimit(event.target.value);
        setPage(1);
        };
    
    const handlePageChange = (event, newPage) => {
        setPage(newPage);
      };
    
    const handleAddArtType = () => {
        setArtTypeModalOpen(true);
    }   
    const handleArtModalClose= () => {
        setArtTypeData(null);
        setArtTypeModalOpen(false);
    }   
    const handleDeleteModal = (id) => {
        setArtId(id)
        setDeleteModalOpen(true);
      }

    const handleEditModal = (row) => {
        setArtTypeData(row)
        setArtTypeModalOpen(true);
      }
      
    const handleDelete = async () => {
        try {
          await delete_art_type(artId);
          setDeleteModalOpen(false);
          toast.success("Art Type deleted successfully", {
            toastId: "Art Type  Deleted"
          });
          setTableData([])
          fetchArtTypes();
                
        } catch (error) {
          toast.error(error.response?.data?.message, {
           toastId: "Error deleting art Type"
          });
          setDeleteModalOpen(false);
        }
    }

    const handleClose = () => {
            setDeleteModalOpen(false);
          }
  
    const renderActionButtons = (row) => (
        <div className="flex justify-start p-auto gap-2">
            <button
                type="button"
                className={`w-[44px] h-[44px] bg-[#E4FFDD] rounded-[5.02px] px-[8px] py-[10px]`}
                onClick={() => handleEditModal(row)}
            >
                <IconComponent
                    iconLabel={ICON_LABELS.EDIT}
                    color="#167D4C"
                    fontSize="24px"
                />
            </button>
            <button
                type="button"
                className="w-[44px] h-[44px] bg-[#FFDDDD] rounded-[5.02px] px-[8px] py-[10px]"
                onClick={() => handleDeleteModal(row?._id)}

            >
                <IconComponent
                    iconLabel={ ICON_LABELS.DELETE}
                    color="#E63535"
                    fontSize="24px"
                   
                />
            </button>

        </div>

    )
    const renderMediaThumbnail = (media) => {
        return (
          media ?   
          <div 
          type="button"
             className={ `flex w-[46px] h-[46px] bg-[#D9D9D9] rounded-[6px] relative `}
          ><img
            src={media}
            alt=""
            className="object-fill w-full h-full rounded-[6px]"
          /> 
          </div> :  
          renderTextContent("N/A")
        );
      };
    const fetchArtTypes = async() => {
        dispatch(loadingActions.loadingState(true));
        try {
          const response = await get_art(page,limit);
          setTableData(response?.data?.data?.artTypeList);
          setTotalCount(response?.data?.data?.countData);
        } catch (error) {
          toast.error(error.response?.data?.message, {
            toastId: "fetching order list failed",
          });
        } finally {
          dispatch(loadingActions.loadingState(false));
        }
    }

    useEffect(() => {
        fetchArtTypes();
    }, [limit , page]);
    
    return (
        <Paper
            className=" flex-grow flex flex-col justify-start pt-3 gap-[24px] shadow-none !rounded-none "
            component={Paper}
        >
               <div className="flex justify-between items-center flex-wrap">
            <Title
              pagetitle="Art Types"
              fontsize="18px"
              fontWeight="600"
              color="#222124"
            />
            <Button
              onClick={handleAddArtType}
              >
                Add Art Type
              </Button>
         
        </div>
            {loadingData ? (
                ""
            ) : (
                <TableContainer className="scrollContainer w-auto overflow-auto">
                    {tableData?.length !== 0 ? (
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow className="h-11 p-3">
                                    {tableHeading?.map((heading) => renderTableHeading(heading))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tableData?.map((row, index) => (
                                    <TableRow key={row?._id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                        {renderTableCell(<p>{index + 1}</p>)}
                                        {renderTableCell(renderMediaThumbnail(row?.images[0]))}
                                        {renderTableCell(row?.artTypeName || renderTextContent("N/A"))}
                                        {renderTableCell(renderActionButtons(row))}


                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    ) : (
                        <div className="flex flex-col gap-10 justify-center items-center mt-[150px] ">
                            <IconComponent
                                iconLabel={ICON_LABELS.NO_DATA_FOUND}
                                color="#EFEFEF"
                                fontSize="80px"
                            /><p className="text-[18px] font-[600] text-[#888888] opacity-[0.4]">No product found</p> </div>)}
                </TableContainer>)
            
            }
                      <div className="flex justify-between mt-auto items-center">
          <RowsSelector limit={limit} handlePaginationLimitChange={handlePaginationLimitChange} />
          <PageSelector totalCount={totalCount} limit={limit} page={page} handlePageChange={handlePageChange} />
        </div>
        <GenericModal 
            modal={artTypeModalOpen} 
            handleClose={handleArtModalClose} 
            isCross={true} 
            title={artTypeData ? "Edit Art Types" : "Add Art Types"}
            width={"680px"} 
            content={<ArtTypeModal data={artTypeData} refreshFunc = {fetchArtTypes} closeFunc = {handleArtModalClose}/>} />

        <DeletePopup modal={deleteModalOpen} handleDone={handleDelete} handleClose={handleClose} />
        </Paper>
    )
}
