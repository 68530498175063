import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { EVENT_PATH } from "../../utils/constants/routes";
import { ICON_LABELS } from "../../components/icons";
import IconComponent from "../../components/icons/IconComponent";
import { Title } from "../../components/title/Title";
import { STRINGS } from "./strings";
import styles from "./event.module.css";
import { ParaGraph } from "../../components/title/ParaGraph";
import { Paper } from "@mui/material";
import { loadingActions } from "../../utils/redux/features/loadingSlice";
import { useDispatch, useSelector } from "react-redux";
import { get_event_details } from "../../utils/api/utilAPI";
import { toast } from "react-toastify";
import { formatDateTime, formatTime } from "../../utils/helperFuncs";

export const ViewEvent = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const loading = useSelector((state) => state?.state?.loadingReducer?.loading);
  const [data, setData] = useState(location?.state);

  const fetchEventDetails = async () => {
    dispatch(loadingActions.loadingState(true));
    try {
      const response = await get_event_details(location?.state?._id);
      setData(response?.data?.data);
    } catch (error) {
      setData([]);
      toast.error(error?.response?.data?.message, {
        toastId: "Error fetching event details",
      });
    } finally {
      dispatch(loadingActions.loadingState(false));
    }
  };

  useEffect(() => {
    fetchEventDetails();
  }, []);

  return (
    <div className="flex flex-col !h-full justify-start gap-6 scrollPage">
      {!loading &&
        <Paper className="paperClass" component={Paper}>
          <div className="flex gap-3 border-bottom">
            <Link to={EVENT_PATH} className={styles.link}>
              <IconComponent iconLabel={ICON_LABELS.KEYBOARD_BACKSPACE} />
            </Link>
            <Title
              pagetitle={STRINGS.VIEW_TITLE}
              fontsize="20px"
              fontWeight="600"
              color="#060709"
              paddingBottom="1rem"
            />
          </div>

          {data?.length !== 0 ? (
            <div className="flex flex-col gap-4">
              <div className="flex w-full gap-3">
                <div className="flex flex-col w-1/2">
                  <ParaGraph
                    text={STRINGS.EVENT_NAME}
                    fontsize="20px"
                    fontWeight="600"
                    color="#060709"
                  />
                  <ParaGraph
                    text={data?.eventName}
                    color="#858585"
                    fontsize="16px"
                  />
                </div>
                <div className="flex flex-col w-1/2">
                  <ParaGraph
                    text={STRINGS.ARTIST_NAME}
                    fontsize="20px"
                    fontWeight="600"
                    color="#060709"
                  />
                  <ParaGraph
                    text={`${data?.eventArtist?.firstName || ""} ${data?.eventArtist?.lastName || ""}`}
                    color="#858585"
                    fontsize="16px"
                  />
                </div>
              </div>

              <div className="flex w-full gap-3">
                <div className="flex flex-col w-1/2">
                  <ParaGraph
                    text={STRINGS.EVENT_DATE}
                    fontsize="20px"
                    fontWeight="600"
                    color="#060709"
                  />
                  <ParaGraph
                    text={`${formatDateTime(data?.eventStartDate)} - ${formatTime(
                      data?.eventEndDate
                    )}`}
                    color="#858585"
                    fontsize="16px"
                  />
                </div>
                <div className="flex flex-col w-1/2">
                  <ParaGraph
                    text={STRINGS.EVENT_ADDRESS}
                    fontsize="20px"
                    fontWeight="600"
                    color="#060709"
                  />
                  <ParaGraph
                    text={data?.eventAddress}
                    color="#858585"
                    fontsize="16px"
                  />
                </div>
              </div>

              <div className="flex flex-col gap-2">
                <ParaGraph
                  text={STRINGS.EVENT_DETAILS}
                  fontsize="20px"
                  fontWeight="600"
                  color="#060709"
                />
                <ParaGraph
                  text={data?.eventDetails}
                  color="#858585"
                  fontsize="16px"
                />
              </div>
            </div>
          ) : (
            <div className="flex flex-col gap-10 justify-center items-center mt-[150px]">
              <IconComponent
                iconLabel={ICON_LABELS.NO_DATA_FOUND}
                color="#EFEFEF"
                fontSize="80px"
              />
              <p className="text-[18px] font-[600] text-[#888888] opacity-[0.4]">
                No data found
              </p>
            </div>
          )}
        </Paper>
      }
    </div>
  );
};
