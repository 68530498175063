import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { ICON_LABELS } from "../../components/icons";
import {  renderNameWithProfile, renderTableCell, renderTableHeading, renderTextContent } from "../../utils/helperFuncs";
import IconComponent from "../../components/icons/IconComponent";
import { toast } from "react-toastify";
import DeletePopup from "../../components/formelements/DeletePopup";
import { useNavigate } from "react-router-dom";
import {PRODUCT_ADD_PATH_2 } from "../../utils/constants/routes";
import { Title } from "../../components/title/Title";
import { TextInput } from "../../components/formelements/TextInput";
import SelectBox from "../../components/formelements/SelectBox";
import Button from "../../components/formelements/Button";
import { useDispatch } from "react-redux";
import { delete_prod, get_cat, get_prod, get_prod_by_cat, search_product } from "../../utils/api/utilAPI";
import { loadingActions } from "../../utils/redux/features/loadingSlice";
import RowsSelector from "../../components/pagination/RowSelector";
import PageSelector from "../../components/pagination/PageSelector";
const tableHeading = [
    "S.No.",
    "Product",
    "Price",
    "Category",
    "Product Type",
    "Actions",
];
const prep_for_select_box = (arr) => {
    return arr?.map((item) => {
      return {
        id: item._id,
        name: item.categoryName,
      };
    });
  };
  
export default function ProductTable({  loadingData  }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [tableData ,setTableData] = useState([]);
    const [limit , setLimit] = useState(10);
    const [page , setPage] = useState(1);
    const [totalCount , setTotalCount] = useState(0);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [productId , setProductId] = useState("");
    const [search , setSearch] = useState("");
    const [filter, setFilter] = useState("");
    const [categories, setCategories] = useState([]);

    const handlePaginationLimitChange = (event) => {
        setLimit(event.target.value);
        setPage(1);
    
      };
    
    const handlePageChange = (event, newPage) => {
        setPage(newPage);
      };
      const handleSearchByCategory = async(val) => {
        
            if (val === "") fetchProductList();
            else {
                get_prod_by_cat(val)
                .then((res) => {
                  setTableData(res.data?.data?.productList);
                  setTotalCount(res?.data?.data?.countData)
                })
                .catch((err) => {
                  toast.error("Error Fetching Products", {toastId : "Error fetching category products"});
                });
      }
             
          
      }
    const handleSearch = async() => {
        console.log("handleSearch")
        if (search !== "") {
            try {
               
                const response = await search_product(search);
                setTableData(response?.data?.data);
            } catch (error) {
                setTableData([]);
                toast.error("Search Failed" , {toastId : "Search Failed"})
            }
          }
          else {
            fetchProductList();
          }

    }
    const handleDeleteModal = (id) => {
        setProductId(id)
        setDeleteModalOpen(true);
      }

    const handleEditProduct = (row) => {
        navigate(PRODUCT_ADD_PATH_2 ,{ state:  row });
      }
      
    const handleDelete = async () => {
        try {
          await delete_prod(productId);
          setDeleteModalOpen(false);
          toast.success("Product deleted successfully", {
            toastId: "Product  Deleted"
          });
          setTableData([])
          fetchProductList();
                
        } catch (error) {
          toast.error(error.response?.data?.message, {
           toastId: "Error deleting product"
          });
          setDeleteModalOpen(false);
        }
    }

    const handleClose = () => {
            setDeleteModalOpen(false);
          }
  
    const renderActionButtons = (row) => (
        <div className="flex justify-start p-auto gap-2">
           

            <button
                type="button"
                className={`w-[44px] h-[44px] bg-[#E4FFDD] rounded-[5.02px] px-[8px] py-[10px]`}
                onClick={() => handleEditProduct(row)}
            >
                <IconComponent
                    iconLabel={ICON_LABELS.EDIT}
                    color="#167D4C"
                    fontSize="24px"
                />
            </button>
            <button
                type="button"
                className="w-[44px] h-[44px] bg-[#FFDDDD] rounded-[5.02px] px-[8px] py-[10px]"
                onClick={() => handleDeleteModal(row?._id)}

            >
                <IconComponent
                    iconLabel={ ICON_LABELS.DELETE}
                    color="#E63535"
                    fontSize="24px"
                   
                />
            </button>

        </div>

    )

    const fetchProductList = async() => {
        dispatch(loadingActions.loadingState(true));
        try {
          const response = await get_prod(page,limit);
          setTableData(response?.data?.data?.productList);
          setTotalCount(response?.data?.data?.countData);
        } catch (error) {
          toast.error(error.response?.data?.message, {
            toastId: "fetching order list failed",
          });
        } finally {
          dispatch(loadingActions.loadingState(false));
        }
    }
 console.log(categories , "categories")
    useEffect(() => {
        fetchProductList();
    }, [limit , page]);
    useEffect(() => {
      const fetchCategories = async () => {
        try {
          const response = await get_cat();
          console.log(response?.data?.data, "category data");
          setCategories(response?.data?.data);
        } catch (error) {
          toast.error("Error fetching categories", { toastId: "Error fetching categories" });
        }
      };
  
      fetchCategories();
    }, []);
  
   

    return (
        <Paper
            className=" flex-grow flex flex-col justify-start pt-3 gap-[24px] shadow-none !rounded-none border-t-[1px]"
            component={Paper}
        >
               <div className="flex justify-between items-center flex-wrap">
            <Title
              pagetitle="All Products"
              fontsize="18px"
              fontWeight="600"
              color="#222124"
            />

          <div className="flex gap-4">
          <TextInput
                type="text"
                placeholder="Search"
                style={{ background: "#E4E4E4" }}
                marginbot="0"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                searchFunc={handleSearch}
              />
             <SelectBox
                width="120px"
                background="rgb(228, 228, 228)"
                list={prep_for_select_box(categories?.categoryList)}
                placeholder={"None"}
                value={filter}
                setValue={setFilter}
                searchAPI={(val) => handleSearchByCategory(val)}
              />
              <Button
              onClick={() => navigate(PRODUCT_ADD_PATH_2)}
              >
                Add Product
              </Button>
          </div>
        </div>
            {loadingData ? (
                ""
            ) : (
                <TableContainer className="scrollContainer w-auto overflow-auto">
                    {tableData.length !== 0 ? (
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow className="h-11 p-3">
                                    {tableHeading?.map((heading) => renderTableHeading(heading))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tableData?.map((row, index) => (
                                    <TableRow key={row?._id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                        {renderTableCell(<p>{index + 1}</p>)}
                                        {renderTableCell(renderNameWithProfile(row?.productName, row?.productImages[0] , true) || renderTextContent("N/A"))}

                                        {renderTableCell(`$${row?.basePrice}` || renderTextContent("N/A"))}
                                        {renderTableCell(row?.category?.categoryName ||  renderTextContent("N/A"))}
                                        {renderTableCell((row?.isCustomApplicable ? "Custom" : "General" )||  renderTextContent("N/A"))}
                                        {renderTableCell(renderActionButtons(row))}


                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    ) : (
                        <div className="flex flex-col gap-10 justify-center items-center mt-[150px] ">
                            <IconComponent
                                iconLabel={ICON_LABELS.NO_DATA_FOUND}
                                color="#EFEFEF"
                                fontSize="80px"
                            /><p className="text-[18px] font-[600] text-[#888888] opacity-[0.4]">No product found</p> </div>)}
                </TableContainer>)
            
            }
                      <div className="flex justify-between mt-auto items-center">
          <RowsSelector limit={limit} handlePaginationLimitChange={handlePaginationLimitChange} />
          <PageSelector totalCount={totalCount} limit={limit} page={page} handlePageChange={handlePageChange} />
        </div>
      <DeletePopup modal={deleteModalOpen} handleDone={handleDelete} handleClose={handleClose} />

        </Paper>
    )
}
