
import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import { Title } from "../../components/title/Title";
import RowsSelector from "../../components/pagination/RowSelector";
import PageSelector from "../../components/pagination/PageSelector";
import { get_collab_request } from "../../utils/api/utilAPI";
import { useDispatch, useSelector } from "react-redux";
import { loadingActions } from "../../utils/redux/features/loadingSlice";
import CollaboratorTable from "./CollaboratorTable";
import Button from "../../components/formelements/Button";
import { toast } from "react-toastify";
import GenericModal from "../../components/formelements/Modal";
import AddCollaborators from "./AddCollaboartors";


const Collaborators = () => {
  const [tableData, setTableData] = useState([]);
  const loading = useSelector((state) => state?.loadingReducer?.loading);
  const dispatch = useDispatch();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [action, setAction] = useState(true);
  const [collabModal, setCollabModal] = useState(false);

  const handlePaginationLimitChange = (event) => {
    setLimit(event.target.value);
    setPage(1);

  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };
  const handleCollaborator = (event) => {
    setCollabModal(true);
  }
  const fetchCollabList = async () => {
    dispatch(loadingActions.loadingState(true));
    try {
      const response = await get_collab_request(page, limit);
      setTableData(response?.data?.data?.list);
      setTotalCount(response?.data?.data?.count[0]?.count);
    } catch (error) {
      console.error("Error fetching user list:", error?.response?.data?.message);
      toast.error(error.response?.data?.message, {
        toastId: "fetching collab list failed",
      });
    } finally {
      dispatch(loadingActions.loadingState(false));
    }
  };

  useEffect(() => {
    fetchCollabList();
  }, [page, limit]);

  useEffect(() => {
    if (!action) { fetchCollabList(); }
    setAction(true);
  }, [action]);
  return (
    <div className="flex flex-col h-full  justify-start gap-6 ">
      <Paper
        className="paperClass"
        component={Paper}
      >
        <div className="flex flex-row items-center justify-between  ">
          <Title
            pagetitle={"Collab Requests"}
            fontsize="20px"
            fontWeight="600"
            color="#060709"
            border={false}
          />
          <Button
            className="btn"
            type="button"
            disabled={false}
            onClick={handleCollaborator}
          >
            Add Collaborator
          </Button></div>

        <CollaboratorTable tableData={tableData} loadingData={loading} sendAction={(e) => setAction(e)} />
        <div className="flex justify-between mt-auto items-center">
          <RowsSelector limit={limit} handlePaginationLimitChange={handlePaginationLimitChange} />
          <PageSelector totalCount={totalCount} limit={limit} page={page} handlePageChange={handlePageChange} />
        </div>
        <GenericModal modal={collabModal} handleClose={() => setCollabModal(false)} isCross={true} title={"Add Collaborator"} description={"Add collaborators with description"} width={"680px"} content={<AddCollaborators handleModalOpen={(e) => setCollabModal(e)} />} />
      </Paper>
    </div>
  );
}

export default Collaborators;