import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { EVENT_PATH } from "../../utils/constants/routes";
import IconComponent from "../../components/icons/IconComponent";
import { Title } from "../../components/title/Title";
import styles from "./event.module.css";
import { STRINGS } from "./strings";
import { ICON_LABELS } from "../../components/icons";
import { TextInput } from "../../components/formelements/TextInput";
import { TextBox } from "../../components/formelements/TextBox";
import Button from "../../components/formelements/Button";
import { Paper } from "@mui/material";
import {
	DatePicker,
	LocalizationProvider,
	SingleInputTimeRangeField,
} from "@mui/x-date-pickers-pro";
import { AdapterMoment } from "@mui/x-date-pickers-pro/AdapterMoment";
import moment from "moment";
import { AddEventValidations } from "../../utils/validations/joiValidations";
import { loadingActions } from "../../utils/redux/features/loadingSlice";
import { useDispatch } from "react-redux";
import { add_event } from "../../utils/api/utilAPI";
import { toast } from "react-toastify";

export const AddEvent = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [formData, setFormData] = useState({
		eventName: "",
		eventDate: null,
		eventStartTime: null,
		eventEndTime: null,
		eventArtistName: "",
		eventAddress: "",
		eventDetails: "",
	});
	const [validations, setValidations] = useState({
		eventName: "",
		eventArtistName: "",
		eventAddress: "",
		eventStartTime: "",
		eventEndTime: "",
		eventDetails: "",
		eventDate: "",
	});
	const handleFormDataChange = (e) => {
		const { name, value } = e.target;
		setFormData({
			...formData,
			[name]: value,
		});
	};
	const getDateTimeInISO = (date, time) => {
		if (!date || !time) return null;
		const combined = moment(date).set({
			hour: moment(time).hour(),
			minute: moment(time).minute(),
		});
		return combined.toISOString();
	};

	const handleAddEvent = async (data) => {
		dispatch(loadingActions.loadingState(true));
		try {
			await add_event(data);
			toast.success("Event Added Successfully", {
				toastId: "Event Added Successfully",
			});
			navigate(EVENT_PATH);
		} catch (error) {
			toast.error(error?.response?.data?.message, {
				toastId: "Error  Adding Event",
			});
		} finally {
			dispatch(loadingActions.loadingState(false));
		}
	};
	const handleSubmit = (e) => {
		e.preventDefault();
		const data = {
			eventName: formData.eventName,
			eventArtistName: formData.eventArtist,
			eventAddress: formData.eventAddress,
			eventStartTime: getDateTimeInISO(
				formData.eventDate,
				formData.eventStartTime
			),
			eventEndTime: getDateTimeInISO(formData.eventDate, formData.eventEndTime),
			eventDetails: formData.eventDetails,
			eventDate: formData.eventDate
				? moment(formData.eventDate).toISOString()
				: null,
		};
		const { error } = AddEventValidations.validate(data);
		if (error) {
			const validationErrors = error.details.reduce((acc, curr) => {
				acc[curr.path[0]] = curr.message;
				return acc;
			}, {});
			setValidations(validationErrors);
		} else {
			setValidations({});
			delete data.eventDate;
			handleAddEvent(data);
		}
	};

	return (
		<div className="flex flex-col !h-full  justify-start gap-6  scrollPage">
			<Paper className="paperClass " component={Paper}>
				<div className="flex gap-20 border-bottom pb-3">
					<Link
						to={EVENT_PATH}
						className={styles.link}
						onClick={() => navigate(EVENT_PATH)}
					>
						<IconComponent iconLabel={ICON_LABELS.KEYBOARD_BACKSPACE} />
					</Link>
					<Title
						pagetitle={STRINGS.ADD_EVENT}
						fontsize="20px"
						fontWeight="600"
						color="#060709"
						border={false}
					/>
				</div>
				<TextInput
					type="text"
					name="eventName"
					label={STRINGS.EVENT_NAME}
					placeholder={STRINGS.EVENT_NAME}
					value={formData?.eventName}
					error={validations?.eventName}
					onChange={handleFormDataChange}
				/>
				<TextInput
					type="text"
					name="eventArtist"
					label={STRINGS.ARTIST_NAME}
					placeholder={STRINGS.ENTER_ARTIST_NAME}
					value={formData?.eventArtist}
					error={validations?.eventArtist}
					onChange={handleFormDataChange}
				/>
				<TextInput
					type="text"
					name="eventAddress"
					label={STRINGS.EVENT_COMPLETE_ADDRESS}
					placeholder={STRINGS.EVENT_COMPLETE_ADDRESS}
					value={formData?.eventAddress}
					error={validations?.eventAddress}
					onChange={handleFormDataChange}
				/>
				<div className="flex w-[100%] justify-between gap-8">
					<div className="flex w-1/2 flex-col gap-[.5rem]">
						<Title
							pagetitle={"Event Date"}
							fontsize="16px"
							fontWeight="500"
							color="#060709"
							border={false}
						/>
						<LocalizationProvider dateAdapter={AdapterMoment}>
							<DatePicker
								sx={{
									".MuiOutlinedInput-input": {
										fontFamily: "Poppins",
									},
									".css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root": {
										height: "42px",
										borderRadius: "4px",
										fontSize: "400",
									},
								}}
								value={formData?.eventDate}
								onChange={(e) =>
									setFormData((formData) => ({
										...formData,
										eventDate: e,
									}))
								}
								disablePast
							/>
							{validations?.eventDate && (
								<span className="text-danger mb-[0.5rem]">
									{validations?.eventDate}
								</span>
							)}
						</LocalizationProvider>
					</div>
					<div className="flex w-1/2 flex-col gap-[.5rem]">
						<Title
							pagetitle={"Event Time"}
							fontsize="16px"
							fontWeight="500"
							color="#060709"
							border={false}
						/>
						<LocalizationProvider dateAdapter={AdapterMoment}>
							<SingleInputTimeRangeField
								sx={{
									".MuiOutlinedInput-input": {
										fontFamily: "Poppins",
									},
									".css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root": {
										height: "42px",
										borderRadius: "4px",
									},
								}}
								value={[formData?.eventStartTime, formData?.eventEndTime]}
								onChange={(e) =>
									setFormData((formData) => ({
										...formData,
										eventStartTime: e?.[0],
										eventEndTime: e?.[1],
									}))
								}
							/>
							{(validations?.eventStartTime || validations?.eventEndTime) && (
								<span className="text-danger mb-[0.5rem]">
									{validations?.eventStartTime || validations?.eventEndTime}
								</span>
							)}
						</LocalizationProvider>
					</div>
				</div>
				<TextBox
					name="eventDetails"
					label={STRINGS.EVENT_DETAILS}
					rows="5"
					placeholder={STRINGS.ENTER_EVENT_DETAILS}
					value={formData?.eventDetails}
					error={validations?.eventDetails}
					onChange={handleFormDataChange}
				/>
				<div className="flex justify-end gap-20 my-3">
					<Button variant="secondary" onClick={() => navigate(EVENT_PATH)}>
						{STRINGS.BTNTEXTCANCEL}
					</Button>
					<Button className="btn" type={"submit"} onClick={handleSubmit}>
						{STRINGS.BTNTEXTSUBMIT}
					</Button>
				</div>
			</Paper>
		</div>
	);
};
