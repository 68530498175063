import React from 'react'
import Sidenav from '../sidenav/sidenav'
import { Outlet } from "react-router-dom";
import { Header } from '../header/header';
import SpinningLoader from './loader';
import { useSelector } from 'react-redux';
const DefaultLayout = () => {
  const isLoading = useSelector((state) => state?.loadingReducer?.loading);

  return (
    <div className="flex flex-row w-[100vw] scrollContainer">
      <Sidenav />

      <div className=" flex flex-col w-full h-[100vh]">
        <Header />
        <div className=" relative p-4 bg-gray-100 h-full overflow-hidden ">
          <Outlet />
          {isLoading && <SpinningLoader />}
        </div>

      </div>

    </div>
  )
}

export default DefaultLayout
