import React, { useEffect, useState } from "react";
import { Title } from "../../components/title/Title";
import Button from "../../components/formelements/Button";
import AddCategoryModal from "./AddCategory";
import GenericModal from "../../components/formelements/Modal";
import { Tooltip } from "@mui/material";
import DeletePopup from "../../components/formelements/DeletePopup";
import { delete_cat, get_cat } from "../../utils/api/utilAPI"; // Ensure this import is correct
import { toast } from "react-toastify";

const CategoryTable = () => {
  const [categories, setCategories] = useState([]);
  const [addCategoryModalOpen, setAddCategoryModal] = useState(false);
  const [categoryData, setCategoryData] = useState(null);
  const [deleteCategoryModal, setDeleteCategoryModal] = useState(false);

  const handleModalClose = () => {
    setCategoryData(null);
    setAddCategoryModal(false);
  };

  const handleEditCategory = (category) => {
    setCategoryData(category);
    setAddCategoryModal(true);
   
  };

  const handleAddCategory = () => {
    setAddCategoryModal(true);
  };

  const handleDeleteCategoryModal = (category) => {
    setDeleteCategoryModal(true);
    setCategoryData(category);
  };

  const handleDeleteModalClose = () => {
    setDeleteCategoryModal(false);
    setCategoryData(null);
  };

  const handleCategoryDelete = async () => {
    try {
      await delete_cat(categoryData?._id);
      toast.success("Category Deleted Successfully" , {toastId : "category deleted"});
      window.location.reload();
    } catch (error) {
        toast.success("Error Deleting Category" , {toastId : "error deleting category"});
    }
  };
 
  const fetchCategoryData = async() => {
    try {
        const response = await get_cat();
        setCategories(response?.data?.data?.categoryList)
    } catch (error) {
        
    }
  }
console.log(categories, "categories123")
  useEffect(() => {
    fetchCategoryData();
  }, []);
  
  return (
    <div>
      <div className="flex justify-between items-center flex-wrap">
        <Title
          pagetitle="Categories"
          fontsize="18px"
          fontWeight="600"
          color="#222124"
        />
        <Button onClick={handleAddCategory}>Add Category</Button>
      </div>
      <div style={{ display: "flex", flexWrap: "wrap", gap: "16px" }}>
        {categories?.map((category) => (
          <div style={{ position: "relative" }} key={category?._id}>
            <Tooltip title="Double click to edit" arrow>
              <div
                style={{
                  padding: "4px 8px",
                  borderRadius: "4px",
                  background: "#E6355C",
                  fontWeight: 500,
                  fontSize: "14px",
                  color: "white",
                  cursor: "pointer",
                }}
                onDoubleClick={() => handleEditCategory(category)}
              >
                {category?.categoryName}
              </div>
            </Tooltip>
            <svg
              width="20"
              height="20"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{
                position: "absolute",
                top: "-8px",
                right: "-10px",
                cursor: "pointer",
              }}
              onClick={() => handleDeleteCategoryModal(category)}
            >
              <path
                d="M13.9997 2.33337C7.57134 2.33337 2.33301 7.57171 2.33301 14C2.33301 20.4284 7.57134 25.6667 13.9997 25.6667C20.428 25.6667 25.6663 20.4284 25.6663 14C25.6663 7.57171 20.428 2.33337 13.9997 2.33337ZM17.9197 16.6834C18.258 17.0217 18.258 17.5817 17.9197 17.92C17.7447 18.095 17.523 18.1767 17.3013 18.1767C17.0797 18.1767 16.858 18.095 16.683 17.92L13.9997 15.2367L11.3163 17.92C11.1413 18.095 10.9197 18.1767 10.698 18.1767C10.4763 18.1767 10.2547 18.095 10.0797 17.92C9.74134 17.5817 9.74134 17.0217 10.0797 16.6834L12.763 14L10.0797 11.3167C9.74134 10.9784 9.74134 10.4184 10.0797 10.08C10.418 9.74171 10.978 9.74171 11.3163 10.08L13.9997 12.7634L16.683 10.08C17.0213 9.74171 17.5813 9.74171 17.9197 10.08C18.258 10.4184 18.258 10.9784 17.9197 11.3167L15.2363 14L17.9197 16.6834Z"
                fill="black"
              />
            </svg>
          </div>
        ))}
      </div>

      <GenericModal
        modal={addCategoryModalOpen}
        handleClose={handleModalClose}
        isCross={true}
        title={categoryData ? "Edit Category" : "Add Category"}
        width={"680px"}
        content={<AddCategoryModal data={categoryData}/>}
      />

      <DeletePopup
        modal={deleteCategoryModal}
        handleDone={handleCategoryDelete}
        handleClose={handleDeleteModalClose}
      />
    </div>
  );
};

export default CategoryTable;
