import { Avatar, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Title } from "../../components/title/Title";
import Button from "../../components/formelements/Button";
import ResetPasswordPopup from "./Components/ResetPasswordPopup";
import { get_admin_profile } from "../../utils/api/utilAPI";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from 'react-redux';
import { loadingActions } from "../../utils/redux/features/loadingSlice";
import EditProfilePopup from "./Components/EditProfilePopup";
import { ParaGraph } from "../../components/title/ParaGraph";


const MyProfile = () => {

  const [adminProfile, setAdminProfile] = useState({});
  const [resetPasswordModal, setResetPasswordModal] = useState(false);
  const [editProfileModal, setEditProfileModal] = useState(false);

  const loading = useSelector((state) => state?.loadingReducer?.loading);
  const dispatch = useDispatch();

  const fetchAdminProfile = async () => {
    dispatch(loadingActions.loadingState(true));
    try {
      const response = await get_admin_profile();
      setAdminProfile(response.data?.data);

    } catch (error) {
      toast.error(error.response?.data?.message, {
        toastId: "fetching Admin Profile data failed",
      });
    } finally {
      dispatch(loadingActions.loadingState(false));
    }
  };


  // Reset Password modal handlers
  const handleResetPasswordModalOpen = () => {
    setResetPasswordModal(true);
  }

  const handleResetPasswordModalClose = () => {
    setResetPasswordModal(false);
  }

  // Edit Profile modal handlers
  const handleEditProfileModalOpen = () => {
    setEditProfileModal(true);
  }

  const handleEditProfileModalClose = () => {
    setEditProfileModal(false);
  }


  useEffect(() => {
    fetchAdminProfile()
  }, [])

  return (
    <div className="flex flex-col h-full  justify-start gap-6 ">
      <Paper
        className="paperClass"
        component={Paper}
      >
        <Title
          pagetitle={"My Profile"}
          fontsize="20px"
          fontWeight="600"
          color="#060709"
        />
        <div
          className="pt-3 border-t-[1px] px-3"
          component={Paper}
        >
          {!loading &&
            <div>
              <Avatar
                alt="Admin Profile Image"
                src={adminProfile?.profilePicture}
                sx={{ width: 125, height: 125 }}
              />
              <div className="pt-3">
                <div className="flex justify-between items-center">
                  <ParaGraph
                    text="Personal Details"
                    fontsize="18px"
                    fontWeight="600"
                    color="#E6355C"
                  />
                  <Button
                    onClick={handleEditProfileModalOpen}
                  >
                    Edit Profile
                  </Button>
                </div>

                <div className="py-3 flex justify-between items-center w-[80%] flex-wrap gap-y-5">
                  <div className="w-[50%]">
                    <ParaGraph
                      text="Full Name"
                      fontsize="18px"
                      fontWeight="600"
                      color="#000000"
                    />
                    <ParaGraph
                      text={`${adminProfile?.firstName} ${adminProfile?.lastName}`}
                      fontsize="16px"
                      fontWeight="500"
                      color="#858585"
                      margin="5px 0px 0px"
                    />
                  </div>

                  <div className="w-[50%]">
                    <ParaGraph
                      text="Email"
                      fontsize="18px"
                      fontWeight="600"
                      color="#000000"
                    />
                    <ParaGraph
                      text={adminProfile?.email}
                      fontsize="16px"
                      fontWeight="500"
                      color="#858585"
                      margin="5px 0px 0px"
                    />
                  </div>

                  <div className="w-[50%] my-2">
                    <ParaGraph
                      text="Phone Number"
                      fontsize="18px"
                      fontWeight="600"
                      color="#000000"
                    />
                    <ParaGraph
                      text="89777777"
                      fontsize="16px"
                      fontWeight="500"
                      color="#858585"
                      margin="5px 0px 0px"
                    />
                  </div>
                </div>

                <div className="flex justify-between items-center my-2">
                  <div className="w-[50%]">
                    <Typography variant="body1" className="text-[#000000]" sx={{ fontWeight: 600, fontSize: '18px', width: '50%' }}>
                      Change Password
                    </Typography>
                    <ParaGraph
                      text="************"
                      fontsize="16px"
                      fontWeight="500"
                      color="#858585"
                      margin="5px 0px 0px"
                    />
                  </div>
                  <Button
                    onClick={handleResetPasswordModalOpen}
                  >
                    Change Password
                  </Button>
                </div>

              </div>
            </div>
          }
        </div>

        <ResetPasswordPopup modal={resetPasswordModal} handleClose={handleResetPasswordModalClose} />
        <EditProfilePopup modal={editProfileModal} handleClose={handleEditProfileModalClose} prevData={adminProfile} refreshFunc={fetchAdminProfile} />
      </Paper>
    </div>);
};

export default MyProfile;
