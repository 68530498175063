import React, { useEffect, useState } from 'react'
import { Title } from '../../components/title/Title'
import {  get_promo_list,search_promocode } from '../../utils/api/utilAPI'
import { TextInput } from '../../components/formelements/TextInput'
import { Paper } from '@mui/material'
import PageSelector from '../../components/pagination/PageSelector'
import RowsSelector from '../../components/pagination/RowSelector'
import { useDispatch, useSelector } from 'react-redux'
import { loadingActions } from '../../utils/redux/features/loadingSlice'
import { toast } from 'react-toastify'
import Button from '../../components/formelements/Button'
import PromoCodeTable from './PromoCodeTable'
import { useNavigate } from 'react-router-dom'
import {  ADD_PROMO_CODE_PATH } from '../../utils/constants/routes'


 const PromoCode = () => {

  const [tableData, setTableData] = useState([]);
  const loading = useSelector((state) => state?.loadingReducer?.loading);
  const dispatch = useDispatch();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [search, setSearch] = useState("");
  const [action , setAction] = useState(false);
  const navigate = useNavigate();

  const handlePaginationLimitChange = (event) => {
    setLimit(event.target.value);
    setPage(1);

  };
  
  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };
  const fetchPromoCode = async () => {
    dispatch(loadingActions.loadingState(true));
    try {
      const response = await get_promo_list(page, limit, 0);
      setTableData(response?.data?.data);
      setTotalCount(response?.data?.totalCount);
    } catch (error) {
      toast.error(error.response?.data?.message, {
        toastId: "fetching promocode list failed",
      });
    } finally {
      dispatch(loadingActions.loadingState(false));
    }
  }

  const handleSearchPromo = async () => {
    if(search.length !== 0) {
    dispatch(loadingActions.loadingState(true));
    try {
      setPage(1);
      const response = await search_promocode(search);
      response?.data?.data ? setTableData(response?.data?.data) : setTableData([]);
      setTotalCount(response?.data?.totalCount);
    } catch (error) {
      toast.error(error?.response?.data?.message, {
        toastId: "Error fetching promocode  "
      });
    } finally {
      dispatch(loadingActions.loadingState(false));
    } }
    else {
      fetchPromoCode();
    }
  }


  useEffect(() => {
        fetchPromoCode();

      }, [page, limit]);

useEffect(() => {
   if(action) {
    fetchPromoCode();
    setAction(false)
   }
} , [action])

  return (
    <div className="flex flex-col h-full  justify-start gap-6 ">
      <Paper
        className="paperClass"
        component={Paper}
      >
        <div className="flex flex-row items-center justify-between  ">
          <Title
            pagetitle={"Promo Code"}
            fontsize="20px"
            fontWeight="600"
            color="#060709"
            border={false}
          />
          <div className="flex flex-row items-center gap-4 ">
          <TextInput
            type="text"
            placeholder="Search Promo Code"
            style={{ background: "#E4E4E4" }}
            marginbot="0"
            onChange={(e) => setSearch(e.target.value)}
            searchFunc={handleSearchPromo}
          />
          <Button
              type="button"
              disabled={false}
              onClick={() => navigate(ADD_PROMO_CODE_PATH)}            >
            Add Promo Code
            </Button>
            
          </div>
        
        </div>
        <PromoCodeTable tableData={tableData} loadingData={loading} sendAction={setAction}/>
        <div className="flex justify-between mt-auto items-center">
          <RowsSelector limit={limit} handlePaginationLimitChange={handlePaginationLimitChange} />
          <PageSelector totalCount={totalCount} limit={limit} page={page} handlePageChange={handlePageChange} />
        </div>
      </Paper>
    </div>
  )
}

export default PromoCode ;