import React, { useEffect, useState } from "react";
import { MERCHANDISE_PATH } from "../../utils/constants/routes";
import IconComponent from "../../components/icons/IconComponent";
import { ICON_LABELS } from "../../components/icons";
import { Title } from "../../components/title/Title";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Button from "../../components/formelements/Button";
import { CircularProgress, Paper } from "@mui/material";
import { TextInput } from "../../components/formelements/TextInput";
import { TextBox } from "../../components/formelements/TextBox";
import SelectBox from "../../components/formelements/SelectBox";
import { STRINGS } from "./strings";
import {
	add_product,
	edit_product,
	fetch_prod,
	get_art,
	get_cat,
} from "../../utils/api/utilAPI";
import { toast } from "react-toastify";
import styles from "../merchandise/merchandise.module.css";
import { InputFile } from "../../components/formelements/InputFile";
import RectangleSelection from "./rectangleSelection";
import { AddProductValidations } from "../../utils/validations/joiValidations";
import { useDispatch } from "react-redux";
import { loadingActions } from "../../utils/redux/features/loadingSlice";
const prep_for_select_box = (arr) => {
	return arr?.map((item) => {
		return {
			id: item._id,
			name: item.categoryName,
		};
	});
};
const DROPDOWN_PRODUCT_TYPE = [
	{ id: "general", name: "General" },
	{ id: "custom", name: "Custom Design" },
];
const prep_art_select_box = (arr) => {
	return arr?.map((item) => {
		return {
			id: item._id,
			name: item.artTypeName,
		};
	});
};
export const AddProduct = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [name, setName] = useState("");
	const [description, setDescription] = useState("");
	const [productArr, setProductArr] = useState([]); // used for newly added product images (file)
	const [productURLArr, setProductURLArr] = useState([]); // used for newly added product images (URL)
	const [editProductArr, setEditProductArr] = useState([]); // used for downloaded product images (s3 URLs)
	const [basePrice, setBasePrice] = useState(null);
	// ONLY IDS
	const [category, setCategory] = useState(""); // will grab and set from what the API provides
	const [artType, setArtType] = useState("");
	const [sizeGuide, setSizeGuide] = useState([]); //array of a single image where, if addProduct => file | if editProduct => s3 URL
	const [sizeGuideURL, setSizeGuideURL] = useState([]); //array of a single image where if addProduct => URL | if editProduct => s3 URL
	const [categories, setCategories] = useState([]);
	const [artTypes, setArtTypes] = useState([]);
	const [videoArr, setVideoArr] = useState([]);
	const [videoURLArr, setVideoURLArr] = useState([]);
	const [loading, setLoading] = useState(false);
	const [productType, setProductType] = useState("");
	const [coordinates, setCoordinates] = useState([]);
	const [validations, setValidations] = useState({
		name: "",
		description: "",
		category: "",
		productType: "",
		artType: "",
		productArr: "",
		coordinates: "",
		videoArr: "",
		sizeGuide: "",
		basePrice: "",
	});

	const fetchCategories = async () => {
		try {
			const response = await get_cat();
			setCategories(response?.data?.data);
		} catch (error) {
			toast.error("Error fetching categories", {
				toastId: "Error fetching categories 1",
			});
		}
	};

	const fetchArtTypes = async () => {
		try {
			const response = await get_art();
			setArtTypes(response?.data?.data);
		} catch (error) {
			toast.error("Error fetching Art Types", {
				toastId: "Error fetching artTypes",
			});
		}
	};

	const handleRectangleUpdate = (rect) => {
		console.log(rect, "rectangle");
		setCoordinates(rect);
	};

	const handleFileSelect = async (
		event,
		type,
		arr,
		setArr,
		setURLArr,
		n,
		prevArrSize
	) => {
		const files = event.target.files;
		let newImages = [];
		let newURLs = [];

		 if (files.length + prevArrSize > n) {
				alert(`You can only upload ${n} image${n > 1 ? "s" : ""}.`); // toastify
				return;
		}
		for (const file of files) {
			// Check if the file is an image
			if (!file.type.startsWith(`${type}/`)) {
				alert("Please select only images.");
				continue;
			}
			// Create object URL for the image
			newImages.push(file);
			newURLs.push(URL.createObjectURL(file));
		}

		// filter urls start with blob: from the array
		newURLs = newURLs.filter((url) => url.startsWith("blob:"));
		// set the new images and urls
		setArr((currentArr) => [...currentArr, ...newImages]);
		setURLArr((currentArr) => [...currentArr, ...newURLs]);
	};

	const handleFileDelete = (index, arr, setArr, URLArr, setURLArr) => {
		let tempArr = arr;
		tempArr.splice(index, 1);
		setArr([...tempArr]);

		if (URLArr) {
			tempArr = URLArr;
			tempArr.splice(index, 1);
			setURLArr([...tempArr]);
		}
	};

	const handleSubmit = (submitBool) => {
		const { error } = AddProductValidations.validate({
			name,
			description,
			category,
			productType,
			artType,
			productArr,
			videoArr,
			sizeGuide,
			coordinates,
			basePrice,
		});
		console.log(error, "validarion");
		if (error) {
			const validationErrors = error.details.reduce((acc, curr) => {
				acc[curr.path[0]] = curr.message;
				return acc;
			}, {});
			setValidations(validationErrors);
			return;
		}
		console.log({
			name,
			description,
			category,
			productType,
			artType,
			productArr,
			videoArr,
			sizeGuide,
			coordinates,
			basePrice,
		});
		setValidations({});
		const formData = new FormData();
		formData.append("productName", name);
		formData.append("productDescription", description);
		formData.append("isCustomApplicable", productType === "custom");
		formData.append("basePrice", basePrice);
		formData.append("categoryId", category);
		if (productType !== "custom") {
			formData.append("artType", artType);
		}
		productArr?.forEach((obj, index) => {
			formData.append(`productImages`, obj);
		});
		formData.append("sizeChart", sizeGuide[0]);
		formData.append("productVideo", videoArr[0]);
		// if (coordinates?.length !== 0) {
		if (productType === "custom" && coordinates?.length !== 0) {
			formData.append("coordinates", JSON.stringify(coordinates));
		}
		setLoading(true);
		if (submitBool) {
			add_product(formData).then((res) => {
				setLoading(false);
				navigate("/merchandise");
			});
		} else {

			// urls not starting with blob:
			const exsUrls = editProductArr.filter((url) => !url.startsWith("blob:"));

			formData.append(
				"existingProductImage",
				JSON.stringify(exsUrls)
			);

			edit_product(formData, location?.state?._id)
				.then((res) => {
					navigate("/merchandise");
				})
				.catch((error) => {
					toast.error(error?.response?.data?.error, { toastId: "Error fetching products details" });
				})
				.finally(() => {
					setLoading(false);
				});
		}
	};

	useEffect(() => {
		dispatch(loadingActions.loadingState(true));
		fetchCategories().then(() => {
			fetchArtTypes().then(() => {
				if (location?.state) {
					fetch_prod(location?.state?._id).then((res) => {
						const obj = res?.data?.data;
						setName(obj.productName || "");
						setDescription(obj.productDescription || "");
						setBasePrice(obj.basePrice || 0);

						setCategory(obj.category?._id || "");
						setArtType(obj.artType?._id || "");

						// setting both...
						setSizeGuide([obj.sizeChart]);
						setSizeGuideURL([obj.sizeChart]);

						setVideoArr(obj.productVideo ? [obj.productVideo] : []);
						setVideoURLArr(obj.productVideo ? [obj.productVideo] : []);
						obj?.isCustomApplicable
							? setProductType("custom")
							: setProductType("general");
						setCoordinates(obj?.coordinates);
						if (obj.productImages) {							
							setEditProductArr([...obj.productImages]);
							setProductArr([obj.productImages]);
						}
					});
				}
			});
		});
		dispatch(loadingActions.loadingState(false));
	}, []);

	return (
		<div className={`flex flex-col !h-full justify-start gap-6 scrollPage ${styles["merc-width"]}`}>
		<Paper className="paperClass " component={Paper}>
				<div className="flex gap-20 border-bottom pb-3">
					<Link to={MERCHANDISE_PATH}>
						<IconComponent iconLabel={ICON_LABELS.KEYBOARD_BACKSPACE} />
					</Link>
					<Title
						pagetitle={location?.state ? "Edit Product" : "Add Product"}
						fontsize="20px"
						fontWeight="600"
						color="#060709"
						border={false}
					/>
				</div>

				<TextInput
					type="text"
					label={STRINGS.PNAME}
					placeholder={STRINGS.PNAME}
					error={validations?.name}
					value={name}
					onChange={(e) => setName(e.target.value)}
				/>
				<TextBox
					label={STRINGS.DESCRIPTION}
					placeholder={STRINGS.PRODUCT_DESCRIPTION}
					rows="8"
					value={description}
					error={validations?.description}
					onChange={(e) => setDescription(e.target.value)}
				></TextBox>
				<div className="mb-3">
					<SelectBox
						list={prep_for_select_box(categories?.categoryList)}
						// items={DROPDOWN_USER_TYPE}
						placeholder={STRINGS.SELECT_CATEGORY}
						value={category}
						setValue={setCategory}
						width="100%"
						background="none"
						error={validations?.category}
						label={STRINGS.CATEGORY}
					/>
				</div>
				<div className="mb-3">
					<SelectBox
						list={DROPDOWN_PRODUCT_TYPE}
						// items={DROPDOWN_USER_TYPE}
						placeholder={STRINGS.SELECT_PRODUCT_TYPE}
						value={productType}
						setValue={setProductType}
						error={validations?.productType}
						width="100%"
						background="none"
						label={STRINGS.PRODUCT_TYPE}
					/>
				</div>
				{productType === "general" && (
					<div className="mb-3">
						<SelectBox
							list={prep_art_select_box(artTypes?.artTypeList)}
							// items={DROPDOWN_USER_TYPE}
							placeholder={STRINGS.SELECT_ART}
							value={artType}
							setValue={setArtType}							
							error={validations?.artType}
							width="100%"
							background="none"
							label={STRINGS.ART}
						/>
					</div>
				)}
				<div className={styles.mediaComposite}>
					<InputFile
						accept="image/x-png,image/jpeg,image/jpg"
						type="file"
						label={STRINGS.MEDIA}
						placeholder={STRINGS.UPLAOD_IMG}
						subtext={STRINGS.IMG_LIMIT}
						error={validations?.productArr}
						value={productArr}
						onChange={(e) =>
							location.state ? handleFileSelect(
								e,
								"image",
								editProductArr,
								setProductArr,
								setEditProductArr,
								10,
								editProductArr?.length
							) :
							handleFileSelect(
								e,
								"image",
								productArr,
								setProductArr,
								setProductURLArr,
								10,
								editProductArr?.length
							)
						}
						multiple={true}
					/>
					<div className={styles.carousel}>
						{editProductArr?.map((item, index) => {
							return (
								<div
									key={item}
									style={{
										flexShrink: "0",
										position: "relative",
										width: "8rem",
										height: "8rem",
										marginRight: "2rem",
									}}
								>
									<img
										className={styles.mediaStyle}
										key={item}
										src={item}
										alt=""
									/>
									<button
										style={{
											position: "absolute",
											top: "-5%",
											right: "-5%",
											width: "1.5rem",
											height: "1.5rem",
											display: "flex",
											cursor: "pointer",
										}}
										onClick={() => {
											handleFileDelete(
												index,
												editProductArr,
												setEditProductArr,
												null,
												null
											);
										}}
									>
										<svg
											width="24"
											height="24"
											viewBox="0 0 56 56"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<circle cx="28" cy="28" r="16" fill="white" />
											<path
												d="M27.9994 4.66602C15.1427 4.66602 4.66602 15.1427 4.66602 27.9994C4.66602 40.856 15.1427 51.3327 27.9994 51.3327C40.856 51.3327 51.3327 40.856 51.3327 27.9994C51.3327 15.1427 40.856 4.66602 27.9994 4.66602ZM35.8393 33.366C36.516 34.0427 36.516 35.1627 35.8393 35.8393C35.4893 36.1894 35.046 36.3527 34.6027 36.3527C34.1594 36.3527 33.716 36.1894 33.366 35.8393L27.9994 30.4727L22.6327 35.8393C22.2827 36.1894 21.8393 36.3527 21.396 36.3527C20.9527 36.3527 20.5094 36.1894 20.1594 35.8393C19.4827 35.1627 19.4827 34.0427 20.1594 33.366L25.526 27.9994L20.1594 22.6327C19.4827 21.956 19.4827 20.836 20.1594 20.1594C20.836 19.4827 21.956 19.4827 22.6327 20.1594L27.9994 25.526L33.366 20.1594C34.0427 19.4827 35.1627 19.4827 35.8393 20.1594C36.516 20.836 36.516 21.956 35.8393 22.6327L30.4727 27.9994L35.8393 33.366Z"
												fill="#E6355C"
											/>
										</svg>
									</button>
								</div>
							);
						})}
						{productURLArr?.map((item, index) => {
							return (
								<div
									key={item}
									style={{
										flexShrink: "0",
										position: "relative",
										width: "8rem",
										height: "8rem",
										marginRight: "2rem",
									}}
								>
									<img
										key={item}
										className={styles.mediaStyle}
										src={item}
										alt=""
									/>
									<button
										style={{
											position: "absolute",
											top: "-5%",
											right: "-5%",
											width: "1.5rem",
											height: "1.5rem",
											display: "flex",
											cursor: "pointer",
										}}
										onClick={() => {
											handleFileDelete(
												index,
												productArr,
												setProductArr,
												productURLArr,
												setProductURLArr
											);
										}}
									>
										<svg
											width="24"
											height="24"
											viewBox="0 0 56 56"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<circle cx="28" cy="28" r="16" fill="white" />
											<path
												d="M27.9994 4.66602C15.1427 4.66602 4.66602 15.1427 4.66602 27.9994C4.66602 40.856 15.1427 51.3327 27.9994 51.3327C40.856 51.3327 51.3327 40.856 51.3327 27.9994C51.3327 15.1427 40.856 4.66602 27.9994 4.66602ZM35.8393 33.366C36.516 34.0427 36.516 35.1627 35.8393 35.8393C35.4893 36.1894 35.046 36.3527 34.6027 36.3527C34.1594 36.3527 33.716 36.1894 33.366 35.8393L27.9994 30.4727L22.6327 35.8393C22.2827 36.1894 21.8393 36.3527 21.396 36.3527C20.9527 36.3527 20.5094 36.1894 20.1594 35.8393C19.4827 35.1627 19.4827 34.0427 20.1594 33.366L25.526 27.9994L20.1594 22.6327C19.4827 21.956 19.4827 20.836 20.1594 20.1594C20.836 19.4827 21.956 19.4827 22.6327 20.1594L27.9994 25.526L33.366 20.1594C34.0427 19.4827 35.1627 19.4827 35.8393 20.1594C36.516 20.836 36.516 21.956 35.8393 22.6327L30.4727 27.9994L35.8393 33.366Z"
												fill="#E6355C"
											/>
										</svg>
									</button>
								</div>
							);
						})}
					</div>
				</div>

				{productType === "custom" && (
					<div className="flex flex-col justify-between gap-2.5 mb-2">
						<Title
							pagetitle={STRINGS.HIGHLIGHT_AREA}
							fontsize="16px"
							fontWeight="500"
							color="#222124"
							error={validations?.coordinates}
						/>
						<RectangleSelection
							imageURL={location?.state ? editProductArr[0] : productURLArr[0]}
							width={622}
							height={624.53}
							onRectangleUpdate={handleRectangleUpdate}
							rectangle={coordinates || [
								{ x: 50, y: 50 },
								{ x: 150, y: 50 },
								{ x: 50, y: 150 },
								{ x: 150, y: 150 },
								{
								  height: 100,
								  width: 100,
								  startX: 200,
								  startY: 200,
								  x: 50,
								  y: 50
								}
							  ]
						}
						/>
					</div>
				)}
				{productType !== "custom" && (
					<div className={styles.mediaComposite}>
						<InputFile
							accept="video/mp4"
							type="file"
							label="Product Video"
							placeholder={STRINGS.UPLAOD_IMG}
							subtext="Click to upload product video"
							error={validations?.videoArr}
							value={videoArr}
							onChange={async(e) =>{
								setVideoArr([]);
								setVideoURLArr([]);
								// timeout 50ms
								const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
								// timeout 50ms
								await delay(50);
								handleFileSelect(
									e,
									"video",
									videoArr,
									setVideoArr,
									setVideoURLArr,
									1,
									0
								)
							}
							}
						/>
						<div className={styles.carousel}>
							{videoArr.length > 0 && (
								<div
									style={{
										flexShrink: "0",
										position: "relative",
										width: "8rem",
										height: "8rem",
										marginRight: "2rem",
									}}
								>
									<video
										className={styles.mediaStyle}
										src={videoURLArr[0]}
										controls
									/>
									<button
										style={{
											position: "absolute",
											top: "-5%",
											right: "-5%",
											width: "1.5rem",
											height: "1.5rem",
											display: "flex",
											cursor: "pointer",
										}}
										onClick={() => {
											handleFileDelete(
												0,
												videoArr,
												setVideoArr,
												videoURLArr,
												setVideoURLArr
											);
										}}
									>
										<svg
											width="24"
											height="24"
											viewBox="0 0 56 56"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<circle cx="28" cy="28" r="16" fill="white" />
											<path
												d="M27.9994 4.66602C15.1427 4.66602 4.66602 15.1427 4.66602 27.9994C4.66602 40.856 15.1427 51.3327 27.9994 51.3327C40.856 51.3327 51.3327 40.856 51.3327 27.9994C51.3327 15.1427 40.856 4.66602 27.9994 4.66602ZM35.8393 33.366C36.516 34.0427 36.516 35.1627 35.8393 35.8393C35.4893 36.1894 35.046 36.3527 34.6027 36.3527C34.1594 36.3527 33.716 36.1894 33.366 35.8393L27.9994 30.4727L22.6327 35.8393C22.2827 36.1894 21.8393 36.3527 21.396 36.3527C20.9527 36.3527 20.5094 36.1894 20.1594 35.8393C19.4827 35.1627 19.4827 34.0427 20.1594 33.366L25.526 27.9994L20.1594 22.6327C19.4827 21.956 19.4827 20.836 20.1594 20.1594C20.836 19.4827 21.956 19.4827 22.6327 20.1594L27.9994 25.526L33.366 20.1594C34.0427 19.4827 35.1627 19.4827 35.8393 20.1594C36.516 20.836 36.516 21.956 35.8393 22.6327L30.4727 27.9994L35.8393 33.366Z"
												fill="#E6355C"
											/>
										</svg>
									</button>
								</div>
							)}
						</div>
					</div>
				)}
				<div className={styles.mediaComposite}>
					<InputFile
						accept="image/x-png,image/jpeg,image/jpg"
						type="file"
						label={STRINGS.SIZE_GUIDE}
						placeholder={STRINGS.SIZE_CHART}
						subtext={STRINGS.CSV_FILE}
						error={validations?.sizeGuide}
						value={sizeGuide}
						onChange={async (e) =>{

							setSizeGuide("");
							setSizeGuideURL("");

							await new Promise(resolve => setTimeout(resolve, 50));

							handleFileSelect(
								e,
								"image",
								sizeGuide,
								setSizeGuide,
								setSizeGuideURL,
								1,
								0
							)
						}
						}
					/>
					<div className={styles.carousel}>
						{sizeGuide.length > 0 && (
							<div
								style={{
									flexShrink: "0",
									position: "relative",
									width: "8rem",
									height: "8rem",
									marginRight: "2rem",
								}}
							>
								<img
									className={styles.mediaStyle}
									src={sizeGuideURL}
									alt="sizeGuide"
								/>
								<button
									style={{
										position: "absolute",
										top: "-5%",
										right: "-5%",
										width: "1.5rem",
										height: "1.5rem",
										display: "flex",
										cursor: "pointer",
									}}
									onClick={() => {
										handleFileDelete(
											0,
											sizeGuide,
											setSizeGuide,
											sizeGuideURL,
											setSizeGuideURL
										);
									}}
								>
									<svg
										width="24"
										height="24"
										viewBox="0 0 56 56"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<circle cx="28" cy="28" r="16" fill="white" />
										<path
											d="M27.9994 4.66602C15.1427 4.66602 4.66602 15.1427 4.66602 27.9994C4.66602 40.856 15.1427 51.3327 27.9994 51.3327C40.856 51.3327 51.3327 40.856 51.3327 27.9994C51.3327 15.1427 40.856 4.66602 27.9994 4.66602ZM35.8393 33.366C36.516 34.0427 36.516 35.1627 35.8393 35.8393C35.4893 36.1894 35.046 36.3527 34.6027 36.3527C34.1594 36.3527 33.716 36.1894 33.366 35.8393L27.9994 30.4727L22.6327 35.8393C22.2827 36.1894 21.8393 36.3527 21.396 36.3527C20.9527 36.3527 20.5094 36.1894 20.1594 35.8393C19.4827 35.1627 19.4827 34.0427 20.1594 33.366L25.526 27.9994L20.1594 22.6327C19.4827 21.956 19.4827 20.836 20.1594 20.1594C20.836 19.4827 21.956 19.4827 22.6327 20.1594L27.9994 25.526L33.366 20.1594C34.0427 19.4827 35.1627 19.4827 35.8393 20.1594C36.516 20.836 36.516 21.956 35.8393 22.6327L30.4727 27.9994L35.8393 33.366Z"
											fill="#E6355C"
										/>
									</svg>
								</button>
							</div>
						)}
					</div>
				</div>

				<TextInput
					type="text"
					label={STRINGS.BASE_PRICE}
					placeholder={STRINGS.PRODUCT_PRICE}
					error={validations?.basePrice}
					value={basePrice}
					onChange={(e) => setBasePrice(e.target.value)}
				/>

				<div className="flex justify-content-end gap-20 my-3">
					<Button
						variant="secondary"
						onClick={() => navigate(MERCHANDISE_PATH)}
					>
						Cancel
					</Button>
					<Button
						className={styles.saveButton}
						onClick={() => handleSubmit(location?.state ? false : true)}
						disabled={loading}
					>
						{STRINGS.BTNTEXTSUBMIT}
						{loading && (
							<CircularProgress size={20} className={styles.buttonLoader} />
						)}
					</Button>
				</div>
			</Paper>
		</div>
	);
};
