import React from 'react';
import PropTypes from 'prop-types';
import { ListItem } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import './sidenav.css';
import IconComponent from '../icons/IconComponent';

const SideNavItem = ({ to, label, img }) => {
    const location = useLocation();
    const isActive = location.pathname === to;

    return (
        <Link to={to}>
            <ListItem
                className={`flex gap-[8px] flex-row h-[56px] p-4 py-4 px-2 gap-2.5 rounded-l-lg justify-center items-center listItem ${isActive ? 'activeHover' : ''}`}
            >
                  <IconComponent  iconLabel={img} />
                <p
                    style={{ fontFamily: "Poppins"}}
                
                />
                  {label}
            </ListItem>
        </Link>
    );
};

SideNavItem.propTypes = {
    to: PropTypes.string,
    label: PropTypes.string,
    img: PropTypes.string,
};

export default SideNavItem;
