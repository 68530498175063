export const STRINGS = {
  loginTitle:"Login to your Account",
  loginText:"To log in to your account or register a new account please enter your credentials below",
  name:"Name",
  email:"Email Address",
  password:"Password",
  emailplaceholder:"Enter Email Address",
  passwordplaceholder:"Enter Password",
  buttontext:"Login",
  forgotpassword:"Forgot Password?",
  forgotpasstext:"To reset your password, enter your email address below.",
  reset:"Reset password",
  newpassword:"Enter New Password",
  confirmpassword:"Confirm Your Password",
  passwordplaceholder:"**************"

};
