import { React, useState } from "react";
import styles from "./form.module.css";
export const TextInput = ({
	label,
	type,
	value,
	onChange,
	error,
	isError,
	marginbot,
	searchFunc,
	...props
}) => {
	return (
		<div className={marginbot ? marginbot : "mb-3"}>
			{label ? (
				<label className={`d-block mb-2 ${styles.label}`}>{label}</label>
			) : (
				""
			)}

			<input
				type={type}
				value={value}
				onChange={onChange}
				onKeyUp={(e) => {
					if (searchFunc) {
						searchFunc();
					}
				}}
				{...props}
				className="form-control py-2"
			/>
			{error && (
				<span className={isError !== false && "text-danger"}>{error}</span>
			)}
		</div>
	);
};
